import "./SearchPage.scss";

import React, { useContext, useLayoutEffect } from "react";

import { authentication } from "../../auth";
import { SearchBoxHome } from "../../components/Header/SearchBoxHome";
import { LogoFull } from "../../components/LogoFull";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { Footer } from "../../components/Footer/Footer";

const SearchPage = () => {
  const { pageName, setPageName } = useContext(GlobalContext);
  const thisPageName = "homePage";
  const permissions = authentication.getUserPermissions();

  useLayoutEffect(() => {
    if (thisPageName !== pageName) {
      setPageName(thisPageName);
    }
  }, [thisPageName, pageName, setPageName]);

  return (
    <>
      <div className="search_container">
        <div className="searchBar">
          <LogoFull svgClass="errorpage_LogoFull" />
          <span className="home_title">
            Welcome to <br className="br_Mobile" />
            emc med data browser
          </span>
          <br />
          for easy access to the NHS dictionary of medicines and devices (dm+d) and
          <br className="br_Desktop" /> emc supply chain data set. For more information,{" "}
          <br className="br_underTablet" />
          please [
          <a
            className="home_link"
            href="https://www.datapharm.com/products/emc-med-data/"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>
          ]{/* Show search box only if they have at least one of the permissions granted */}
          {permissions.dmd.granted || permissions.sc.granted ? (
            <div>
              <SearchBoxHome />
            </div>
          ) : (
            ""
          )}
          {/* add spacer if there will be any messages */}
          {!permissions.dmd.granted || !permissions.sc.granted ? (
            <div>
              <br />
            </div>
          ) : (
            ""
          )}
          {/* user has an account but hasn't asked for access to DMD or SC yet */}
          {permissions.dmd.state === "notrequested" && permissions.sc.state === "notrequested" ? (
            <div className="review-text">
              Your emc med data account does not have access either to dm+d or emc supply chain
              dataset. Please go to Edit Profile to request access.
            </div>
          ) : (
            ""
          )}
          {/* user has pending access to MD */}
          {permissions.dmd.state === "pending" ? (
            <div className="review-text">
              Your request for access to the dm+d dataset is pending.
            </div>
          ) : (
            ""
          )}
          {/* user has pending access to SC */}
          {permissions.sc.state === "pending" ? (
            <div className="review-text">
              Your request for access to emc supply chain dataset is pending.
            </div>
          ) : (
            ""
          )}
          {/* user has at least one pending permission*/}
          {permissions.dmd.state === "pending" || permissions.sc.state === "pending" ? (
            <div className="review-text">
              Please log out and log in once access is available. You will be contacted about this
              within 2 working days of requesting access.
            </div>
          ) : (
            ""
          )}
          {/* user has expired DMD permission*/}
          {permissions.dmd.state === "expired" ? <div className="review-text"></div> : ""}
          {/* user has expired SC permission*/}
          {permissions.sc.state === "expired" ? <div className="review-text"></div> : ""}
          {/* user has at least one expired permission*/}
          {permissions.dmd.state === "expired" || permissions.sc.state === "expired" ? (
            <div className="review-text">
              Your emc med data account does not have access either to dm+d or emc supply chain
              dataset. Please go to Edit Profile to request access
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default SearchPage;
