import React, {useContext} from 'react';
import { GlobalContext } from '../../contexts/GlobalStore/GlobalStore';



export const CloseIcon = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { setLayoutData } = useContext(GlobalContext);



//-------------------------------------------------------------
// 		closeAll -> mobileMenu / mobileFilterZone / mobileSearch
//-------------------------------------------------------------
const closeAll = () => {
  setLayoutData(prev => ({
    ...prev,
    isMobileMenuActive:false,
    isMobileFilterZoneActive:false,
    isMobileSearchActive:false
  }));
}











//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" space="preserve"
      className = "mobileIcon mobileCloseIcon"
      onClick = {()=>{ closeAll() }}
      >
        <path d="M12.6,11.5c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2L8,9.1l-3.5,3.5 c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1L6.9,8L3.4,4.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0L8,6.9 l3.5-3.5c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L9.1,8L12.6,11.5z"/>
      </svg>
)};