import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

import {
  Approved,
  B2cPolicies,
  BaseLoginScopes,
  BaseTokenKey,
  Configuration,
  NotRequested,
  Registered,
  RegisteredSupplyChain,
  TokenScopes,
} from "./configuration";
import { Permissions } from "./types";

export const client = new msal.PublicClientApplication(Configuration);

function clearAccessToken(): void {
  sessionStorage.removeItem(BaseTokenKey);
}

function parsePermissions(permissions: string): Permissions {
  var result: Permissions = {
    dmd: { granted: false, state: NotRequested },
    sc: { granted: false, state: NotRequested },
  };

  if (!permissions || permissions === "") {
    return result;
  }

  var split = permissions.split(",");

  split.forEach((p) => {
    var match = p.match(/^meddata:(\w+):(\w+)$/i);

    if (match == null) {
      return;
    }

    switch (match[1]) {
      case Registered:
        result.dmd.granted = match[2] === Approved;
        result.dmd.state = match[2];
        break;
      case RegisteredSupplyChain:
        result.sc.granted = match[2] === Approved;
        result.sc.state = match[2];
        break;
      default:
        break;
    }
  });

  return result;
}

async function requestAccessToken(request: msal.SilentRequest): Promise<string | null> {
  return await client
    .acquireTokenSilent(request)
    .then((response) => response.accessToken)
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        await client.acquireTokenRedirect(request);
      }
      return null;
    });
}

export const authentication = {
  signIn: async function (): Promise<void> {
    const request: msal.RedirectRequest = {
      scopes: BaseLoginScopes,
    };
    localStorage.clear();
    return await client.loginRedirect(request);
  },
  signOut: async function (): Promise<void> {
    clearAccessToken();
    return await client.logoutRedirect();
  },
  signUp: async function (): Promise<void> {
    const request: msal.RedirectRequest = {
      scopes: BaseLoginScopes,
      authority: B2cPolicies.authorities.signUp.authority,
    };

    return await client.loginRedirect(request);
  },
  getAccount: function (): msal.AccountInfo | null {
    const activeAccount = client.getActiveAccount();
    const accounts = client.getAllAccounts();

    return activeAccount || accounts[0];
  },
  getUser: function (): msal.AccountInfo | null {
    return this.getAccount();
  },
  editProfile: async function (): Promise<void> {
    const request: msal.RedirectRequest = {
      scopes: BaseLoginScopes,
      authority: B2cPolicies.authorities.editProfile.authority,
    };

    return await client.loginRedirect(request);
  },
  newToken: async function (): Promise<void> {
    const request: msal.RedirectRequest = {
      scopes: BaseLoginScopes,
      authority: B2cPolicies.authorities.newToken.authority,
    };
    return await client.loginRedirect(request);
  },
  getToken: async function (): Promise<string | null> {
    const activeAccount = client.getActiveAccount();
    const accounts = client.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return null;
    }

    const request: msal.SilentRequest = {
      scopes: TokenScopes,
      account: activeAccount || accounts[0],
      forceRefresh: false,
    };

    return await requestAccessToken(request);
  },
  forgotPassword: async function (): Promise<void> {
    const request: msal.RedirectRequest = {
      scopes: BaseLoginScopes,
      authority: B2cPolicies.authorities.forgotPassword.authority,
    };
    return await client.loginRedirect(request);
  },
  existingUserRegister: async function (): Promise<void> {
    const request: msal.RedirectRequest = {
      scopes: BaseLoginScopes,
      authority: B2cPolicies.authorities.existingUserRegister.authority,
    };
    return await client.loginRedirect(request);
  },
  userHasSupplyChainAccess() {
    return this.getUserPermissions().sc.granted;
  },
  userHasDmdAccess() {
    return this.getUserPermissions().dmd.granted;
  },
  getUserPermissions: function (): Permissions {
    const activeAccount = client.getActiveAccount();
    const accounts = client.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return parsePermissions("");
    }

    const account = activeAccount || accounts[0];

    var claims: any = account?.idTokenClaims;
    var permissions: string =
      !account || !claims || !claims["permissions"] ? "" : claims["permissions"];

    return parsePermissions(permissions);
  },
};
