import { Filter, FilterStore } from "../../contexts/FilterStore/FilterStore";
import * as React from "react";
import "./MiniFilterBox.scss";

interface MiniFilterBoxProps {
  filter: Filter;
  type: "ingredients" | "forms" | "routes" | "suppliers";
}

export const MiniFilterBox: React.FC<MiniFilterBoxProps> = ({ filter, type }) => {
  const { setActiveFilters } = React.useContext(FilterStore);
  const maxLength = 40;

  return (
    <abbr
      className="miniBox"
      onClick={() => {
        setActiveFilters({ type: type, payload: filter });
      }}
      {...(filter.name.length > maxLength && { title: filter.name })}
    >
      <div>
        "{filter.name.length < maxLength ? filter.name : filter.name.slice(0, maxLength) + "..."}"
        <svg
          version="1.1"
          className="miniBox_X"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
        >
          <path
            d="M6.9,2.1l1,1C8,3.2,8,3.3,7.9,3.4L6.3,5l1.6,1.6C8,6.7,8,6.8,7.9,6.9l-1,1C6.8,8,6.7,8,6.6,7.9L5,6.3L3.4,7.9
          C3.3,8,3.2,8,3.1,7.9l-1-1C2,6.8,2,6.7,2.1,6.6L3.7,5L2.1,3.4C2,3.3,2,3.2,2.1,3.1l1-1C3.2,2,3.3,2,3.4,2.1L5,3.7l1.6-1.6
          C6.7,2,6.8,2,6.9,2.1z"
          />
        </svg>
      </div>
    </abbr>
  );
};
