import React from 'react';



export const ExternalURLicon = (props) => {
const { className,productId } = props;







//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className = { className }
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 68 20"
    space="preserve"
    onClick = {(e)=>{ 

      window.open(`${process.env.REACT_APP_EMC_SEARCH_URL}/${productId}`, '_blank');

    }}
    >


      <path
        className="externalURLicon_emblem"
        d="M16.1,7.7V4.2c0-2.3-1.9-4.2-4.2-4.2c-2.2,0-4,1.7-4.2,3.9H4.2C1.9,3.9,0,5.7,0,8.1c0,2.2,1.7,4,3.9,4.2v3.5
        c0,2.3,1.9,4.2,4.2,4.2c2.2,0,4-1.7,4.2-3.9h3.5c2.3,0,4.2-1.9,4.2-4.2C20,9.7,18.3,7.9,16.1,7.7z M11.6,12.6v2.8H8.7
        c-0.2,0-0.3-0.1-0.3-0.3v-2.5c0-0.6-0.5-1.1-1.1-1.1H4.5V8.7c0-0.2,0.1-0.3,0.3-0.3h2.5c0.6,0,1.1-0.5,1.1-1.1V4.5h2.9
        c0.2,0,0.3,0.1,0.3,0.3v2.5c0,0.6,0.5,1.1,1.1,1.1h2.8v2.9c0,0.2-0.1,0.3-0.3,0.3h-2.5C12.1,11.6,11.6,12.1,11.6,12.6z M11.9,0.7
        c1.9,0,3.5,1.6,3.5,3.5v3.5h-2.8c-0.2,0-0.4-0.2-0.4-0.4V4.9c0-0.6-0.5-1-1-1H8.4C8.6,2.1,10.1,0.7,11.9,0.7z M0.7,8.1
        c0-1.9,1.6-3.5,3.5-3.5h3.5v2.8c0,0.2-0.2,0.4-0.4,0.4H4.9c-0.6,0-1,0.5-1,1v2.8C2.1,11.4,0.7,9.9,0.7,8.1z M8.1,19.3
        c-1.9,0-3.5-1.6-3.5-3.5v-3.5h2.8c0.2,0,0.4,0.2,0.4,0.4v2.5c0,0.6,0.5,1,1,1h2.8C11.4,17.9,9.9,19.3,8.1,19.3z M15.8,15.4h-3.5
        v-2.8c0-0.2,0.2-0.4,0.4-0.4h2.5c0.6,0,1-0.5,1-1V8.4c1.8,0.2,3.2,1.7,3.2,3.5C19.3,13.9,17.7,15.4,15.8,15.4z"/>


      <path
        className="externalURLicon_type"
        d="M54.8,8.5V17h-0.6c-0.9,0-1.6-0.7-1.6-1.6V8.8c0-2.1-1.1-3.2-3.4-3.2c-2.4,0-3.4,1.1-3.4,3.1v6.7
        c0,0.9-0.7,1.6-1.6,1.6h-0.6V8.7c0-2.1-1.1-3.1-3.4-3.1c-2.4,0-3.4,1.1-3.4,3.2v6.9c0,0.7-0.6,1.3-1.3,1.3h-0.9V8.5
        c0-3,2-4.8,5.6-4.8c2,0,3.6,0.7,4.5,2c0.8-1.3,2.4-2,4.5-2C52.8,3.6,54.8,5.5,54.8,8.5z M62.1,5.7c0.2,0,0.4,0,0.6,0
        c1.9,0.1,2.8,0.7,3.1,2c0.1,0.5,0.6,0.9,1.1,0.9l1.1,0V7.9c0-2.1-1.8-4.1-5.6-4.2v0c-0.1,0-0.2,0-0.4,0c-3.7,0-6,1.8-6,4.8v3.9
        c0,3,2.3,4.8,6,4.8c0.1,0,0.3,0,0.4,0v0c3.7-0.1,5.6-2.1,5.6-4.2v-0.6l-1.1,0c-0.5,0-1,0.4-1.1,0.9c-0.3,1.3-1.3,1.9-3.3,2
        c-0.1,0-0.3,0-0.4,0c-2.5,0-3.9-1.1-3.9-3.1V8.8C58.2,6.8,59.6,5.7,62.1,5.7z M27.5,3.7c-3.7,0-6,1.8-6,4.8v3.9c0,3,2.3,4.8,6,4.8
        c0.1,0,0.4,0,0.5,0c3.7-0.1,5.6-2.1,5.6-4.2v-0.6l-1.1,0c-0.5,0-1,0.4-1.1,0.9c-0.3,1.3-1.3,1.9-3.4,2c-0.1,0-0.4,0-0.5,0
        c-2.5,0-3.9-1-3.9-3.1v-1l0-2h0V8.8c0-2,1.4-3.1,3.9-3.1c2.5,0,3.8,1.1,3.8,3.1v0.5c0,0.1-0.1,0.2-0.2,0.2h-4.5
        c-0.8,0-1.4,0.6-1.4,1.4v0.3h7.2c0.6,0,1.1-0.5,1.1-1.1V8.6C33.5,5.6,31.2,3.7,27.5,3.7z"/>

  </svg>
)};