import * as React from "react";

import ExportAllButton from "../../components/ExportAllButton/ExportAllButton";
import { TileData } from "../../components/Tile/Tile";
import { Filters } from "../../contexts/FilterStore/FilterStore";
import { AddSearchToFavourites } from "../../features/favourites";
import { Feature } from "../../features/features";
import {
  Database,
  SearchCounters,
  SearchQuery,
  SearchResultItem,
  SearchResults,
  SearchType,
  TabId
} from "../../pages/NewSearchResultsPage/SearchResultsTypes";
import { http } from "./http.service";

const SearchService = {
  getCounters: async (query: SearchQuery, db: Database): Promise<Partial<SearchCounters>> => {
    const postUrl = `${process.env.REACT_APP_API_PATH}/search`;

    const response = await http.post<typeof query, SearchCounters>(postUrl, query);

    return db === "dmd"
      ? {
          totalVmps: response.totalVmps,
          totalVmpps: response.totalVmpps,
          totalAmps: response.totalAmps,
          totalAmpps: response.totalAmpps,
          totalSupps: response.totalSupps,
        }
      : {
          totalSupplyChainPacks: response.totalSupplyChainPacks,
          totalSupplyChainCompanies: response.totalSupplyChainCompanies,
        };
  },

  getFilters: async <T extends Database>(
    query: string,
    database: T,
  ): Promise<Omit<Filters, "showDiscontinued">> => {
    const postUrl = `${process.env.REACT_APP_API_PATH}/search`;
    const payload = {
      term: query,
      scope: database,
      top: 1,
    };

    const response = await http.post<typeof payload, Filters>(postUrl, payload);

    return {
      forms: response.forms,
      ingredients: response.ingredients,
      routes: response.routes,
      suppliers: response.suppliers,
    };
  },

  getSearchResults: async <T extends SearchType>(searchQuery: SearchQuery) => {
    const postUrl = `${process.env.REACT_APP_API_PATH}/filter/more`;
    return await http.post<SearchQuery, SearchResults<T>>(postUrl, searchQuery);
  },
  getSearchQuery: (
    q: string,
    pageSize: number,
    offset: number,
    type: SearchType,
    filters: Filters,
    database: Database,
  ) => {
    const finalFilters = {
      formFilters: filters.forms.map((f) => f.id),
      ingredientFilters: filters.ingredients.map((f) => f.id),
      supplierFilters: filters.suppliers.map((f) => f.id),
      routeFilters: filters.routes.map((f) => f.id),
      showDiscontinued: !!filters.showDiscontinued.find((f) => f.id === "showDiscontinued"),
    };

    const searchQuery: SearchQuery = {
      ...finalFilters,
      offset: offset,
      term: q,
      scope: database,
      top: pageSize,
      type: type,
    };
    return searchQuery;
  },
  getDesctopActions: (key: TabId): JSX.Element[] => {
    switch (key) {
      case "totalAmpps":
        return [
          <Feature name={"favouritePacksAndCompanies"}>
            <AddSearchToFavourites
              className="addToFavouritesButton"
              color={"blue"}
              type="ampp"
              label="Add All to favourites"
            />
          </Feature>,
          <Feature name={"exportPackData"}>
            <ExportAllButton type="ampp" />
          </Feature>,
        ];
      case "totalSupplyChainPacks":
        return [
          <Feature name={"favouritePacksAndCompanies"}>
            <AddSearchToFavourites
              className="addToFavouritesButton"
              color={"blue"}
              type="supplychainpack"
              label="Add All to favourites"
            />
          </Feature>,
          <Feature name={"exportPackData"}>
            <ExportAllButton type="scpack" />
          </Feature>,
        ];
      default:
        return [];
    }
  },

  getLabel: (key: TabId) => {
    switch (key) {
      case "totalAmpps":
        return "AMPPs";
      case "totalAmps":
        return "AMPs";
      case "totalSupplyChainCompanies":
        return "COMPANIEs";
      case "totalSupplyChainPacks":
        return "PACKs";
      case "totalSupps":
        return "SUPPLIERs";
      case "totalVmpps":
        return "VMPPs";
      case "totalVmps":
        return "VMPs";
    }
  },

  getBackendLabel: (key: TabId): SearchType => {
    switch (key) {
      case "totalAmpps":
        return "ampp";
      case "totalAmps":
        return "amp";
      case "totalSupplyChainCompanies":
        return "sccompany";
      case "totalSupplyChainPacks":
        return "scpack";
      case "totalSupps":
        return "sup";
      case "totalVmpps":
        return "vmpp";
      case "totalVmps":
        return "vmp";
    }
  },
  generateTileData: (tile: SearchResultItem<SearchType>): TileData => {
    switch (tile.type) {
      case "ampp":
        return {
          id: tile.id,
          label: "AMPP ",
          name: tile.nm,
          price:
            tile.price && tile.price !== "0"
              ? (parseInt(tile.price) / 100).toFixed(2).toString()
              : undefined,
          isDiscontinued: tile.isDiscontinued,
          gtin: tile.gtinCodes,
          redirectUrl: `/ampp/${tile.id}/ampps`,
          productId: tile.productId,
        };
      case "amp":
        return {
          id: tile.id,
          label: "AMP ",
          name: tile.desc,
          redirectUrl: `/amp/${tile.id}/amps`,
        };
      case "sup":
        return {
          id: tile.id,
          label: "SUP ",
          name: tile.desc,
          redirectUrl: `/sup/${tile.id}/sups`,
        };
      case "vmp":
        return {
          id: tile.id,
          label: "VMP ",
          name: tile.nm,
          redirectUrl: `/vmp/${tile.id}/vmps`,
        };
      case "vmpp":
        return {
          id: tile.id,
          label: "VMPP ",
          name: tile.nm,
          redirectUrl: `/vmpp/${tile.id}/vmpps`,
        };
      case "sccompany":
        return {
          id: tile.id,
          label: "COMPANY ",
          name: tile.name,
          redirectUrl: `/sccompany/${tile.id}/sccompanys`,
        };
      case "scpack":
        return {
          id: tile.amppId,
          label: "AMPP ",
          name: tile.name,
          redirectUrl: `/scpack/${tile.id}/scpacks/${tile.companyId}`,
          pipCode: tile.pipCode,
          gtin: tile.gtinCodes,
        };
    }
  },
};

export default SearchService;
