import React from 'react';



export const AccCollapseAllIcon = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { type } = props;



//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className ="renderDetails_AccCollapseIcon2"
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 26"
    space="preserve"
    style={{ opacity:0.7 }}
    >

    <path
        style={{ fill:"#909090" }}
        d="M25.3,6.8h-2.7V4.1c0-0.4-0.3-0.7-0.7-0.7h-2.7V0.7c0-0.4-0.3-0.7-0.7-0.7H0.7C0.3,0,0,0.3,0,0.7v17.7
        c0,0.4,0.3,0.7,0.7,0.7h2.7v2.7c0,0.4,0.3,0.7,0.7,0.7h2.7v2.7c0,0.4,0.3,0.7,0.7,0.7h17.7c0.4,0,0.7-0.3,0.7-0.7V7.5
        C26,7.1,25.7,6.8,25.3,6.8z M3.4,4.1v13.6H1.5V1.5h16.3v1.9H4.1C3.7,3.4,3.4,3.7,3.4,4.1z M6.8,7.5v13.6H4.9V4.9h16.3v1.9H7.5
        C7.1,6.8,6.8,7.1,6.8,7.5z M24.5,24.5H8.3V8.3h16.3V24.5z"/>

    {type==="+"?

    <path
        style={{ fill:"#909090" }}
        d="M21.6,16.4c0,0.4-0.3,0.7-0.7,0.7h-3.7v3.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-3.7H12c-0.4,0-0.7-0.3-0.7-0.7
        c0-0.4,0.3-0.7,0.7-0.7h3.7V12c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7v3.7h3.7C21.3,15.7,21.6,16,21.6,16.4z"
      />
  
    
    :

    <path
        style={{ fill:"#909090" }}
        d="M20.8,17.1h-8.9c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h8.9c0.4,0,0.7,0.3,0.7,0.7S21.3,17.1,20.8,17.1z"
      />


    }


      


  </svg>
)};