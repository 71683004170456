import * as React from "react";
import { Action, Filter, Filters } from "../contexts/FilterStore/FilterStore";

export default function useFilterState(initialFilters: Filters) {
  const [selectedFilters, setSelectedFilters] = React.useReducer(reducer, initialFilters);

  function reducer(state: Filters, action: Action): Filters {
    switch (action.type) {
      case "clear":
        return initialFilters;
      case "setState":
        return action.payload;
    }
    return { ...state, [action.type]: getUpdatedFilterState(state, action.payload, action.type) };
  }

  function getUpdatedFilterState(state: Filters, filter: Filter, type: keyof Filters) {
    const filters = [...state[type]];
    const index = filters.findIndex((f) => f.id === filter.id);
    if (index !== -1) {
      filters.splice(index, 1);
      return filters;
    }
    return [...filters, filter];
  }

  return { selectedFilters, setSelectedFilters };
}
