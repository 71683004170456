import React, { useContext } from 'react';
import "./LoadingPage.scss";

import { GlobalContext } from '../../contexts/GlobalStore/GlobalStore.js';

import { BackEmblem } from "../../components/BackEmblem.js"
import { LoadingSVG } from "../../components/LoadingSVG.js"

const LoadingPage = () => {

  const { isLoading } = useContext(GlobalContext);

  return (
      isLoading && (
        <div className="isLoading_Zone">
          <BackEmblem svgClass="emblemSVG negative" />
          <LoadingSVG svgClass="isLoading_loadingSVG"/>
          <br/>
          med data is working on your request.
        </div>
      )
  )
};

export default LoadingPage;