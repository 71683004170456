import "./SearchResultsPage.scss";

import * as React from "react";
import { useQuery } from "react-query";

import { Navbar, NewHeader, NewPageContainer, Tab, TabContainer } from "../../components";
import { NavbarProps } from "../../components/Navbar/Navbar";
import { FilterStore } from "../../contexts/FilterStore/FilterStore";
import SearchService from "../../core/services/search.service";
import useMobileActions from "../../hooks/useMobileActions";
import useSearchQuery from "../../hooks/useSearchQuery";
import SearchFilters from "./SearchFilters/SearchFilters";
import SearchResultsTab from "./SearchResultsTab/SearchResultsTab";
import { SearchCounters, TabId } from "./SearchResultsTypes";

const SearchResultsPage: React.FC = () => {
  //state
  const [q, db] = useSearchQuery();
  const [activeTabId, setActiveTabId] = React.useState<TabId>(
    db === "dmd" ? "totalAmpps" : "totalSupplyChainPacks",
  );
  //services
  const { getLabel, getCounters, getSearchQuery, getDesctopActions } = SearchService;
  const { activeFilters } = React.useContext(FilterStore);
  const mobileActions = useMobileActions(activeTabId);
  //queries
  const countersQuery = useQuery(["searchCounters", q, db, ...Object.values(activeFilters)], () =>
    getCounters(getSearchQuery(q, 1, 0, "amp", activeFilters, db), db),
  );

  function prepareTabs(counters: Partial<SearchCounters>): NavbarProps["tabs"] {
    return Object.entries(counters).map(([key, value]) => ({
      id: key,
      href: `/search/${getLabel(key as TabId).toLowerCase()}`,
      label: `${getLabel(key as TabId)}(${value})`,
      params: { term: q, scope: db },
    }));
  }

  React.useEffect(() => {
    setActiveTabId(db === "dmd" ? "totalAmpps" : "totalSupplyChainPacks");
  }, [db]);

  function areResultsAvailable() {
    let resultsAvailable = false;
    if (countersQuery.data)
      Object.values(countersQuery.data).forEach((counter) => {
        if (counter > 0) {
          resultsAvailable = true;
        }
      });
    return resultsAvailable;
  }

  return (
    <NewPageContainer className="searchPage">
      <NewHeader mobileButtons={mobileActions} />
      {countersQuery.isSuccess && (
        <>
          {areResultsAvailable() ? (
            <>
              <Navbar
                tabs={prepareTabs(countersQuery.data)}
                activeTabId={activeTabId}
                setActiveTab={(id) => setActiveTabId(id as TabId)}
                buttons={getDesctopActions(activeTabId)}
              />
              <SearchFilters />
              <TabContainer activeTab={activeTabId}>
                {Object.entries(countersQuery.data).map(([k, v]) => (
                  <Tab key={k} tabId={k}>
                    <SearchResultsTab
                      filters={activeFilters}
                      total={v}
                      q={q}
                      db={db}
                      type={SearchService.getBackendLabel(k as TabId)}
                    />
                  </Tab>
                ))}
              </TabContainer>
            </>
          ) : (
            <h3 className="noSearchResults">
              Sorry - no results were found. <br />
              Please try a new search query.
            </h3>
          )}
        </>
      )}
    </NewPageContainer>
  );
};

export default SearchResultsPage;
