import * as React from "react";
import "./SidebarButton.scss";

export const SidebarButton = (props) => {
  const { labelText, icon, onClick, children } = props;
  return (
    <div className="sidebarButton" onClick={onClick}>
      <h2>{labelText}</h2>
      <div className="icon">{icon}</div>
      {children}
    </div>
  );
};
