import * as React from "react";
import { MobileMenus } from "../MobileMenuZone/MobileMenus";
import { Sidebar } from "../Sidebar/Sidebar";
import { Footer } from "../Footer/Footer";

const PageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <MobileMenus />
      <Sidebar />
      <div className="rightOfSidebar">
        {children}
        <Footer />
      </div>
    </>
  );
};

export default PageContainer;
