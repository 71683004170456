import React, { useState, useRef, useLayoutEffect } from "react";
import "./FilterSections.scss";
import { FilterCollapseIcon } from "./FilterCollapseIcon.js";
import { CheckBox } from "../CheckBox/CheckBox.js";
import _ from "lodash";

export const FilterSections = (props) => {
  //-------------------------------------------------------------
  // 		VARIABLES
  //-------------------------------------------------------------
  const {
    currentContent,
    label,
    onChange,
    currentExtendedAcc,
    setCurrentExtendedAcc,
    checkedList,
  } = props;

  const [easyFindBarValue, setEasyFindBarValue] = useState("");

  const collactableZone = useRef();
  const inputZone = useRef();

  //-------------------------------------------------------------
  // 		DEBOUNCED FILTERING BAR
  //-------------------------------------------------------------
  const delayedQuery = (e) => {
    delayedQuery2(e.target.value);
  };
  const delayedQuery2 = useRef(
    _.debounce((value) => {
      onLocalChange(value);
    }, 500),
  ).current;
  const onLocalChange = (newValue) => {
    setEasyFindBarValue(newValue);
  };

  //-------------------------------------------------------------
  // 		CHANGE OPENED ACCORDION ITEM, CLOSE OTHERS and CALCULATE ACC HEIGHT and SCROLL TO BEGINNING AFTER OPENING A ACC ZONE.
  //-------------------------------------------------------------
  useLayoutEffect(() => {
    if (currentExtendedAcc === label) {
      collactableZone.current.style.maxHeight = collactableZone.current.scrollHeight + 100 + "px";
    } else {
      collactableZone.current.style.maxHeight = 0 + "px";
      if (inputZone.current) {
        setEasyFindBarValue("");
        inputZone.current.value = "";
      }
    }
  }, [currentExtendedAcc, label]);

  //-------------------------------------------------------------
  // 		RETURN
  //-------------------------------------------------------------
  return (
    <div className="filterAtt">
      <div className="filterAtt_title">
        {/* Label Zone */}
        <div
          className="filterSection_titleArea"
          onClick={() => {
            if (currentExtendedAcc !== label) {
              setCurrentExtendedAcc(label);
            } else {
              setCurrentExtendedAcc(null);
            }
          }}
        >
          <div className="filterSection_iconArea">
            <FilterCollapseIcon type={currentExtendedAcc === label ? "-" : "+"} />
          </div>
          <div className="filterSection_title">
            {label} <span>({currentContent.length})</span>
          </div>
        </div>
      </div>
      <div className="filterAtt_content">
        <div ref={collactableZone} className="filterSection_collapsableArea">
          {/* Extendable Zone EASY FIND BAR  */}
          {currentContent.length > 19 ? (
            <input
              ref={inputZone}
              spellCheck="false"
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              placeholder="Type to find easily"
              className="filterSection_easyFindBar"
              onChange={delayedQuery.bind(this)}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
                if (event.keyCode === 27) {
                  inputZone.current.blur();
                }
              }}
            />
          ) : null}

          {/* Extendable Zone Handle Content  */}
          <div className="currentContent">
            {currentContent
              .filter((item) => {
                const query = easyFindBarValue.toLowerCase();

                return item.name.toLowerCase().indexOf(query) >= 0;
              })
              .map((item, index) => {
                return (
                  <CheckBox
                    key={index}
                    label={item.name}
                    id={item.id}
                    currentValue={!!checkedList.find((filter) => filter.id === item.id)}
                    onChange={(val) => {
                      onChange({ id: item.id, name: item.name, value: val });
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
