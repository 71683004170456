
import { authentication } from "../auth";

//npm install --save isomorphic-fetch es6-promise
export const fetchAPI = async (
	propshistory,
	urlProps,
	setIsLoading,
	setIsLoadingMore
) => {
	//-------------------------------------------------------------
	// 		VARIABLES
	//-------------------------------------------------------------
	let returnData = null;
	let apiURL = null;
	let apiData = null;
	let apiUsePost = false;
	let apiTask = null;
	
	if (
		urlProps.apiType !== 'loadMoreSearch' &&
		urlProps.apiType !== 'loadMoreFilterSearch'
	) {
		setIsLoading(true);
	} else {
		setIsLoadingMore(true);
	}

	const mapFilters = function (list) {
		if (list && list.length > 0) {
			return list.map(function (item) {
				return item;
			});
		}
		return [];
	};

	switch (urlProps.apiType) {


		// ------------------------
		// DETAILS PAGE APIs
		// ------------------------
		case 'vtmDetails': apiURL = `${process.env.REACT_APP_API_PATH}/vtm/${urlProps.value}?top=${urlProps.relativeTop}&offset=0`; break;
		case 'vmpDetails': apiURL = `${process.env.REACT_APP_API_PATH}/vmp/${urlProps.value}?top=${urlProps.relativeTop}&offset=0`; break;
		case 'ampDetails': apiURL = `${process.env.REACT_APP_API_PATH}/amp/${urlProps.value}?top=${urlProps.relativeTop}&offset=0`; break;
		case 'vmppDetails': apiURL = `${process.env.REACT_APP_API_PATH}/vmpp/${urlProps.value}?top=${urlProps.relativeTop}&offset=0`; break;
		case 'amppDetails': apiURL = `${process.env.REACT_APP_API_PATH}/ampp/${urlProps.value}?top=${urlProps.relativeTop}&offset=0`; break;
		case 'supDetails': apiURL = `${process.env.REACT_APP_API_PATH}/sup/${urlProps.value}?top=${urlProps.relativeTop}&offset=0`; break;
		case 'scpackDetails': apiURL = `${process.env.REACT_APP_API_PATH}/supplychainpack/${urlProps.companyId}/${urlProps.value}`; break;
		case 'sccompanyDetails': apiURL = `${process.env.REACT_APP_API_PATH}/supplychaincompany/${urlProps.value}`; break;
		case 'supplychainimage': apiURL = `${process.env.REACT_APP_API_PATH}/supplychainimage/${urlProps.value}`; break;

		// ------------------------
		// LEVEL TABs APIs
		// ------------------------
		case 'vtms': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/vtms?top=${urlProps.top}&offset=${urlProps.offset}`; break;
		case 'vmps': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/vmps?top=${urlProps.top}&offset=${urlProps.offset}`; break;
		case 'vmpps': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/vmpps?top=${urlProps.top}&offset=${urlProps.offset}`; break;
		case 'amps': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/amps?top=${urlProps.top}&offset=${urlProps.offset}`; break;
		case 'ampps': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/ampps?top=${urlProps.top}&offset=${urlProps.offset}`; break;
		case 'sups': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/sups?top=${urlProps.top}&offset=${urlProps.offset}`; break;
		case 'scpacks': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/scpacks?top=${urlProps.top}&offset=${urlProps.offset}`; break;
		case 'sccompanys': apiURL = `${process.env.REACT_APP_API_PATH}/${urlProps.level}/${urlProps.value}/sccompany?top=${urlProps.top}&offset=${urlProps.offset}`; break;

		// ------------------------
		// EXPORT APIs
		// ------------------------

		case 'scpackSearchExport': 
			apiURL = `${process.env.REACT_APP_API_PATH}/supplychainpack/search/export`;
			apiData = {
				term: urlProps.value.toLowerCase(),
				scope: urlProps.scope,
				level: urlProps.level,
				ingredientFilters: mapFilters(urlProps.ingList),
				routeFilters: mapFilters(urlProps.routeList),
				formFilters: mapFilters(urlProps.formList),
				supplierFilters: mapFilters(urlProps.suppList),
				type: urlProps.type,
				showDiscontinued: urlProps.showDis,
			};
			apiUsePost = true;
			break;

		case 'amppSearchExport': 
			apiURL = `${process.env.REACT_APP_API_PATH}/ampp/search/export`;
			apiData = {
				term: urlProps.value.toLowerCase(),
				scope: urlProps.scope,
				level: urlProps.level,
				ingredientFilters: mapFilters(urlProps.ingList),
				routeFilters: mapFilters(urlProps.routeList),
				formFilters: mapFilters(urlProps.formList),
				supplierFilters: mapFilters(urlProps.suppList),
				type: urlProps.type,
				showDiscontinued: urlProps.showDis,
			};
			apiUsePost = true;
			break;

		// ------------------------
		// SEARCH APIs
		// ------------------------

		case 'firstSearch':
			apiURL = `${process.env.REACT_APP_API_PATH}/search`;
			apiData = {
				term: urlProps.value.toLowerCase(),
				top: urlProps.top,
				scope: urlProps.scope,
				level: urlProps.level
			};
			apiUsePost = true;
			break;
		case 'loadMoreSearch':
			apiURL = `${process.env.REACT_APP_API_PATH}/search/more`;
			apiData = {
				term: urlProps.value.toLowerCase(),
				scope: urlProps.scope,
				top: urlProps.top,
				offset: urlProps.offset,
				type: urlProps.type,
			};
			apiUsePost = true;
			break;
		case 'firstFilteredSearch':
			apiURL = `${process.env.REACT_APP_API_PATH}/filter`;
			apiData = {
				term: urlProps.value.toLowerCase(),
				scope: urlProps.scope,
				top: urlProps.top,
				ingredientFilters: mapFilters(urlProps.ingList),
				routeFilters: mapFilters(urlProps.routeList),
				formFilters: mapFilters(urlProps.formList),
				supplierFilters: mapFilters(urlProps.suppList),
				type: urlProps.type,
				showDiscontinued: urlProps.showDis,
				};
			apiUsePost = true;
			break;
		case 'loadMoreFilterSearch':
			apiURL = `${process.env.REACT_APP_API_PATH}/filter/more`;
			apiData = {
				term: urlProps.value.toLowerCase(),
				scope: urlProps.scope,
				top: urlProps.top,
				offset: urlProps.offset,
				ingredientFilters: mapFilters(urlProps.ingList),
				routeFilters: mapFilters(urlProps.routeList),
				formFilters: mapFilters(urlProps.formList),
				supplierFilters: mapFilters(urlProps.suppList),
				type: urlProps.type,
				showDiscontinued: urlProps.showDis,
			};
			apiUsePost = true;
			break;

		default:
			apiURL = '';

	}

	//-------------------------------------------------------------
	// 	FETCH
	//-------------------------------------------------------------
	
	const token = await authentication.getToken();

	if (apiUsePost) {

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// console.log(apiURL);
			// console.log(JSON.stringify(apiData ?? {}));
		}

		apiTask = fetch(apiURL, {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			body: JSON.stringify(apiData ?? {}),
		});
	} else {

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') { console.log(apiURL); }
		apiTask = fetch(apiURL, {
			method: 'GET',
			headers: { Authorization: `Bearer ${token}` },
		});
	}

	await apiTask
		.then((response) => {

			// console.log(response.status);
			if (!response.ok) {
				throw new Error(response.status);
			} else {
				const contentType = response.headers.get("content-type");
				setTimeout(() => {
                    setIsLoading(false);
                    if (setIsLoadingMore) {
                        setIsLoadingMore(false);
                    }
                }, 250);
				if (contentType.includes("image") || contentType.includes("sheet") || contentType.includes("pdf")) {
					return response.blob();
				} 
				else {
					return response.json();
				}
			}
		})
		//-------------------------------------------------------------
        // 		HANDLE DATA
        //-------------------------------------------------------------
        .then((data) => {			
            if (data?.constructor.name === "Blob") {
                returnData = {
                    url: window.URL.createObjectURL(data),
                    blob: data,
                };
            } else {
                returnData = data;
            }
        })

		//-------------------------------------------------------------
		// 		CATCH ERROR
		//-------------------------------------------------------------

		.catch((error) => {
			setTimeout(() => {
				setIsLoading(false);
				if (setIsLoadingMore) {
					setIsLoadingMore(false);
				}
				propshistory.replace({ pathname: '/' + error.message });
			}, 250);
		});



	//-------------------------------------------------------------
	// 		RETURN DATA
	//-------------------------------------------------------------
	return returnData;
};
