import * as React from "react";

const EmcTileLogo: React.FC = () => (
  <svg width="77" height="19" viewBox="0 0 77 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9963 7.80789H11.7484C11.209 7.80789 10.7701 7.3695 10.7701 6.83071V4.52165C10.7701 4.35609 10.6352 4.22133 10.4695 4.22133H7.17969V3.90395C7.17969 1.75137 8.93307 0 11.0881 0C13.2432 0 14.9964 1.75137 14.9964 3.90395V7.80789H14.9963ZM7.83066 3.58656H10.4696C10.9859 3.58656 11.4059 4.00606 11.4059 4.52177V6.83071C11.4059 7.01947 11.5596 7.173 11.7485 7.173H14.3608V3.90407C14.3608 2.10152 12.8926 0.635132 11.0881 0.635132C9.39052 0.635009 7.99101 1.9324 7.83066 3.58656Z"
      fill="#67B599"
    />
    <path
      d="M14.6816 14.9806H10.7734V11.7365C10.7734 11.1977 11.2122 10.7593 11.7517 10.7593H14.0633C14.2291 10.7593 14.3639 10.6247 14.3639 10.459V7.17285H14.6817C16.8369 7.17285 18.5902 8.92422 18.5902 11.0768C18.5902 13.2294 16.8369 14.9806 14.6816 14.9806ZM11.4091 14.3457H14.6817C16.4864 14.3457 17.9545 12.8793 17.9545 11.0768C17.9545 9.38127 16.6557 7.98324 14.9996 7.82308V10.459C14.9996 10.9747 14.5795 11.3942 14.0633 11.3942H11.7517C11.5628 11.3942 11.4091 11.5477 11.4091 11.7365V14.3457Z"
      fill="#67B599"
    />
    <path
      d="M4.22617 11.3947H3.90842C1.75325 11.3947 0 9.64344 0 7.49074C0 5.33816 1.75325 3.58691 3.90842 3.58691H7.81672V6.83106C7.81672 7.36985 7.37782 7.80824 6.83842 7.80824H4.52683C4.36108 7.80824 4.22617 7.94288 4.22617 8.10844V11.3947ZM3.90842 4.22192C2.10368 4.22192 0.635615 5.68831 0.635615 7.49086C0.635615 9.18639 1.93449 10.5845 3.59055 10.7446V8.10856C3.59055 7.59297 4.01065 7.17335 4.52683 7.17335H6.83842C7.02739 7.17335 7.18111 7.01982 7.18111 6.83118V4.22192H3.90842Z"
      fill="#67B599"
    />
    <path
      d="M7.49814 18.5674C5.3431 18.5674 3.58984 16.8162 3.58984 14.6636V10.7598H6.83771C7.37711 10.7598 7.81601 11.198 7.81601 11.7369V14.0459C7.81601 14.2114 7.9508 14.3462 8.11656 14.3462H11.4064V14.6636C11.4063 16.8162 9.65306 18.5674 7.49814 18.5674ZM4.22534 11.3945V14.6636C4.22534 16.466 5.69341 17.9326 7.49802 17.9326C9.19549 17.9326 10.5951 16.6351 10.7555 14.9811H8.11643C7.60025 14.9811 7.18015 14.5616 7.18015 14.0459V11.7369C7.18015 11.5482 7.02644 11.3947 6.83759 11.3947H4.22534V11.3945Z"
      fill="#67B599"
    />
    <path
      d="M42.9211 1.16504C45.385 1.16504 47.2821 2.00837 48.3301 3.61067C49.3498 2.00837 51.2757 1.16504 53.7675 1.16504C58.044 1.16504 60.5079 3.35753 60.5079 6.98401V17.188H59.7221C58.6858 17.188 57.8461 16.354 57.8461 15.3254V7.32107C57.8461 4.81964 56.5431 3.49816 53.7675 3.49816C50.9073 3.49816 49.6327 4.76339 49.6327 7.26482V15.2651C49.6327 16.3268 48.7655 17.188 47.6955 17.188H46.9992V7.26527C46.9992 4.76383 45.6962 3.49861 42.9211 3.49861C40.0326 3.49861 38.7863 4.82009 38.7863 7.32152V15.6442C38.7863 16.4969 38.0895 17.1884 37.2305 17.1884H36.1523V6.98445C36.1528 3.35798 38.6167 1.16504 42.9211 1.16504Z"
      fill="#142B61"
    />
    <path
      d="M69.6914 1.37891V3.72742C72.1832 3.7683 73.3788 4.53512 73.707 6.10738C73.8346 6.71742 74.3759 7.14777 74.9912 7.14732L76.3144 7.14552V6.46675C76.3144 3.87746 74.123 1.45393 69.6914 1.37891Z"
      fill="#142B61"
    />
    <path
      d="M69.6914 17.6161V15.2678C72.1832 15.2269 73.3788 14.4596 73.707 12.8877C73.8346 12.2771 74.3759 11.8468 74.9912 11.8477L76.3144 11.849V12.5278C76.3144 15.1178 74.123 17.541 69.6914 17.6161Z"
      fill="#142B61"
    />
    <path
      d="M28.0312 17.6161V15.2678C30.6034 15.2269 31.8376 14.4596 32.1764 12.8877C32.3081 12.2771 32.8669 11.8468 33.502 11.8477L34.8679 11.849V12.5278C34.8683 15.1178 32.6058 17.541 28.0312 17.6161Z"
      fill="#142B61"
    />
    <path
      d="M69.2948 15.2644C66.2927 15.2644 64.6091 13.9766 64.6091 11.4852V7.50965C64.6091 5.01818 66.2927 3.73042 69.2948 3.73042C69.5749 3.73042 69.8671 3.71119 70.1185 3.72774L70.0871 1.40171C69.8299 1.38695 69.5659 1.37891 69.2948 1.37891C64.8054 1.37891 62 3.56274 62 7.17385V11.821C62 15.4325 64.8054 17.6159 69.2948 17.6159C69.5749 17.6159 69.8469 17.6074 70.1122 17.5909L70.0871 15.2707C69.8277 15.289 69.5843 15.2644 69.2948 15.2644Z"
      fill="#142B61"
    />
    <path
      d="M26.4095 8.32081C25.4777 8.32081 24.7223 9.06521 24.7223 9.98352V10.3922H33.5221C34.2666 10.3922 34.8704 9.79753 34.8704 9.06386V7.17314C34.8704 3.56203 32.0586 1.37891 27.5137 1.37891C22.9693 1.37891 20.1289 3.56203 20.1289 7.17314V11.8197C20.1289 15.4308 22.9693 17.6139 27.5137 17.6139C27.6553 17.6139 27.9502 17.6184 28.0876 17.6139V15.277C27.9502 15.2824 27.6584 15.2909 27.5137 15.2909C24.4746 15.2909 22.7987 14.0869 22.7706 11.5957V10.3922L22.7983 8.04138H22.7987V7.42529C22.7706 4.96185 24.4746 3.70196 27.5137 3.70196C30.5528 3.70196 32.2006 4.98957 32.2006 7.42529V8.03825C32.2006 8.19428 32.0717 8.32081 31.9138 8.32081H26.4095Z"
      fill="#142B61"
    />
  </svg>
);

export default EmcTileLogo;
