import React from 'react';



export const LogoType = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { svgClass } = props;






//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className = { svgClass }
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 202 61"
    space="preserve">


    <path
      className="LogoTypeEMC"
      d="M35.7,11v9.1H35c-0.9,0-1.7-0.7-1.7-1.7v-7.1c0-2.2-1.2-3.4-3.6-3.4c-2.5,0-3.7,1.1-3.7,3.4v7.1
      c0,0.9-0.8,1.7-1.7,1.7h-0.6v-8.8c0-2.2-1.2-3.4-3.6-3.4c-2.6,0-3.7,1.2-3.7,3.4v7.4c0,0.8-0.6,1.4-1.4,1.4h-1V11
      c0-3.2,2.2-5.2,6-5.2c2.2,0,3.9,0.8,4.8,2.2c0.9-1.4,2.6-2.2,4.8-2.2C33.5,5.8,35.7,7.8,35.7,11z M43.4,8c0.2,0,0.4,0,0.5,0
      c2.1,0.1,3.2,0.7,3.5,2.1c0.1,0.5,0.6,0.9,1.2,0.9l1.2,0v-0.6c0-2.3-2-4.4-5.9-4.5v0c-0.1,0-0.3,0-0.4,0c-4,0-6.5,1.9-6.5,5.1v4.1
      c0,3.2,2.5,5.1,6.5,5.1c0.1,0,0.3,0,0.4,0v0c4-0.1,5.9-2.2,5.9-4.5v-0.6l-1.2,0c-0.6,0-1,0.4-1.2,0.9c-0.3,1.4-1.3,2-3.5,2.1
      c-0.2,0-0.3,0-0.5,0c-2.7,0-4.2-1.1-4.2-3.4v-3.5C39.3,9.2,40.8,8,43.4,8z M6.5,5.9c-4,0-6.5,1.9-6.5,5.1v4.1c0,3.2,2.5,5.1,6.5,5.1
      c0.1,0,0.4,0,0.5,0c4-0.1,5.9-2.2,5.9-4.5v-0.6l-1.2,0c-0.6,0-1,0.4-1.2,0.9c-0.3,1.4-1.4,2.1-3.6,2.1c-0.1,0-0.4,0-0.5,0
      c-2.7,0-4.1-1.1-4.2-3.3V14l0-2.1h0v-0.5C2.3,9.1,3.8,8,6.5,8s4.1,1.1,4.1,3.3v0.5c0,0.1-0.1,0.3-0.3,0.3H5.5
      c-0.8,0-1.5,0.7-1.5,1.5V14h7.7c0.7,0,1.2-0.5,1.2-1.2v-1.7C12.9,7.9,10.4,5.9,6.5,5.9z"/>
    <path
      className="LogoTypeDMDBROWSER"
      d="M146.3,23.2c-7.7,0-11.5,4.1-11.5,8.4v1.1l2.2,0c1,0,1.9-0.7,2.2-1.7c0.6-2.7,2.7-3.9,7.1-3.9
      c5,0,7.2,1.6,7.2,5.5V36c-2.1-0.5-5.4-1-8.2-1c-6.8,0-11.4,1.9-11.4,7.6c0,5.4,4.3,7.7,11.3,7.7c7.4,0,12.7-2.8,12.7-8.8v-9.6
      C157.9,26.2,153.8,23.2,146.3,23.2z M153.5,41c0,4-3.2,5.6-8.2,5.6c-3.9,0-7-1.1-7-4.2c0-2.9,2.2-4.1,6.7-4.1c3,0,6,0.4,8.5,1V41z
       M189.2,23.2c-7.7,0-11.5,4.1-11.5,8.4v1.1l2.2,0c1,0,1.9-0.7,2.2-1.7c0.6-2.7,2.7-3.9,7.1-3.9c5,0,7.2,1.6,7.2,5.5V36
      c-2.1-0.5-5.4-1-8.2-1c-6.8,0-11.4,1.9-11.4,7.6c0,5.4,4.3,7.7,11.3,7.7c7.4,0,12.7-2.8,12.7-8.8v-9.6
      C200.8,26.2,196.7,23.2,189.2,23.2z M196.4,41c0,4-3.2,5.6-8.2,5.6c-3.9,0-7-1.1-7-4.2c0-2.9,2.2-4.1,6.7-4.1c3,0,6,0.4,8.5,1V41z
       M160.6,23l0-6.7h2.1c1.3,0,2.4,1.1,2.4,2.4V23h10.2v1.2c0,1.5-1.2,2.6-2.6,2.6H165v14.1c0,3.8,2.1,5.5,6.8,5.5c0.7,0,1.6,0,2.4-0.1
      c1.1-0.1,2.1,0.8,2.1,1.9v1.5c-1.3,0.3-3.2,0.6-4.7,0.6c-7.2,0-11.1-3-11.1-9.2V26.8L160.6,23z M89,18.4v7.7
      c-2.2-1.6-5.4-2.7-8.8-2.7c-7.1,0-10.7,3.3-10.7,9.3v8c0,6,4.6,9.6,12,9.6c7.4,0,11.9-3.6,11.9-9.6V14.9h-0.9
      C90.6,14.9,89,16.5,89,18.4z M89,40.1c0,4.1-2.6,6.3-7.5,6.3c-5,0-7.7-2.2-7.7-6.3v-6.9c0-3.9,1.9-5.9,6.8-5.9
      c3.2,0,6.6,1.5,8.4,3.2V40.1z M127,18.4v7.7c-2.2-1.6-5.4-2.7-8.8-2.7c-7.1,0-10.7,3.3-10.7,9.3v8c0,6,4.6,9.6,12,9.6
      c7.4,0,11.9-3.6,11.9-9.6V14.9h-0.9C128.5,14.9,127,16.5,127,18.4z M127,40.1c0,4.1-2.6,6.3-7.5,6.3c-5,0-7.7-2.2-7.7-6.3v-6.9
      c0-3.9,1.9-5.9,6.8-5.9c3.2,0,6.6,1.5,8.4,3.2V40.1z M40.2,33.3v17h-1.3c-1.7,0-3.1-1.4-3.1-3.1V33.9c0-4.2-2.2-6.4-6.7-6.4
      c-4.7,0-6.8,2.1-6.8,6.3v13.3c0,1.8-1.4,3.2-3.2,3.2h-1.1V33.8c0-4.2-2.2-6.3-6.7-6.3c-4.8,0-6.8,2.2-6.8,6.4v13.8
      c0,1.4-1.2,2.6-2.6,2.6H0v-17c0-6,4.1-9.7,11.2-9.7c4.1,0,7.2,1.4,8.9,4.1c1.7-2.7,4.9-4.1,9-4.1C36.1,23.6,40.2,27.3,40.2,33.3z
       M66.9,33v3.1c0,1.2-1,2.2-2.2,2.2H50.3v-0.7c0-1.5,1.2-2.8,2.8-2.8h9c0.3,0,0.5-0.2,0.5-0.5v-1c0-4-2.7-6.2-7.7-6.2
      c-5,0-7.7,2.1-7.7,6.2v1h0l0,3.9v2c0,4.1,2.8,6.1,7.7,6.1c0.2,0,0.7,0,0.9,0c4.2-0.1,6.2-1.3,6.7-3.9c0.2-1,1.1-1.7,2.1-1.7l2.2,0
      v1.1c0,4.3-3.7,8.3-11.1,8.4c-0.2,0-0.7,0-0.9,0c-7.4,0-12.1-3.6-12.1-9.6V33c0-6,4.6-9.6,12.1-9.6S66.9,27,66.9,33z"/>

  </svg>
)};