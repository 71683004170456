import * as React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import "./Popup.scss";
import classNames from "classnames";

interface PopupProps {
  message: string;
  onClose: Function;
  type: "success" | "error";
}

export const Popup: React.FC<PopupProps> = ({ onClose, message, type }) => {
  return (
    <div className={classNames("popup", type)}>
      <div className="topBar">
        <div className="flex">
          {type === "success" ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
          <h3>{type === "success" ? "Success" : "Error"}</h3>
        </div>
        <CloseIcon className="closeIcon" onClick={() => onClose()} />
      </div>
      <div className="mobileIcon">
        {type === "success" ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
      </div>
      <div className="content">{message}</div>
    </div>
  );
};

export default Popup;
