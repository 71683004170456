import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import * as React from "react";

import FeatureFlagsService from "../../../../core/services/featureFlagsService.service";
import { DefaultFeatureFlags } from "../../defaults";
import { FeatureFlags } from "../../types";

export const FeatureFlagsStore = React.createContext<FeatureFlags>({} as FeatureFlags);

export const FeatureFlagsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { inProgress, accounts } = useMsal();
  const [state, setState] = React.useState<FeatureFlags>({ ...DefaultFeatureFlags });
  const [, setLoading] = React.useState(true);

  React.useEffect(() => {
    const load = async (): Promise<void> =>
      await FeatureFlagsService.getFeatureFlags().then((flags) => {
        setLoading(false);
        setState({
          ...flags,
          loading: false,
        });
      });

    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      load();
    }
  }, [inProgress, accounts, setState, setLoading]);

  return <FeatureFlagsStore.Provider value={{ ...state }}>{children}</FeatureFlagsStore.Provider>;
};
