import "./TileTab.scss";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import * as React from "react";

import { Button } from "../../../components/Library";
import Tile, { TileData } from "../../../components/Tile/Tile";
import ErrorBoundary from "../../../core/Guards/ErrorBoundary";
import useFavourites from "../../../features/favourites/Hooks/useFavourites";
import {
  FavoritesDetailsResult,
  Favourite,
  FavouriteAmpp,
  FavouriteType,
} from "../../../features/favourites/types";

interface TileTabProps {
  type: FavouriteType;
  setButtonsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setCounters: React.Dispatch<React.SetStateAction<Omit<FavoritesDetailsResult, "results">>>;
}

const TileTab: React.FC<TileTabProps> = ({ type, setButtonsDisabled, setCounters }) => {
  const [
    favourites,
    loadFavourites,
    removeFavourite,
    clearFavourites,
    areFavouritesLoading,
    counters,
    isMoreOfType,
  ] = useFavourites();

  React.useEffect(() => {
    clearFavourites();
    loadFavourites(type, true);
  }, [type]);

  React.useEffect(() => {
    if (favourites) {
      setButtonsDisabled(false);
    }
  }, [favourites, setButtonsDisabled]);

  React.useEffect(() => {
    setCounters(counters);
  }, [counters, setCounters]);

  function isAmpp(favourite: Favourite): favourite is FavouriteAmpp {
    return !!(favourite as FavouriteAmpp).nm;
  }

  function generateTileData(favourite: Favourite): TileData {
    switch (favourite.type) {
      case "ampp":
        return {
          label: "AMPP",
          name: favourite.nm,
          id: favourite.id,
          description: favourite.nm,
          price: favourite.price && favourite.price !== "0" ? (parseInt(favourite.price) / 100).toFixed(2) : undefined,
        };
      case "supplychainpack":
        return {
          ...favourite,
          label: "AMPP",
          description: favourite.name,
          id: favourite.amppId,
          pipCode: favourite.pipCode,
        };
      case "sup":
        return { label: "SUPPLIER", id: favourite.id, name: favourite.desc };
      case "sccompany":
        return { label: "SUPPLIER", id: favourite.id, name: favourite.name };
    }
  }

  function getRedirectUrl(favourite: Favourite) {
    switch (favourite.type) {
      case "ampp":
        return `/ampp/${favourite.id}/ampps`;
      case "supplychainpack":
        return `/scpack/${favourite.id}/scpacks/${favourite.companyId}`;
      case "sccompany":
        return `/sccompany/${favourite.id}/sccompanys`;
      case "sup":
        return `/sup/${favourite.id}/sups`;
    }
  }

  return (
    <ErrorBoundary>
      <div className="tileTab">
        {areFavouritesLoading && favourites.length <= 0 ? (
          <h1 className="loader">Loading...</h1>
        ) : (
          <>
            {(favourites.length || isMoreOfType(type)) && (
              <>
                <div className="gridContainer">
                  {favourites?.map((f, i) => (
                    <Tile
                      tileData={{ ...generateTileData(f), redirectUrl: getRedirectUrl(f) }}
                      labelColor={isAmpp(f) ? "blue" : "orange"}
                      key={f.id}
                      onRemove={() => removeFavourite(i, f)}
                    />
                  ))}
                </div>
                <div className="buttons">
                  {isMoreOfType(type) && (
                    <Button
                      color="transparent"
                      onClick={() => loadFavourites(type)}
                      disabled={areFavouritesLoading}
                    >
                      {areFavouritesLoading ? "Loading..." : "Load More"}
                    </Button>
                  )}
                  <Button
                    className="gototop"
                    color="transparent"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Go To Top
                  </Button>
                </div>
              </>
            )}
            {!areFavouritesLoading && !favourites.length && !isMoreOfType(type) && (
              <div className="noContent">
                <StarBorderRoundedIcon />
                <h1>You have not added any favourites yet.</h1>
                <p>You can add items from search results or from a Pack's Details page.</p>
              </div>
            )}
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default TileTab;
