import * as React from "react";
import { useLocation } from "react-router-dom";
import { Database } from "../pages/NewSearchResultsPage/SearchResultsTypes";

export default function useSearchQuery(): [string, Database] {
  const { search } = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const [db, setDb] = React.useState<Database>((params.get("scope") as Database) ?? "dmd");
  const [q, setQ] = React.useState(params.get("term") ?? "a");
  React.useEffect(() => {
    const newDb = params.get("scope");
    const newQ = params.get("term");
    if (newDb && newDb !== db) {
      setDb(newDb as Database);
    }
    if (newQ && newQ !== q) {
      setQ(newQ);
    }
  }, [params]);

  return [q, db];
}
