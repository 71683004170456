import * as React from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { Footer } from "../Footer/Footer";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import NewMobileMenu from "../NewMobileMenu/NewMobileMenu";
import "./NewPageContainer.scss";

const NewPageContainer: React.FC<{ children: React.ReactNode; className?: string }> = (props) => {
  const { isMobileMenuOpen } = React.useContext(GlobalContext);

  return (
    <div className={props.className}>
      {isMobileMenuOpen && <NewMobileMenu />}
      <Sidebar />
      <div className="rightOfSidebar newRightOfSidebar">
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default NewPageContainer;
