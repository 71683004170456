import * as React from "react";
import { useHistory } from "react-router-dom";
import useSearchQuery from "../../hooks/useSearchQuery";
import { Database } from "../../pages/NewSearchResultsPage/SearchResultsTypes";
import SearchIcon from "@mui/icons-material/Search";
import "./Searchbar.scss";
import { authentication } from "../../auth";

const Searchbar: React.FC = () => {
  const [q, db] = useSearchQuery();
  const history = useHistory();
  const [input, setInput] = React.useState(q);
  const [select, setSelect] = React.useState<Database>(db);

  function handleSearch() {
    if ((input.length && input !== q) || select !== db)
      history.push({
        pathname: "/search",
        search: "?" + new URLSearchParams({ term: input, scope: select }).toString(),
      });
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  function handleDatabaseChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelect(event.target.value as Database);
    handleSearch();
  }

  React.useEffect(() => {
    handleSearch();
  }, [select]);

  return (
    <div className="searchbar">
      <SearchIcon onClick={() => handleSearch()} />
      <input
        value={input}
        type="search"
        placeholder={q}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      />

      <select value={select} onChange={handleDatabaseChange}>
        {authentication.userHasDmdAccess() && <option value="dmd">NHS dm+d</option>}
        {authentication.userHasSupplyChainAccess() && <option value="sc">emc supply chain</option>}
      </select>
    </div>
  );
};
export default Searchbar;
