import "./FavouritesPage.scss";

import * as React from "react";
import { useParams } from "react-router-dom";

import { Navbar, NewHeader, NewPageContainer, Tab, TabContainer } from "../../components";
import FavouritesService from "../../core/services/favourites.service";
import { ExportFavoritesButton } from "../../features/favourites/components/ExportFavoritesButton/ExportFavoritesButton";
import { FavoritesDetailsResult } from "../../features/favourites/types";
import { Feature } from "../../features/features";
import FavouritesSettingsTab from "./FavouritesSettingsTab/FavouritesSettingsTab";
import TileTab from "./TileTab/TileTab";
import { Notification } from "../../components/Library";

const FavouritesPage: React.FC = () => {
  const { database, tab } = useParams<{ database: string; tab: string }>();
  const [areButtonsDisabled, setButtonsDisabled] = React.useState(false);
  const { exportFavourites } = FavouritesService;
  const [activeTab, setActiveTab] = React.useState(
    ["packs", "suppliers", "settings"].indexOf(tab) >= 0 ? tab : "packs",
  );
  const [counter, setCounters] = React.useState<Omit<FavoritesDetailsResult, "results">>({
    totalAmpps: 0,
    totalSupplyChainPacks: 0,
    totalSupplyChainCompanies: 0,
    totalSupps: 0,
  });

  document.title = "Favourites";

  const packsLabel =
    database === "dmd"
      ? `AMPPs(${counter?.totalAmpps})`
      : `Packs(${counter?.totalSupplyChainPacks})`;
  const suppliersLabel =
    database === "dmd"
      ? `Suppliers(${counter.totalSupps})`
      : `Companies(${counter.totalSupplyChainCompanies})`;
  const baseURL = `/favourites/${database}`;
  const tabs = [
    {
      label: packsLabel,
      href: baseURL + `/${database === "dmd" ? `ampps` : `packs`}`,
      id: "packs",
    },
    { label: suppliersLabel, href: baseURL + `/suppliers`, id: "suppliers" },
    { label: "Settings", href: baseURL + `/settings`, id: "settings" },
  ];

  const buttons = [
    <ExportFavoritesButton
      label="Export All"
      type={database === "dmd" ? "ampp" : "supplychainpack"}
      disabled={areButtonsDisabled}
    />,
  ];

  const mobileButtons = [
    {
      label: "Export all",
      action: () => exportFavourites(database === "dmd" ? "ampp" : "supplychainpack"),
    },
  ];

  return (
    <NewPageContainer>
      <NewHeader
        title="Favourites"
        dataset={database === "dmd" ? "NHS dm+d" : "emc supply chain"}
        mobileButtons={activeTab === "packs" ? mobileButtons : undefined}
        disableSearch={true}
        disableFilter={true}
      />
      <Navbar
        tabs={tabs}
        setActiveTab={(id) => setActiveTab(id as string)}
        activeTabId={activeTab}
        buttons={activeTab === "packs" ? buttons : undefined}
      />
      <br/>
      <TabContainer activeTab={activeTab}>
        <Tab tabId="packs">
          <TileTab
            type={database === "dmd" ? "ampp" : "supplychainpack"}
            setButtonsDisabled={setButtonsDisabled}
            setCounters={setCounters}
          />
        </Tab>
        <Tab tabId="suppliers">
          <TileTab
            type={database === "dmd" ? "sup" : "sccompany"}
            setButtonsDisabled={setButtonsDisabled}
            setCounters={setCounters}
          />
        </Tab>
        <Tab tabId="settings">
          <Feature name="changeNotification">
            <FavouritesSettingsTab />
          </Feature>
        </Tab>
      </TabContainer>
    </NewPageContainer>
  );
};

export default FavouritesPage;
