import * as React from "react";
import { InfiniteData } from "react-query";

export default function useInfiniteScroll(fetchNextPage: () => void, data?: InfiniteData<unknown>) {
  const [lastElement, setLastElement] = React.useState<HTMLDivElement | null>(null);
  const observer = new IntersectionObserver((entries) => {
    entries[0].isIntersecting && fetchNextPage();
  });

  React.useEffect(() => {
    lastElement && observer.observe(lastElement);
    return () => {
      lastElement && observer.unobserve(lastElement);
    };
  }, [lastElement]);

  function shouldUpdateReference(
    node: HTMLDivElement | null,
    p: number,
    tileIndex: number,
    triggerPosition: number,
  ) {
    if (data && p === data.pages.length - 1 && tileIndex === triggerPosition) {
      setLastElement(node);
    }
  }

  return { shouldUpdateReference };
}
