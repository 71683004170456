import "./Tile.scss";

import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { useHistory } from "react-router-dom";
import EmcTileLogo from "./EmcTileLogo";

export interface TileProps {
  onRemove?: () => void;
  tileData: TileData;
  labelColor?: "blue" | "orange";
  from?: "search";
}

export interface TileData {
  name: string;
  id: string;
  label: string;
  description?: string;
  lastUpdated?: string;
  price?: string;
  pipCode?: string;
  gtin?: Array<string>;
  redirectUrl?: string;
  isDiscontinued?: boolean;
  productId?: string;
}

const Tile = React.forwardRef<HTMLDivElement, TileProps>(
  ({ onRemove, tileData, labelColor, from }, ref) => {
    const history = useHistory();
    const {
      price,
      pipCode,
      lastUpdated,
      description,
      name,
      id,
      label,
      gtin,
      isDiscontinued,
      productId,
    } = tileData;

    function redirect() {
      if (tileData.redirectUrl) {
        history.push(tileData.redirectUrl, { from });
      }
    }

    return (
      <div ref={ref} className="tile" onClick={() => redirect()}>
        <div className="topBar">
          <div className="title">
            <label className={`${labelColor ?? "blue"}`}>{label}</label>
            <label>{id}</label>
          </div>
          {onRemove && (
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            />
          )}
        </div>

        <p>{description ?? name}</p>
        {gtin && gtin.length > 0 && (
          <div className="gtin">
            <label>GTIN</label> {gtin.join(" ")}
          </div>
        )}
        {price && <div className="price">£ {price}</div>}
        {pipCode && (
          <div className="pipCode">
            <label>PIP Code</label> {pipCode}
          </div>
        )}

        {lastUpdated && (
          <div className="lastUpdated">
            <label>Last Updated: </label>
            {lastUpdated}
          </div>
        )}
        {isDiscontinued && <div className="discontinued">Discontinued</div>}
        {productId && (
          <a
            className="logo"
            href={`${process.env.REACT_APP_EMC_SEARCH_URL}/${productId}`}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noreferrer"
          >
            <EmcTileLogo />
          </a>
        )}
      </div>
    );
  },
);
export default Tile;
