import { MsalProvider } from "@azure/msal-react";
import * as React from "react";

import { ClientStore, GlobalStore } from "..";
import { client } from "../../auth/authentication.service";
import { FeatureFlagsProvider } from "../../features/features";
import { ModalStoreProvider } from "../ModalStore/ModalStore";
import { PopupStoreProvider } from "../PopupStore/PopupStore";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { QueryClient, QueryClientProvider } from "react-query";
import { FilterStoreProvider } from "../FilterStore/FilterStore";
import { reactPlugin } from "../InsightsStore/InsightsStore";
import { useLocation } from "react-router-dom";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      refetchOnWindowFocus: false,
    },
  },
});

const AppStore: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  //telemetry
  let location = useLocation();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("term");
    const database = searchParams.get("scope");
    reactPlugin.trackPageView({
      uri: location.pathname,
      name: "page change",
      properties: { query, database },
    });
  }, [location]);

  return (
    <MsalProvider instance={client}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <GlobalStore>
            <ClientStore>
              <ModalStoreProvider>
                <PopupStoreProvider>
                  <FilterStoreProvider>
                    <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
                  </FilterStoreProvider>
                </PopupStoreProvider>
              </ModalStoreProvider>
            </ClientStore>
          </GlobalStore>
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </MsalProvider>
  );
};

export default AppStore;
