import classNames from "classnames";
import * as React from "react";
import { MiniFilterBox } from "../../../components/Sidebar/MiniFilterBox";
import { ClientContext } from "../../../contexts/ClientStore/ClientStore";
import { FilterStore, Filter } from "../../../contexts/FilterStore/FilterStore";
import { GlobalContext } from "../../../contexts/GlobalStore/GlobalStore";
import "./SearchFilters.scss";

const SearchFilters: React.FC = () => {
  const { openMobileFilter, openFilterZone } = React.useContext(GlobalContext);
  const { activeFilters, setActiveFilters } = React.useContext(FilterStore);
  const { CS } = React.useContext(ClientContext);

  function showActions(): boolean {
    let showActions = false;
    Object.values(activeFilters).forEach((filters) => {
      if (filters.length > 0) {
        showActions = true;
      }
    });
    return showActions;
  }

  return (
    <div className={classNames("searchFilters", { invisible: !showActions() })}>
      <div className="filtered_miniBoxZone">
        {Object.entries(activeFilters).map(([type, filters], i) => {
          return filters.map((filter: Filter) => (
            <MiniFilterBox
              key={filter.name + i}
              filter={filter}
              type={type as "ingredients" | "forms" | "routes" | "suppliers"}
            />
          ));
        })}
      </div>
      {showActions() && (
        <>
          <div
            className="filtered_link"
            onClick={() => {
              CS.WINW < 600 ? openMobileFilter() : openFilterZone();
            }}
          >
            [ <span>Change Filter</span> ]
          </div>
          <div
            className="filtered_link"
            onClick={() => {
              setActiveFilters({ type: "clear" });
            }}
          >
            [ <span>Clear All</span> ]
          </div>
        </>
      )}
    </div>
  );
};

export default SearchFilters;
