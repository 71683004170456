import "./Checkbox.scss";

import * as React from "react";

interface CheckboxProps {
  label?: string;
  valueName?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (newValue: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { label, valueName, value, disabled, onChange } = props;

  return (
    <label className="lib-checkbox b-contain">
      <span>{label}</span>
      <input
        type="checkbox"
        name={valueName}
        checked={value}
        disabled={disabled}
        onChange={() => onChange && onChange(!value)}
      />
      <div className="b-input"></div>
    </label>
  );
};

export default Checkbox;
