import { fetchAPI } from "../../pages/APIConnections";
import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from '../../contexts/GlobalStore/GlobalStore';
import { useHistory } from "react-router-dom";
import "./style.scss"

export const ImageRenderer  = (props) => {

    const [allImages, setAllImages] = useState([]);
    const { setIsLoading } = useContext(GlobalContext);
    const currentValue = props.imageList;
    const history = useHistory();

    const getImage = () => {
        const requests = currentValue?.map( async (imageId) => {
            return await fetchAPI(history, {apiType:"supplychainimage", value: imageId},setIsLoading)
        });
        
        if (requests) {
            Promise.all(requests).then((images) => {
                setAllImages(images);
            });
        }
    };
    useEffect(getImage, []);

    return (
        <div className="view_images">
            <div className="images">
                {allImages?.map((image, index) => (
                    <a className="image"
                        href={image.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={image.url}
                    >
                        {image.blob?.type === "application/pdf" 
                            ? (<span>Open PDF</span>)
                            : (<img src={image.url} alt="" />)}
                    </a>
                ))}
            </div>
        </div>
    );
}