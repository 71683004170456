import React, { useContext } from "react";

import "./MobileMenus.scss";

import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { ClientContext } from "../../contexts/ClientStore/ClientStore";
import { CloseIcon } from "./CloseIcon.js";
import { FilterZone } from "../Sidebar/FilterZone";
import { MobileSearchZone } from "../Sidebar/MobileSearchZone.js";
import { MobileHamburger } from "./MobileHamburger.js";

export const MobileMenus = (props) => {
  //-------------------------------------------------------------
  // 		VARIABLES
  //-------------------------------------------------------------
  const { pageName, layoutData, openMobileMenu, openMobileFilter, openMobileSearch } =
    useContext(GlobalContext);
  const { CS } = useContext(ClientContext);

  //-------------------------------------------------------------
  // 		RETURN
  //-------------------------------------------------------------
  if (CS.WINW >= 600 || pageName === "errorPage") {
    return null;
  } else {
    return (
      <div className="mobileMenuIconDiv">
        {/* MOBILE MENU CONTAINER */}
        <div
          className={
            "mobileZones Menu_Background" + (layoutData.isMobileMenuActive ? " active" : "")
          }
        >
          <CloseIcon />
          <MobileHamburger />
        </div>

        {/* MOBILE FILTER CONTAINER */}
        <div
          className={
            "mobileZones Filter_Background" + (layoutData.isMobileFilterZoneActive ? " active" : "")
          }
        >
          <CloseIcon />
          <div className="mobileZoneInner">
            <FilterZone />
          </div>
        </div>

        {/* MOBILE SEARCH CONTAINER */}
        <div
          className={
            "mobileZones Search_Background" + (layoutData.isMobileSearchActive ? " active" : "")
          }
        >
          <CloseIcon />
          <MobileSearchZone />
        </div>

        {/* FILTER ICON FOR SOME PAGES */}
        {pageName !== "searchListingPage" ? null : (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 16 16"
            space="preserve"
            className="mobileIcon mobileFilterIcon"
            onClick={() => {
              openMobileFilter();
            }}
          >
            <path d="M9.4,14c-0.1,0-0.2,0-0.3-0.1l-2.7-1.7c-0.2-0.1-0.3-0.3-0.3-0.5V8.2L2.8,2.9c-0.1-0.2-0.1-0.4,0-0.6 C2.9,2.1,3.1,2,3.3,2h9.4c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4,0,0.6L9.9,8.2v5.2c0,0.2-0.1,0.4-0.3,0.5C9.6,14,9.5,14,9.4,14z M7.1,7.7C7.2,7.8,7.2,7.9,7.2,8v3.4l1.6,1V8c0-0.1,0-0.2,0.1-0.3l2.8-4.6H4.3L7.1,7.7z" />
          </svg>
        )}

        {/* SEARCH ICON FOR ALL PAGES exc HOME and eRRor */}
        {pageName === "homePage" || pageName === "errorPage" ? null : (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 16 16"
            space="preserve"
            className="mobileIcon mobileSearchIcon"
            onClick={() => {
              openMobileSearch();
            }}
          >
            <path d="M10.4,2.1C8.3,1.6,6.2,2.9,5.7,5c-0.2,1-0.1,2.1,0.5,3c0.1,0.1,0.2,0.3,0.3,0.4l-3.7,4.3 c-0.3,0.3-0.2,0.8,0.1,1.1C3,13.9,3.1,14,3.3,14c0.2,0,0.4-0.1,0.6-0.3l3.8-4.4c0.3,0.2,0.6,0.3,1,0.4c0.3,0.1,0.6,0.1,0.9,0.1 c0.7,0,1.4-0.2,2.1-0.6c0.9-0.6,1.5-1.4,1.8-2.4C13.8,4.7,12.5,2.6,10.4,2.1z M11.9,6.5C11.6,7.8,10.3,8.6,9,8.3 C8.3,8.1,7.8,7.8,7.5,7.2C7.1,6.7,7,6,7.2,5.4c0.3-1.3,1.6-2.1,2.9-1.8C11.4,3.9,12.2,5.2,11.9,6.5z" />
          </svg>
        )}

        {/* MENU ICON FOR ALL PAGES exc ERRORPAGE */}
        {pageName === "errorPage" ? null : (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 16 16"
            space="preserve"
            className="mobileIcon mobileMenuIcon"
            onClick={() => {
              openMobileMenu();
            }}
          >
            <path d="M9.2,4.1c0,0.7-0.6,1.2-1.2,1.2S6.8,4.8,6.8,4.1S7.3,2.9,8,2.9S9.2,3.4,9.2,4.1z M8,6.8 C7.3,6.8,6.8,7.3,6.8,8S7.3,9.2,8,9.2S9.2,8.7,9.2,8S8.7,6.8,8,6.8z M8,10.7c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2 s1.2-0.6,1.2-1.2S8.7,10.7,8,10.7z" />
          </svg>
        )}
      </div>
    );
  }
};
