import React, { useRef, useContext, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore.js";
import useSearchQuery from "../../hooks/useSearchQuery";
import { Button } from "../FormElements/Buttons/Button.js";
import "./MobileSearchZone.scss";

export const MobileSearchZone = (props) => {
  const { APIData, layoutData, closeAllMobileZones } = useContext(GlobalContext);
  const [q, db] = useSearchQuery();
  const inputZone = useRef();
  let history = useHistory();

  useLayoutEffect(() => {
    if (inputZone && inputZone.current) {
      inputZone.current.value = APIData.value ? APIData.value : "";
    }
  }, [APIData.value, layoutData.isMobileSearchActive]);

  const onSubmit = () => {
    if (inputZone.current.value !== "" && inputZone.current.value !== q) {
      history.push({
        pathname: "/search",
        search: "?" + new URLSearchParams({ term: inputZone.current.value, scope: db }).toString(),
      });
      closeAllMobileZones();
    }
  };

  return (
    <div className="MobileSearchZone">
      <textarea
        ref={inputZone}
        defaultValue={APIData.value ? APIData.value : ""}
        spellCheck="false"
        autoCorrect="off"
        autoCapitalize="off"
        className="MobileSearchZone_input"
        autoComplete="off"
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            onSubmit(event);
          }
          if (event.keyCode === 27) {
            inputZone.current.blur();
          }
        }}
        onChange={() => {
          inputZone.current.style.height = "auto";
          inputZone.current.style.height = inputZone.current.scrollHeight + "px";
        }}
      />

      {/* BUTTONS */}
      <Button
        buttonText="SEARCH"
        type="white"
        level="important"
        onClick={(e) => {
          onSubmit(e);
        }}
      />
    </div>
  );
};
