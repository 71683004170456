import React from "react";
import "./style.scss"

class SelectBox extends React.Component {
  state = {
    items: this.props.items || [],
    showItems: false,
    selectedItem: this.props.items && this.props.items[0],
    selectKeyItem : "dmd"
  };

  dropDown = () => {
    this.setState(prevState => ({
      showItems: !prevState.showItems
    }));
  };

  selectItem = item => {
    if(item.id === "sc"){
      this.setState({
        selectedItem: item,
        showItems: false,
        selectKeyItem: "sc",
      });
      this.setState({ items:[ { value:'NHS dm+d', id:'dmd', class:'' }, { value:'emc supply chain', id:'sc', class:'invisible' }]})
    }
    else if(item.id === "dmd"){
      this.setState({
        selectedItem: item,
        showItems: false,
        selectKeyItem: "dmd",
      });
      this.setState({ items:[ { value:'NHS dm+d', id:'dmd', class:'invisible' }, { value:'emc supply chain', id:'sc', class:'' }]})
    }
    this.props.selectItem(item.id)
  };
  setSelecte = (value) =>{
    this.props.selectedItem(value);
  }

  render() {
    return (
      <div className="select-box--box">
        <div className="select-box--container">
          <div className="select-box--selected-item" onClick={this.dropDown}>
            {this.state.selectedItem.value}
          </div>
          <div className="select-box--arrow" onClick={this.dropDown}>
            <span className={`${this.state.showItems ? "select-box--arrow-up" : "select-box--arrow-down" }`}/>
          </div>

          <div style={{ display: this.state.showItems ? "block" : "none" }} className={"select-box--items"}>
            {this.state.items.map(item => (
              <div key={item.id} onClick={() => this.selectItem(item)} className={this.state.selectKeyItem === item.id ? "invisible"  : ""}>
                {item.value}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectBox;
