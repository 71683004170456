import { useIsAuthenticated } from "@azure/msal-react";
import * as React from "react";
import { useQuery } from "react-query";

import SearchService from "../../core/services/search.service";
import useFilterState from "../../hooks/useFilterState";
import useSearchQuery from "../../hooks/useSearchQuery";

export type Filter = { id: string; name: string };

export interface Filters {
  showDiscontinued: Filter[];
  ingredients: Filter[];
  forms: Filter[];
  routes: Filter[];
  suppliers: Filter[];
}

interface FilterStoreInterface {
  availableFilters: Filters;
  activeFilters: Filters;
  setActiveFilters: React.Dispatch<Action>;
}

export type Action =
  | {
      type: "ingredients" | "forms" | "routes" | "suppliers" | "showDiscontinued";
      payload: Filter;
    }
  | {
      type: "clear";
    }
  | {
      type: "setState";
      payload: Filters;
    };

const initialFilters: Filters = {
  showDiscontinued: [],
  ingredients: [],
  forms: [],
  routes: [],
  suppliers: [],
};

export const FilterStore = React.createContext<FilterStoreInterface>({} as FilterStoreInterface);

export const FilterStoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const [q, db] = useSearchQuery();

  const filtersQuery = useQuery(["searchFilters", q, db], () => SearchService.getFilters(q, db), {
    enabled: isAuthenticated,
  });

  const [availableFilters, setAvailableFilters] = React.useState<Filters>(initialFilters);
  const { selectedFilters: activeFilters, setSelectedFilters: setActiveFilters } =
    useFilterState(initialFilters);

  React.useEffect(() => {
    if (!filtersQuery.isSuccess) {
      return;
    }

    setAvailableFilters({
      showDiscontinued: [],
      ingredients: filtersQuery.data.ingredients || [],
      forms: filtersQuery.data.forms || [],
      routes: filtersQuery.data.routes || [],
      suppliers: filtersQuery.data.suppliers || [],
    });
  }, [filtersQuery.isSuccess]);

  return (
    <FilterStore.Provider value={{ availableFilters, activeFilters, setActiveFilters }}>
      {children}
    </FilterStore.Provider>
  );
};
