import * as msal from "@azure/msal-browser";

export const NotRequested: string = "notrequested";
export const Approved: string = "approved";
export const Registered: string = "registered";
export const RegisteredSupplyChain: string = "registeredsupplychain";
export const BaseTokenKey: string = "MeddataToken";
export const BaseLoginScopes: string[] = ["openid", "profile"];
export const LoginScopes = [...BaseLoginScopes];
export const TokenScopes = [`${process.env.REACT_APP_B2C_SCOPE_MEDDATA_ACCESS}`];

export const B2cPolicies = {
  names: {
    signIn: process.env.REACT_APP_B2C_POLICY_SIGNIN,
    signUp: process.env.REACT_APP_B2C_POLICY_SIGNUP,
    forgotPassword: process.env.REACT_APP_B2C_POLICY_RESET_PASSWORD,
    editProfile: process.env.REACT_APP_B2C_POLICY_EDIT_PROFILE,
    newToken: process.env.REACT_APP_B2C_POLICY_NEW_TOKEN,
    existingUserRegister: process.env.REACT_APP_B2C_POLICY_REGISTER_APP,
  },
  authorities: {
    signIn: {
      authority: `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_B2C_AUTHORITY}/${process.env.REACT_APP_B2C_POLICY_SIGNIN}`,
    },
    signUp: {
      authority: `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_B2C_AUTHORITY}/${process.env.REACT_APP_B2C_POLICY_SIGNUP}`,
    },
    forgotPassword: {
      authority: `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_B2C_AUTHORITY}/${process.env.REACT_APP_B2C_POLICY_RESET_PASSWORD}`,
    },
    editProfile: {
      authority: `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_B2C_AUTHORITY}/${process.env.REACT_APP_B2C_POLICY_EDIT_PROFILE}`,
    },
    newToken: {
      authority: `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_B2C_AUTHORITY}/${process.env.REACT_APP_B2C_POLICY_NEW_TOKEN}`,
    },
    existingUserRegister: {
      authority: `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_B2C_AUTHORITY}/${process.env.REACT_APP_B2C_POLICY_REGISTER_APP}`,
    },
  },
};

export const Configuration: msal.Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_B2C_CLIENT_ID}`,
    authority: B2cPolicies.authorities.signIn.authority,
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
    knownAuthorities: [`${process.env.REACT_APP_B2C_TENANT}`],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};
