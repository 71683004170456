import * as React from "react";

import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { ClientContext } from "../../contexts/ClientStore/ClientStore";
import "./FilterZone.scss";
import { Button } from "../FormElements/Buttons/Button.js";
import { CheckBox } from "../FormElements/CheckBox/CheckBox.js";
import { FilterSections } from "../FormElements/FilterSections/FilterSections.js";
import { Filters, FilterStore } from "../../contexts/FilterStore/FilterStore";
import useFilterState from "../../hooks/useFilterState";

export const FilterZone = () => {
  const { activeFilters, availableFilters, setActiveFilters } = React.useContext(FilterStore);
  const { selectedFilters, setSelectedFilters } = useFilterState(activeFilters);
  const { closeFilterZone, closeAllMobileZones } = React.useContext(GlobalContext);
  const { CS } = React.useContext(ClientContext);
  const [currentExtendedAcc, setCurrentExtendedAcc] = React.useState(null);

  const applyFilterZone = () => {
    if (CS.WINW < 600) {
      closeAllMobileZones();
    } else {
      closeFilterZone();
    }
  };

  function getFilterSectionParams(type: keyof Omit<Filters, "showDiscontinued">) {
    return {
      currentContent: availableFilters[type],
      checkedList: selectedFilters[type],
      onChange: (val: any) => setSelectedFilters({ type, payload: { id: val.id, name: val.name } }),
      currentExtendedAcc: currentExtendedAcc,
      setCurrentExtendedAcc: setCurrentExtendedAcc,
    };
  }

  return (
    <div className="filterZone2">
      <div className="filterZone3">
        {/* SHOW DISCONT. CHECK BOX */}
        <div className="filterAtt">
          <div className="filterAtt_title">
            <CheckBox
              label="Show Discontinued"
              bigLabel
              currentValue={
                !!selectedFilters.showDiscontinued.find(
                  (filter) => filter.id === "showDiscontinued",
                )
              }
              onChange={() => {
                setSelectedFilters({
                  type: "showDiscontinued",
                  payload: { id: "showDiscontinued", name: "Show discontinued" },
                });
              }}
            />
          </div>
        </div>

        {availableFilters.ingredients.length > 0 && (
          <FilterSections label="Ingredients" {...getFilterSectionParams("ingredients")} />
        )}
        {availableFilters.forms.length > 0 && (
          <FilterSections label="Forms" {...getFilterSectionParams("forms")} />
        )}
        {availableFilters.routes.length > 0 && (
          <FilterSections label="Routes" {...getFilterSectionParams("routes")} />
        )}
        {availableFilters.suppliers.length > 0 && (
          <FilterSections label="Suppliers" {...getFilterSectionParams("suppliers")} />
        )}
      </div>

      {/* BUTTONS */}
      <div className="buttonsZone">
        <Button
          buttonText="APPLY"
          type="white"
          level="important"
          onClick={() => {
            setActiveFilters({ type: "setState", payload: selectedFilters });
            applyFilterZone();
          }}
        />

        {CS.WINW < 600 ? null : (
          <Button
            buttonText="CLEAR"
            type="white"
            level="default"
            onClick={() => {
              setSelectedFilters({ type: "clear" });
            }}
          />
        )}

        <Button
          buttonText="CANCEL"
          type="white"
          level="default"
          onClick={() => {
            CS.WINW < 600 ? closeAllMobileZones() : closeFilterZone();
          }}
        />
      </div>
    </div>
  );
};
