import * as React from "react";
import useExportSearchResults from "../../hooks/useExportSearchResults";
import { SearchType } from "../../pages/NewSearchResultsPage/SearchResultsTypes";
import { Button } from "../Library";

const ExportAllButton: React.FC<{ type: SearchType }> = ({ type }) => {
  const { initExport } = useExportSearchResults(type);
  return (
    <Button
      className="exportButton"
      label="Export All"
      color={"blue"}
      onClick={() => {
        initExport(type);
      }}
    />
  );
};

export default ExportAllButton;
