import React from 'react';



export const LogoFull = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { svgClass } = props;
// const [currentValue,setCurrentValue]=useState("");



//-------------------------------------------------------------
// 		FUNCTIONS
//-------------------------------------------------------------



//-------------------------------------------------------------
// 		LIFE CYCLE
//-------------------------------------------------------------




//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className = { svgClass }
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 360 82"
    space="preserve">


    <path
      className="skyBlueZone"
      d="M135.4,14.6v12.2h-0.9c-1.2,0-2.2-1-2.2-2.2V15c0-3-1.5-4.6-4.8-4.6c-3.4,0-4.9,1.5-4.9,4.5v9.6
      c0,1.3-1,2.3-2.3,2.3h-0.8V15c0-3-1.5-4.5-4.8-4.5c-3.4,0-4.9,1.6-4.9,4.6v10c0,1-0.8,1.8-1.8,1.8h-1.3V14.6c0-4.3,2.9-7,8-7
      c2.9,0,5.2,1,6.4,2.9c1.2-1.9,3.5-2.9,6.5-2.9C132.5,7.7,135.4,10.3,135.4,14.6z M145.8,10.6c0.2,0,0.5,0,0.7,0
      c2.9,0.1,4.3,1,4.6,2.8c0.2,0.7,0.8,1.2,1.5,1.2l1.6,0v-0.8c0-3.1-2.6-6-8-6v0c-0.2,0-0.4,0-0.5,0c-5.3,0-8.7,2.6-8.7,6.9v5.5
      c0,4.3,3.3,6.9,8.7,6.9c0.2,0,0.4,0,0.5,0v0c5.3-0.1,8-3,8-6v-0.8l-1.6,0c-0.7,0-1.4,0.5-1.5,1.2c-0.4,1.8-1.8,2.7-4.7,2.8
      c-0.2,0-0.4,0-0.7,0c-3.6,0-5.6-1.5-5.6-4.5v-4.7C140.3,12.2,142.3,10.6,145.8,10.6z M96.2,7.8c-5.3,0-8.7,2.6-8.7,6.9v5.5
      c0,4.3,3.3,6.9,8.7,6.9c0.2,0,0.5,0,0.7,0c5.3-0.1,8-3,8-6v-0.8l-1.6,0c-0.7,0-1.4,0.5-1.5,1.2c-0.4,1.9-1.8,2.8-4.8,2.8
      c-0.2,0-0.5,0-0.7,0c-3.6,0-5.5-1.4-5.6-4.4v-1.4l0-2.8h0v-0.7c0-2.9,2-4.4,5.5-4.4s5.5,1.5,5.5,4.4v0.7c0,0.2-0.2,0.3-0.3,0.3h-6.5
      c-1.1,0-2,0.9-2,2v0.5h10.4c0.9,0,1.6-0.7,1.6-1.6v-2.3C104.8,10.4,101.5,7.8,96.2,7.8z M82,49.1c0,9.5-7.7,17.2-17.2,17.2H50.3
      c-0.7,8.9-8.1,15.8-17.2,15.8c-9.5,0-17.2-7.7-17.2-17.2V50.4C7,49.7,0,42.3,0,33.2C0,23.7,7.7,16,17.2,16h14.5
      c0.7-8.9,8.1-15.8,17.2-15.8c9.5,0,17.2,7.7,17.2,17.2v14.5C75,32.6,82,40.1,82,49.1z M51.8,47.7H62c0.7,0,1.3-0.6,1.3-1.3V34.7
      H51.8c-2.4,0-4.3-1.9-4.3-4.3V20.1c0-0.7-0.6-1.3-1.3-1.3H34.5v11.5c0,2.4-1.9,4.3-4.3,4.3H20c-0.7,0-1.3,0.6-1.3,1.3v11.7h11.5
      c2.4,0,4.3,1.9,4.3,4.3v10.2c0,0.7,0.6,1.3,1.3,1.3h11.7V52C47.5,49.6,49.5,47.7,51.8,47.7z M34.5,16h11.6c2.3,0,4.1,1.9,4.1,4.1
      v10.2c0,0.8,0.7,1.5,1.5,1.5h11.5V17.4c0-8-6.5-14.4-14.4-14.4C41.4,3,35.3,8.7,34.5,16z M15.8,47.6V36c0-2.3,1.9-4.1,4.1-4.1h10.2
      c0.8,0,1.5-0.7,1.5-1.5V18.8H17.2c-8,0-14.4,6.5-14.4,14.4C2.8,40.7,8.5,46.9,15.8,47.6z M47.5,66.3H35.8c-2.3,0-4.1-1.9-4.1-4.1V52
      c0-0.8-0.7-1.5-1.5-1.5H18.6v14.4c0,8,6.5,14.4,14.4,14.4C40.6,79.4,46.7,73.6,47.5,66.3z M79.2,49.1c0-7.5-5.7-13.7-13-14.4v11.6
      c0,2.3-1.9,4.1-4.1,4.1H51.8c-0.8,0-1.5,0.7-1.5,1.5v11.5h14.4C72.7,63.5,79.2,57,79.2,49.1z"/>
    <path
      className="darkBlueZone"
      d="M283.9,31.1c-10.3,0-15.4,5.5-15.4,11.3v1.5l3,0c1.4,0,2.6-1,2.9-2.3c0.8-3.6,3.6-5.3,9.5-5.3
      c6.7,0,9.7,2.2,9.7,7.4v4.7c-2.8-0.6-7.3-1.3-11.1-1.3c-9.1,0-15.3,2.5-15.3,10.2c0,7.2,5.8,10.4,15.2,10.4c9.9,0,17-3.7,17-11.9
      V42.8C299.6,35.1,294,31.1,283.9,31.1z M293.7,55.1c0,5.4-4.3,7.5-11.1,7.5c-5.3,0-9.4-1.5-9.4-5.6c0-4,3-5.5,9-5.5
      c4.1,0,8.1,0.5,11.4,1.3V55.1z M341.6,31.1c-10.3,0-15.4,5.5-15.4,11.3v1.5l3,0c1.4,0,2.6-1,2.9-2.3c0.8-3.6,3.6-5.3,9.5-5.3
      c6.7,0,9.7,2.2,9.7,7.4v4.7c-2.8-0.6-7.3-1.3-11.1-1.3c-9.1,0-15.3,2.5-15.3,10.2c0,7.2,5.8,10.4,15.2,10.4c9.9,0,17-3.7,17-11.9
      V42.8C357.2,35.1,351.7,31.1,341.6,31.1z M351.3,55.1c0,5.4-4.3,7.5-11.1,7.5c-5.3,0-9.4-1.5-9.4-5.6c0-4,3-5.5,9-5.5
      c4.1,0,8.1,0.5,11.4,1.3V55.1z M303.2,30.8l-0.1-9h2.8c1.8,0,3.2,1.4,3.2,3.2v5.8h13.7v1.6c0,2-1.6,3.6-3.6,3.6h-10.1v18.9
      c0,5.1,2.8,7.4,9.1,7.4c1,0,2.1,0,3.3-0.1c1.5-0.1,2.8,1,2.8,2.6v2.1c-1.7,0.4-4.3,0.8-6.3,0.8c-9.6,0-14.9-4-14.9-12.3V35.9
      L303.2,30.8z M207,24.7V35c-2.9-2.2-7.3-3.7-11.8-3.7c-9.5,0-14.4,4.4-14.4,12.5v10.8c0,8.1,6.1,12.9,16.1,12.9
      c9.9,0,15.9-4.9,15.9-12.9V20h-1.2C209,20,207,22.1,207,24.7z M207,53.8c0,5.6-3.5,8.5-10.1,8.5c-6.7,0-10.3-2.9-10.3-8.5v-9.3
      c0-5.2,2.6-7.9,9.2-7.9c4.3,0,8.8,2.1,11.2,4.2V53.8z M258,24.7V35c-2.9-2.2-7.3-3.7-11.8-3.7c-9.5,0-14.4,4.4-14.4,12.5v10.8
      c0,8.1,6.1,12.9,16.1,12.9c9.9,0,15.9-4.9,15.9-12.9V20h-1.2C260.1,20,258,22.1,258,24.7z M258,53.8c0,5.6-3.5,8.5-10.1,8.5
      c-6.7,0-10.3-2.9-10.3-8.5v-9.3c0-5.2,2.6-7.9,9.2-7.9c4.3,0,8.8,2.1,11.2,4.2V53.8z M141.3,44.7v22.8h-1.7c-2.3,0-4.2-1.9-4.2-4.2
      V45.5c0-5.6-2.9-8.5-9.1-8.5c-6.3,0-9.2,2.8-9.2,8.4v17.9c0,2.4-1.9,4.3-4.3,4.3h-1.5V45.3c0-5.6-2.9-8.4-9.1-8.4
      c-6.4,0-9.2,3-9.2,8.5v18.6c0,1.9-1.5,3.5-3.5,3.5h-2.4V44.7c0-8.1,5.5-13,15-13c5.5,0,9.7,1.9,12,5.5c2.3-3.6,6.5-5.5,12.1-5.5
      C135.9,31.7,141.3,36.6,141.3,44.7z M177.2,44.3v4.2c0,1.6-1.3,3-3,3H155v-0.9c0-2,1.7-3.7,3.7-3.7h12.1c0.3,0,0.6-0.3,0.6-0.6v-1.4
      c0-5.4-3.6-8.3-10.3-8.3c-6.7,0-10.4,2.8-10.3,8.3v1.4h0l-0.1,5.2v2.7c0.1,5.5,3.7,8.2,10.4,8.2c0.3,0,1,0,1.3,0
      c5.6-0.1,8.3-1.8,9-5.3c0.3-1.4,1.5-2.3,2.9-2.3l3,0v1.5c0,5.7-4.9,11.1-14.9,11.3c-0.3,0-0.9,0-1.3,0c-10,0-16.2-4.9-16.2-12.9
      V44.3c0-8,6.2-12.9,16.2-12.9C171.1,31.4,177.2,36.2,177.2,44.3z"/>


  </svg>
)};