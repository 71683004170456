import "./ErrorBoundary.scss";

import * as React from "react";

import { ErrorService } from "../services/error.service";

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  private _errorService: ErrorService;

  state = {
    error: null,
  };

  constructor(props: any) {
    super(props);
    this._errorService = ErrorService.create();
  }

  static getDerivedStateFromError(error: any) {
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this._errorService.log(error, errorInfo.componentStack);
  }

  render() {
    if (this.state.error) {
      return (
        <div className="errorBoundary" title="Error! Please refresh and try again.">
          {this.props.children}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
