import * as React from "react";
import { Notification } from "../components/Library";
import { GlobalContext } from "../contexts/GlobalStore/GlobalStore";
import { ModalStore } from "../contexts/ModalStore/ModalStore";
import { PopupStore } from "../contexts/PopupStore/PopupStore";
import FavouriteService from "../core/services/favourites.service";
import { ProblemDetails } from "../features/favourites/types";
import { SearchType } from "../pages/NewSearchResultsPage/SearchResultsTypes";
import useSearchQuery from "./useSearchQuery";
export default function useAddSearchToFavourites(type: SearchType) {
  const { APIData } = React.useContext(GlobalContext);
  const [q, db] = useSearchQuery();
  const [isLoading, setLoading] = React.useState(false);
  const { addSearchedFavourites } = FavouriteService;
  const modalStore = React.useContext(ModalStore);
  const popupStore = React.useContext(PopupStore);

  const getIDsAsArray = (ARRAY: { id: string; name: string }[]) => {
    let convertedIDs = [];
    for (var i in ARRAY) {
      convertedIDs.push(ARRAY[i].id);
    }
    return convertedIDs;
  };

  const mapFilters = function (list: string[]) {
    if (list && list.length > 0) {
      return list.map(function (item) {
        return item;
      });
    }
    return [];
  };

  const buildSearchProps = () => {
    return {
      term: q.toLowerCase(),
      scope: db,
      ingredientFilters: mapFilters(getIDsAsArray(APIData.selectedIngValues)),
      routeFilters: mapFilters(getIDsAsArray(APIData.selectedRoutesValues)),
      formFilters: mapFilters(getIDsAsArray(APIData.selectedFormValues)),
      supplierFilters: mapFilters(getIDsAsArray(APIData.selectedSupplierValues)),
      showDiscontinued: APIData.showDiscValue,
      level: "",
    };
  };

  const add = async () => {
      setLoading(true);

      try {
        await addSearchedFavourites(
          type === "scpack" ? "supplychainpack" : type,
          buildSearchProps(),
        );
        popupStore.openPopup("success", `Items were successfully added to the favourites list.`);
      } catch (e) {
        (e as ProblemDetails).status === 409
          ? popupStore.openPopup("error", (e as ProblemDetails).detail)
          : popupStore.openPopup(
              "error",
              `Items were not successfully added to the favourites list. Please try again.`,
            );
      }
      setLoading(false);
  };

  return { isLoading, add };
}
