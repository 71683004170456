import "./Header.scss";

import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { ClientContext } from "../../contexts/ClientStore/ClientStore";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { AddSearchToFavourites } from "../../features/favourites";
import { Feature } from "../../features/features";
import { fetchAPI } from "../../pages/APIConnections";
import { Button } from "../Library/Button/Button";
import { LogoFull } from "../LogoFull.js";
import SearchBox from "./SearchBoxWithoutSuggestion.js";
import { ModalStore } from "../../contexts/ModalStore/ModalStore";

export const Header = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { APIData, pageName, lastRenderedId,openMobileSearch, setIsLoading } = useContext(GlobalContext);
const { CS } = useContext(ClientContext);



const [VTMRect, setVTMRect] = useState(null);
const [VMPRect, setVMPRect] = useState(null);
const [VMPPRect, setVMPPRect] = useState(null);
const [AMPRect, setAMPRect] = useState(null);
const [AMPPRect, setAMPPRect] = useState(null);
const [SupplierRect, setSupplierRect] = useState(null);
const [SCPACKRect, setSCPACKRect] = useState(null);
const [SCCOMPANYRect, setSCCOMPANYRect] = useState(null);

const VTMText = useRef();
const VMPText = useRef();
const VMPPText = useRef();
const AMPText = useRef();
const AMPPText = useRef();
const SupplierText = useRef();
const SCPACKText = useRef();
const SCCOMPANYText = useRef();

const [hoverLink, setHoverLink] = useState(null);
const [hideMe, setHideMe] = useState(false);

const searchParams = new URLSearchParams(props.location.search);
const value = searchParams.get("term") || "";
const scope = searchParams.get("scope") || "";
const level = props.level;
const {tab} = useParams();
const modalStore = React.useContext(ModalStore);


//-------------------------------------------------------------
// 		SET "STATIC NAV INDICATOR" (BLUE ONE) AGAIN FOR EVERY LOADING or RESIZE
//-------------------------------------------------------------

useLayoutEffect(() => {
  

  setTimeout(function(){ 
  
    if(CS.WINW > 600){

      setVTMRect(VTMText && VTMText.current ? VTMText.current.getBoundingClientRect() : {});
      setVMPRect(VMPText && VMPText.current ? VMPText.current.getBoundingClientRect() : {});
      setVMPPRect(VMPPText && VMPPText.current ? VMPPText.current.getBoundingClientRect() : {});
      setAMPRect(AMPText && AMPText.current ? AMPText.current.getBoundingClientRect() : {});
      setAMPPRect(AMPPText && AMPPText.current ? AMPPText.current.getBoundingClientRect() : {});
      setSupplierRect(SupplierText && SupplierText.current ? SupplierText.current.getBoundingClientRect() : {});
      setSCPACKRect(SCPACKText && SCPACKText.current ? SCPACKText.current.getBoundingClientRect() : {});
      setSCCOMPANYRect(SCCOMPANYText && SCCOMPANYText.current ? SCCOMPANYText.current.getBoundingClientRect() : {});
      
      }


  }, 1000);

  

}, [CS.WINW, APIData, lastRenderedId]);


//-------------------------------------------------------------
// 		HELPERS
//-------------------------------------------------------------

const getIDsAsArray=(ARRAY)=>{
  let convertedIDs = [];
  for (var i in ARRAY){ convertedIDs.push(ARRAY[i].id) }
  return convertedIDs;
};

//-------------------------------------------------------------
// 		HANDLE EXPORT
//-------------------------------------------------------------


const handleExport = async (type) => {
  const file = await fetchAPI(props.history, {
    apiType: type === "scpack" ? "scpackSearchExport" : "amppSearchExport",
    value: value,
    scope: scope,
    showDis:APIData.showDiscValue,
    ingList:getIDsAsArray(APIData.selectedIngValues),
    strList:getIDsAsArray(APIData.selectedStrengthValues),
    routeList:getIDsAsArray(APIData.selectedRoutesValues),
    formList:getIDsAsArray(APIData.selectedFormValues),
    suppList:getIDsAsArray(APIData.selectedSupplierValues)
    },setIsLoading);
  
  const link = document.createElement('a');
  
  link.href = file.url;
  link.download = `Export All ${+new Date()}.xlsx`;
  link.click();
}

//-------------------------------------------------------------
// 		HANDLE FAVORITES
//-------------------------------------------------------------

const mapFilters = function (list) {
  if (list && list.length > 0) {
    return list.map(function (item) {
      return item;
    });
  }
  return [];
};

const buildSearchProps = () => {
  return {
    term: value.toLowerCase(),
    scope: scope,
    ingredientFilters: mapFilters(getIDsAsArray(APIData.selectedIngValues)),
    routeFilters: mapFilters(getIDsAsArray(APIData.selectedRoutesValues)),
    formFilters: mapFilters(getIDsAsArray(APIData.selectedFormValues)),
    supplierFilters: mapFilters(getIDsAsArray(APIData.selectedSupplierValues)),
    showDiscontinued: APIData.showDiscValue,
  };
}

//-------------------------------------------------------------
// 		SET "HOVER NAV INDICATOR" (GREY ONE) AGAIN FOR EVERY LOADING
//-------------------------------------------------------------

useLayoutEffect(() => {
  if (VTMRect && CS.WINW > 600 && !hideMe){

    let hoverTarget;
    let elem = document.getElementById("hoverIndicator");
    if (hoverLink && hoverTarget){
      switch(hoverLink) {
        case "vtm": hoverTarget = VTMRect; break;
        case "vmp": hoverTarget = VMPRect; break;
        case "vmpp": hoverTarget = VMPPRect; break;
        case "amp": hoverTarget = AMPRect; break;
        case "ampp": hoverTarget = AMPPRect; break;
        case "Supplier": hoverTarget = SupplierRect; break;
        case "scpack": hoverTarget = SCPACKRect; break;
        case "sccompany": hoverTarget = SCCOMPANYRect; break;
        default: hoverTarget = VTMRect;
        }

        elem.style.width = ( hoverTarget.width ) +"px";
        elem.style.left = ( hoverTarget.left - 75) +"px";
        elem.style.opacity = 1;


    }
    else {

        elem.style.width = "0px";
        elem.style.opacity = 0;

    }
  
}}, [VTMRect, VMPRect, VMPPRect, AMPRect, AMPPRect, SupplierRect, SCPACKRect, SCCOMPANYRect, hoverLink, CS.WINW, hideMe]);



//-------------------------------------------------------------
// 		CHANGE VISIBLE STATUS IF OVERALL PAGE IS CHANGED
//-------------------------------------------------------------
useLayoutEffect(() => {
 
  if(
      pageName==="errorPage"
      || pageName==="aboutPage"
      || pageName==="contactPage"
      || pageName==="helpPage"
      || pageName==="legalPage"
      || pageName==="homePage"
  ){
    if (!hideMe){setHideMe(true)}
  } else {
    if (hideMe){setHideMe(false)}
  }
  
}, [pageName,hideMe]);



//-------------------------------------------------------------
// 		RETURN 
//-------------------------------------------------------------
if(hideMe){ return null }
return (
<div className="header">

  {
  CS.WINW > 600
  ?
    //{/* SEARCHBOX */}
    <div className="searchBoxC">
      <SearchBox/>
    </div>
  :
    // {/* MOBILE LOGO and JUST NAME */}
    <div>
    <LogoFull svgClass="LogoFull"/>
      <div
        className="MobileHeaderMedName"
        onClick={()=>{ openMobileSearch() }}
        >
        { APIData.headline }
      </div>
    </div>
  }
  
  

  {/* HORIZONTAL NAV */}
    <div className="horizontalNav">

      { APIData.VTMLength > 0
      ?
        <div ref={VTMText}
        className={APIData.tab==="vtms"?"active":""}
        onMouseOver = {()=>{ setHoverLink("vtm") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="vtms"?
        APIData.VTMLength === 1?"VTM":"VTMs("+ APIData.VTMLength +")"
        :
        <Link to={ "/"+ APIData.VTMURL }>{APIData.VTMLength === 1?"VTM":"VTMs("+ APIData.VTMLength +")"}</Link>
        }
        </div>
      :null}

      { APIData.VMPLength > 0
      ?
        <div ref={VMPText}
        className={APIData.tab==="vmps"?"active":""}
        onMouseOver = {()=>{ setHoverLink("vmp") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="vmps"?
        APIData.VMPLength === 1?"VMP":"VMPs("+ APIData.VMPLength +")"
        :
        <Link to={ "/"+ APIData.VMPURL }>{APIData.VMPLength === 1?"VMP":"VMPs("+ APIData.VMPLength +")"}</Link>
        }
        </div>
      :null}

      { APIData.VMPPLength > 0
      ?
        <div ref={VMPPText}
        className={APIData.tab==="vmpps"?"active":""}
        onMouseOver = {()=>{ setHoverLink("vmpp") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="vmpps"?
        APIData.VMPPLength === 1?"VMPP":"VMPPs("+ APIData.VMPPLength +")"
        :
        <Link to={ "/"+ APIData.VMPPURL }>{APIData.VMPPLength === 1?"VMPP":"VMPPs("+ APIData.VMPPLength +")"}</Link>
        }
        </div>
      :null}

      { APIData.AMPLength > 0
      ?
        <div ref={AMPText}
        className={APIData.tab==="amps"?"active":""}
        onMouseOver = {()=>{ setHoverLink("amp") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="amps"?
        APIData.AMPLength === 1?"AMP":"AMPs("+ APIData.AMPLength +")"
        :
        <Link to={ "/"+ APIData.AMPURL }>{APIData.AMPLength === 1?"AMP":"AMPs("+ APIData.AMPLength +")"}</Link>
        }
        </div>
      :null}

      { APIData.AMPPLength > 0
      ?
        <div ref={AMPPText}
        className={APIData.tab==="ampps"?"active":""}
        onMouseOver = {()=>{ setHoverLink("ampp") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="ampps"?
        APIData.AMPPLength === 1?"AMPP":"AMPPs("+ APIData.AMPPLength +")"
        :
        <Link to={ "/"+ APIData.AMPPURL }>{APIData.AMPPLength === 1?"AMPP":"AMPPs("+ APIData.AMPPLength +")"}</Link>
        }
        </div>
      :null}

      { APIData.SUPLength > 0
      ?
        <div ref={SupplierText}
        className={APIData.tab==="sups"?"active":""}
        onMouseOver = {()=>{ setHoverLink("Supplier") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="sups"?
        APIData.SUPLength === 1?(CS.WINW>600?"SUPPLIER":"SUP"):(CS.WINW>600?"SUPPLIER":"SUP")+"s("+ APIData.SUPLength +")"
        :
        <Link to={ "/"+ APIData.SUPURL }>{APIData.SUPLength === 1?(CS.WINW>600?"SUPPLIER":"SUP"):(CS.WINW>600?"SUPPLIER":"SUP")+"s("+ APIData.SUPLength +")"}</Link>
        }
        </div>
      :null}
      
      { APIData.SCPACKLength > 0
      ?
        <div ref={SCPACKText}
        className={APIData.tab==="scpacks"?"active":""}
        onMouseOver = {()=>{ setHoverLink("scpack") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="scpacks"?
        APIData.SCPACKLength === 1?"PACK":"PACKs("+ APIData.SCPACKLength +")"
        :
        <Link to={ "/"+ APIData.SCPACKURL }>{APIData.SCPACKLength === 1?"PACK":"PACKs("+ APIData.SCPACKLength +")"}</Link>
        }
        </div>
      :null}

      { APIData.SCCOMPANYLength > 0
      ?
        <div ref={SCCOMPANYText}
        className={APIData.tab==="sccompanys"?"active":""}
        onMouseOver = {()=>{ setHoverLink("sccompany") }}
        onMouseOut = {()=>{ setHoverLink(null) }}>
        {APIData.tab ==="sccompanys"?
        APIData.SCCOMPANYLength === 1?(CS.WINW>600?"COMPANY":"COMP"):(CS.WINW>600?"COMPANY":"COMP")+"s("+ APIData.SCCOMPANYLength +")"
        :
        <Link to={ "/"+ APIData.SCCOMPANYURL }>{APIData.SCCOMPANYLength === 1?(CS.WINW>600?"COMPANY":"COMP"):(CS.WINW>600?"COMPANY":"COMP")+"s("+ APIData.SCCOMPANYLength +")"}</Link>
        }
        </div>
      :null}
      {
        tab === 'scpacks' && level !== "ampp" && level !== "scpack" &&
        <div className="actionContainer">
          <Feature name={"favouritePacksAndCompanies"}>
            {value && <AddSearchToFavourites className="addToFavouritesButton" color={CS.WINW > 0 ? 'blue' : 'white'} type="supplychainpack" label="Add All to favourites" search={buildSearchProps()} />}
          </Feature>
          <Feature name={"exportPackData"}>
            {value && <Button className="exportButton" label="Export All" color={CS.WINW > 600 ? 'blue' : 'white'} onClick={async () => await handleExport("scpack")}/>}
          </Feature>
        </div>
      }
      {
        tab === 'ampps' && level !== "ampp" && level !== "scpack" &&
        <div className="actionContainer">
          <Feature name={"favouritePacksAndCompanies"}>
            {value && <AddSearchToFavourites className="addToFavouritesButton" color={APIData.AMPLength > 0 ? 'blue' : 'white'} type="ampp" label="Add All to favourites" search={buildSearchProps()} />}
          </Feature>
          <Feature name={"exportPackData"}>
            {value && <Button className="exportButton" label="Export All" color={APIData.AMPLength > 0 ? 'blue' : 'white'} onClick={async () => await handleExport("ampp")}/>}
          </Feature>
        </div>
      }
    </div>

    
  {/* LINE */}
    <div className="headerLine"></div>
    

  {/* HOR NAV INDICATORS */}
  { CS.WINW > 600 ?
    <div id="hoverIndicator" className="hoverIndicator"></div>
  :null}
    
  
  





</div>)};