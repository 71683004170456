import "./components/CookieConsent/CookieConsent.scss";
import "./css_app.scss";

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  AuthenticationEventHandler,
  EditProfile,
  ExistingUserRegister,
  ForgotPassword,
  NewToken,
  SignIn,
  SignOut,
  SignUp,
  withAuth,
} from "./auth";
import { BackEmblem } from "./components/BackEmblem.js";
import AppStore from "./contexts/AppStore/AppStore";
import * as Pages from "./pages";

const routes = ["VTM", "VMP", "VMPP", "AMP", "AMPP", "SUP", "SCCOMPANY"];

const RootApp = () => {
  return (
    <Router>
      <AppStore>
        <AuthenticationEventHandler />
        {/* ISLOADING */}
        <Pages.LoadingPage />

        {/* EMBLEM */}
        <BackEmblem svgClass="emblemSVG pozitive" />

        {/* RIGHT of SIDEBAR STARTS HERE */}
        {/* DATAZONE */}
        <Switch>
          {/* routes needed for B2C policy redirects */}
          <Route exact path="/signin">
            <SignIn />
          </Route>
          <Route exact path="/signup">
            <SignUp />
          </Route>
          <Route exact path="/signout">
            <SignOut />
          </Route>
          <Route exact path="/editprofile">
            <EditProfile />
          </Route>
          <Route exact path="/newtoken">
            <NewToken />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/existingUserRegister">
            <ExistingUserRegister />
          </Route>
          {/* routes that need to be exposed for informational reasons */}
          <Route exact path="/legal" render={(routeProps) => <Pages.LegalPage {...routeProps} />} />
          {/* routes need to ensure user is authenticated  */}
          <Route
            exact
            path="/"
            render={(routeProps) => withAuth(Pages.SearchPage)({ ...routeProps })}
          />
          <Route
            path="/favourites/:database(dmd|emcsupplychain)/:tab?"
            render={(routeProps) => withAuth(Pages.FavouritesPage)({ ...routeProps })}
          />
          <Route
            path="/search"
            render={(routeProps) => withAuth(Pages.SearchResultsPage)({ ...routeProps })}
          />
          {routes.map((route, i) => (
            <Route
              key={`tab-${i}`}
              exact
              path={`/${route}/:tab`}
              render={(routeProps) => withAuth(Pages.LevelPage)({ ...routeProps, level: route })}
            />
          ))}
          {routes.map((route, i) => (
            <Route
              key={`subtab-${i}`}
              exact
              path={`/${route}/:value/:tab`}
              render={(routeProps) => withAuth(Pages.LevelPage)({ ...routeProps, level: route })}
            />
          ))}
          <Route
            exact
            path="/SCPACK/:tab"
            render={(routeProps) => withAuth(Pages.LevelPage)({ ...routeProps, level: "SCPACK" })}
          />
          <Route
            exact
            path="/SCPACK/:value/:tab/:companyId"
            render={(routeProps) => withAuth(Pages.LevelPage)({ ...routeProps, level: "SCPACK" })}
          />
          {/* error pages and 404 */}
          <Route
            exact
            path="/403"
            render={(routeProps) => <Pages.ErrorPage type={403} {...routeProps} />}
          />
          <Route
            exact
            path="/404"
            render={(routeProps) => <Pages.ErrorPage type={404} {...routeProps} />}
          />
          <Route
            exact
            path="/500"
            render={(routeProps) => <Pages.ErrorPage type={500} {...routeProps} />}
          />
          <Route render={(routeProps) => <Pages.ErrorPage type={404} {...routeProps} />} />
        </Switch>
      </AppStore>
    </Router>
  );
};

export const App = RootApp;
