import "./RenderBoxes.scss";

import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { Button } from "../FormElements/Buttons/Button.js";
import { ImageRenderer } from "../ViewImages/ImageRenderer";
import { AccCollapseAllIcon } from "./AccCollapseAllIcon.js";
import { AccCollapseIcon } from "./AccCollapseIcon.js";

export const RenderDetailsAcc = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { amIBooStringNumberArrayOrObject,pennyToPound } = useContext(GlobalContext);
const { data, ACCNo, isThereJustOneSection, level, isAllCollapsed,changeAllSectionToCollapse,getACCSituations } = props;
// const { accNoExtCollapsedHeights } = props;

const [amIExtended,setAmIExtended]=useState(ACCNo===0?true:false);

const allRectZone = useRef();
const collactableZone = useRef();

const ACCTitle = data.label;
const ACCItems = data.items;
const [emcPageUrl,setEmcPageUrl] = useState(null);

//-------------------------------------------------------------
// 		EXTEND ME or COLLEPSE ME - INDIVIDUAL
//-------------------------------------------------------------
const changeAmIExtended = () => {
  setAmIExtended(!amIExtended);
}

//-------------------------------------------------------------
// 		SEND CURRENT COLLAPSE SITUATION TO HIGHER LEVEL PAGE
//-------------------------------------------------------------
useLayoutEffect(() => {

  // console.log (allRectZone.current.getBoundingClientRect().height);
  
  getACCSituations(ACCNo,amIExtended,allRectZone.current.getBoundingClientRect().height);

},[amIExtended, ACCNo,getACCSituations]);

//-------------------------------------------------------------
// 		EXTEND ME or COLLEPSE ME - DEPANDING ON HIGHER LEVEL COMMAND
//-------------------------------------------------------------
useLayoutEffect(() => {
  
  if (isAllCollapsed==="allExtended"){
    setAmIExtended(true);
  } else if (isAllCollapsed==="allCollapsed"){
    setAmIExtended(false);
  }
  
},[isAllCollapsed]);

//-------------------------------------------------------------
// 		HANDLE SPECIAL SECTIONS (Market Att inc price & availability) or LABELS (GTINCODE)
//-------------------------------------------------------------
const amIASpecialSection = (sectionName) => {

    if ( sectionName==="Combination Pack Content"){
      return true;
    }
    return false;
}
const amIASpecialLabel = (labelName) => {
  if (
      labelName==="GTIN Code"
      ||
      labelName==="Pack GTINs"
      ||
      labelName==="emcPageUrl"
      ||
      labelName==="Is Available"
      ||
      labelName==="Discontinued"
      ||
      labelName==="Price"
      ||
      labelName==="Previous Price"
      ||
      labelName==="Pack Images"
      ||
      labelName==="Product Images"
      ||
      labelName === "SmPC Link"
      ||
      labelName === "RMM Link"
      ||
      labelName === "PIL Link"
      ||
      labelName === "Company Id"
    ){
    return true
  } else {
    return false
  }
}
const amIASpecialLabelContent = (labelName,item,index) => {

    if ( labelName==="GTIN Code" ){     
      if (item.gtins.length === 0){

      } else {

        let GTINData = "";

        for (let i in item.gtins){
          if(GTINData!=="") {GTINData+="<br/>"}
          GTINData +="<b>"+ item.gtins[i].code;
          if (item.gtins[i].startDt && item.gtins[i].startDt !== "" && item.gtins[i].startDt !== null){ GTINData +="</b><br/>Start Date: "+ item.gtins[i].startDt; }
          if (item.gtins[i].endDt && item.gtins[i].endDt !== "" && item.gtins[i].endDt !== null){ GTINData +="<br/>End Date: "+ item.gtins[i].endDt; }
        }
  
        return (
          <div key={index} className="dataZone_container">
            <div className="dataZone_label">GTIN Codes</div>
            <div className="dataZone_line"> <div className="dataZone_line_yatay"></div> <div className="dataZone_line_dikey"></div> </div>
            <div className="dataZone_value">
              <div dangerouslySetInnerHTML={{ __html: GTINData }} />
            </div>
          </div>
        );

      }
      
      
    }
    else if ( labelName==="Pack GTINs" ){     
      if (item.value.length === 0){} 
      else {

        let GTINData = "";

        for (let i in item.value){
          if(GTINData!=="") {GTINData+="<br/>"}
          GTINData +="<b>"+ item.value[i];
        }
  
        return (
          <div key={index} className="dataZone_container"> 
            <div className="dataZone_label">GTIN Codes</div>
              <div className="dataZone_line"> 
                <div className="dataZone_line_yatay"></div> 
                <div className="dataZone_line_dikey"></div> 
              </div>
              <div className="dataZone_value">
                <div dangerouslySetInnerHTML={{ __html: GTINData }} />
              </div>
          </div>
        );

      }
      
      
    }
    else if ( labelName==="Market Attributes" ){
      
      let isAvailable = false;
      let isDiscontinues = false;
      let price = 0;

      for (let i in item){
        if(item[i].label === "Price"){ price = item[i].value }
        else if(item[i].label === "Is Available"){ isAvailable = item[i].value }
        else if(item[i].label === "Is Discontinued"){ isDiscontinues = item[i].value }
      }

      return (
        <div className="marketAtt_style">
          <div><span className="marketAtt_price">£ {price}</span> *
          <br/>
          (*) NHS Indicative Price
          </div>
          <div>
            {isAvailable?<div className="marketAtt_available">Available</div>:<div className="marketAtt_unavailable">Uvailable</div>}
            {isDiscontinues?<div className="marketAtt_discontinued">Discontinued</div>:null}
          </div>
      </div>
      );
    }
    else if ( labelName==="emcPageUrl" ){
      if(emcPageUrl !== item.value){setEmcPageUrl(item.value)}
      return null;
    }
    else if (labelName==="emcPageUrlContent"){

      return (
        <div className="dataZone_container emcUrlbuttonFix">
          <div className="dataZone_label"></div>
          <div className="dataZone_line">
                    <div className="emcUrlbutton_yatay"></div>
                    <div className="emcUrlbutton_dikey"></div>
          </div>
          <div className="dataZone_value emcUrlbuttonFix">
                      <Button buttonText="emc PRODUCT PAGE"
                      type="grey" level="default"
                      onClick = {()=>{ 
                        window.open(`${process.env.REACT_APP_EMC_SEARCH_URL}/${emcPageUrl}`, '_blank');
                        }}

                      />
          </div>
        </div>
      );


    }
    else if ( labelName==="Is Available" ){
      
      return (
        <div key={index} className="dataZone_container">
          <div className="dataZone_label">Is Available</div>
          <div className="dataZone_line">
                      <div className="dataZone_line_yatay"></div>
                      <div className="dataZone_line_dikey"></div>
          </div>
          <div className="dataZone_value">
            {
              (item.value).toUpperCase() === "FALSE" || item.value === "0" || item.value === "" || item.value === null
              ?
              <div className="marketAtt_unavailable">Unavailable</div>
              :
              <div className="marketAtt_available">Available</div>
            }
          </div>
        </div>
      );
      
      
    }
    else if ( labelName==="Discontinued" ){

        return(
        <div key={index} className="dataZone_container">
        <div className="dataZone_label">Discontinued</div>
        <div className="dataZone_line">
                    <div className="dataZone_line_yatay"></div>
                    <div className="dataZone_line_dikey"></div>
        </div>
        <div className="dataZone_value">
          {
            ((item.value).toUpperCase() === "TRUE" || item.value === "1" || item.value === "Discontinued Flag" )
            ?
            <div className="marketAtt_discontinued">Discontinued</div>
            :
            <div>{item.value}</div>
          }
        </div>
      </div>
      )
      // }
      
    }
    else if ( labelName==="Price" ){

      if (item.value === "" || item.value === null) { return null }
      
      return (
        <div key={index} className="dataZone_container">
          <div className="dataZone_label">Price</div>
          <div className="dataZone_line"> <div className="dataZone_line_yatay"></div> <div className="dataZone_line_dikey"></div> </div>
          <div className="dataZone_value">
            <span className="marketAtt_price">£ {pennyToPound(item.value)}</span> *
            <br/>
            (*) NHS Indicative Price
          </div>
        </div>
      );
      
      
    }
    else if ( labelName==="Previous Price" ){

      if (item.value === "" || item.value === null) { return null }
      
      return (
        <div key={index} className="dataZone_container">
          <div className="dataZone_label">Previous Price</div>
          <div className="dataZone_line"> <div className="dataZone_line_yatay"></div> <div className="dataZone_line_dikey"></div> </div>
          <div className="dataZone_value">£ {pennyToPound(item.value)}</div>
        </div>
      );
      
      
    }
    else if ( labelName==="Combination Pack Content" ){

      let packs = data.combPacks;
      // ACCItems.map((item, index) => {

      return packs.map((item,index)=> {
        return (
          <div key={index} className="dataZone_container">
            <div className="dataZone_label"></div><div className="dataZone_line"><div className="dataZone_line_yatay"></div><div className="dataZone_line_dikey"></div></div>
            <div className="dataZone_value">
            <Link
              className="dataZone_link"
              to={ item.id }>
              { item.nm }
            </Link>
            </div>
          </div>
        )
      })
    }
    else if(labelName === "SmPC Link"){
      if (item.value === "" || item.value === null || item.value === undefined) { return null }
      return(
        <div key={index} className="dataZone_container">
          <div className="dataZone_label">SmPC Link</div>
          <div className="dataZone_line"> <div className="dataZone_line_yatay"></div> <div className="dataZone_line_dikey"></div> </div>
          <div className="dataZone_value"> <a href={item.value} target="_blank" rel="noopener noreferrer">{item.value}</a></div>
        </div>
      )
    }
    else if(labelName === "RMM Link"){
      if (item.value === "" || item.value === null || item.value === undefined) { return null }
      return(
        <div key={index} className="dataZone_container">
        <div className="dataZone_label">RMM Link</div>
        <div className="dataZone_line"> <div className="dataZone_line_yatay"></div> <div className="dataZone_line_dikey"></div> </div>
        <div className="dataZone_value"> <a href={item.value} target="_blank" rel="noopener noreferrer">{item.value}</a></div>
      </div>
        )
    }
    else if(labelName === "PIL Link"){
      if (item.value === "" || item.value === null || item.value === undefined) { return null }
      return(
        <div key={index} className="dataZone_container">
        <div className="dataZone_label">PIL Link</div>
        <div className="dataZone_line"> <div className="dataZone_line_yatay"></div> <div className="dataZone_line_dikey"></div> </div>
        <div className="dataZone_value"> <a href={item.value} target="_blank" rel="noopener noreferrer">{item.value}</a></div>
      </div>
      )
    }
    else if( labelName === "Pack Images"){
      return ( 
        <ImageRenderer key={index} imageList = { item.value } />
      );
    }
    else if( labelName === "Product Images"){
       return (
       <ImageRenderer key={index} imageList = { item.value } />
       );
    }
    else if(labelName === "Company Id"){
      return null;
    }
    return null;
}

//-------------------------------------------------------------
// 		HANDLE SPECIAL VALUES (Gluten-Free / Sugar-Free etc)
//-------------------------------------------------------------
// eslint-disable-next-line
const amIASpecialValue = (label) => {

  if (
      (
        level === "vmp" &&
        (
          label==="Invalid" ||
          label==="Combination Product Indicator" ||
          label==="Sugar-free" ||
          label==="Gluten-free" ||
          label==="Preservative-free" ||
          label==="CFC-free"
        )
      )

      ||

      (
        level === "vmpp" &&
        (
          label==="Invalidity Flag" ||
          label==="Combination Pack Indicator"
        )
      )

      ||

      (
        level === "ampp" &&
        (
          label==="Schedule 1" ||
          label==="Schedule 2" ||
          label==="ASBC Product" ||
          label==="Personally Administered Indicator" ||
          label==="FP10 MDA" ||
          label==="Hospital Only Pack" ||
          label==="Nurse Formulary" ||
          label==="Extended Nurse Formulary" ||
          label==="Dental Formulary" ||
          label==="Broken Bulk Eligibility" ||
          label==="Calendar Pack" ||
          label==="FP34 D Prescription" ||
          label==="Invalid"
        )
      )

      ||

      (
        level === "amp" &&
        (
          label==="invalid" ||
          label==="EMA Additional Monitoring" ||
          label==="Parallel Import"
        )
      )
      

  ){ return true }
  else { return false }
}
const amIASpecialValueCorrection = (label,value) => {

  if (
        
        (
          level === "vmp" &&
          (
            label==="Invalid" ||
            label==="Sugar-free" ||
            label==="Gluten-free" ||
            label==="Preservative-free" ||
            label==="CFC-free"
          )
        )
  
        ||
  
        (
          level === "vmpp" &&
          (
            label==="Invalidity Flag"
          )
        )
  
        ||
  
        (
          level === "ampp" &&
          (
            label==="Schedule 1" ||
            label==="Schedule 2" ||
            label==="ACBS Product" ||
            label==="FP10 MDA" ||
            label==="Hospital Only Pack" ||
            label==="Nurse Formulary" ||
            label==="Extended Nurse Formulary" ||
            label==="Dental Formulary" ||
            label==="Broken Bulk Eligibility" ||
            label==="Calendar Pack" ||
            label==="FP34 D Prescription" ||
            label==="Invalid"
          )
        )
  
        ||
  
        (
          level === "amp" &&
          (
            label==="Invalid" ||
            label==="EMA Additional Monitoring" ||
            label==="Parallel Import"
          )
        )


    )
  { 
    if (value === "" || value === null || value === 0 || value === "0" || value.toUpperCase() === "FALSE")
    { 
      return "No"
    }
    else if (value === 1 || value === "1" || value.toUpperCase() === "TRUE")
    {
      return "Yes"
    }
  }
  else if
    (
    label === "Combination Product Indicator" || label==="Personally Administered Indicator" || label === "Combination Pack Indicator"
    ){
    if (value === "" || value === null || value === 0 || value === "0" || value.toUpperCase() === "FALSE"){
      return "N/A"
    }
  }
  

  return value;


}

//-------------------------------------------------------------
// 		CHANGE OPENED ACCORDION ITEM, CLOSE OTHERS and CALCULATE ACC HEIGHT and SCROLL TO BEGINNING AFTER OPENING A ACC ZONE.
//-------------------------------------------------------------
useLayoutEffect(() => {

  if(amIExtended){
    collactableZone.current.style.maxHeight = (collactableZone.current.scrollHeight+100) +"px";
  } else {
    collactableZone.current.style.maxHeight = 0 +"px";
  }

},[amIExtended]);

//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
<div className="renderDetails_acc" ref={allRectZone}>
  {/* Collapse All Icon */}
  {
    // ACCNo===0&&
    !isThereJustOneSection?

      <div
        className="renderDetails_mainLabel_collapseAll"
        onClick = {()=>{ 
          isAllCollapsed==="allCollapsed"
          ?
          changeAllSectionToCollapse("allExtended")
          :
          changeAllSectionToCollapse("allCollapsed")
        }}
      >
          <AccCollapseAllIcon type={
            isAllCollapsed==="allCollapsed"
            ?
            "+"
            :
            "-"
          }/>
      </div>

  :null}

  {/* Section Title And Accordion Animation */}
  <div
    className={"renderDetails_mainLabel" + (isThereJustOneSection?" disable":"")}
    { ...( !isThereJustOneSection && { onClick: () => changeAmIExtended()    } )}
    >
  
    {
      !isThereJustOneSection?
          <div className="renderDetails_iconArea" >
            <AccCollapseIcon type={amIExtended?"-":"+"}/>
          </div>
      :
      null
    }
    <div className="renderDetails_LabelArea">
      {ACCTitle}
    </div>
  </div>
  {/* Extendable Zone Starts Here */}
  <div ref={ collactableZone } className="renderDetails_extendableZone">
        {
          //-------------------------------------------------------------
          //         Render Special Sections Content -> such as Combination Packs
          //-------------------------------------------------------------
        }
        {
          amIASpecialSection(ACCTitle)
          ?
            <div className="renderDetails_items">
                {amIASpecialLabelContent(ACCTitle,ACCItems)}
            </div>
          :
          null
        }
      {
        //-------------------------------------------------------------
        // 		Render Default Sections Content
        //-------------------------------------------------------------
      }
      <div className="renderDetails_items">
      {

        // !!!!
        // RenderDetails 63'te de empty controller var. Onu unutma!!!!!!!
        // !!!!
        // amIASpecialSection(ACCTitle) || !ACCItems || ACCItems===null || ACCItems === "" || ACCItems.length === 0 ? null : // EMPTY CONTROLLER -- OPEN THIS
        amIASpecialSection(ACCTitle) || !ACCItems ? null : // EMPTY CONTROLLER -- REMOVE THIS LINE !!!!!!
      
        ACCItems.map((item, index) => {

        // if(!amIASpecialValue(item.label) && (item.value === "" || item.value === null)){ return null } // EMPTY CONTROLLER -- OPEN THIS
        if(amIASpecialLabel(item.label)){ return ( amIASpecialLabelContent(item.label,item,index) ) }

        return (
          
          <div key={index} className="dataZone_container">
            <div className="dataZone_label">{item.label}</div>
            <div className="dataZone_line">
                        <div className="dataZone_line_yatay"></div>
                        <div className="dataZone_line_dikey"></div>
            </div>
            <div className="dataZone_value">
              {
              // handle special arrays
              amIBooStringNumberArrayOrObject(item.value) === "array"
              ?
                      <div>
                      {item.value.map((item,index)=>{return(
                        <div key={index}>{ item }</div>
                      )})}
                      </div>
              :
              // handle rest of them
              amIASpecialValueCorrection(item.label,item.value)
              }
            </div>
          </div>
          
        )})}
      </div>        
      {
        //-------------------------------------------------------------
        // 		Render SubSections Content
        //-------------------------------------------------------------
      }
      <div className="renderDetails_items_subSections">
      { !data.subTag || !data[data.subTag] ? null : data[data.subTag].map((item, index) => {

        return (<div key={index}><div className="dataZone_subSection_title">{item.label}</div>

        {!item.items?null:item.items.map((item, index) => {

          // if (item.value === "" || item.value === null){ return null } // EMPTY CONTROLLER -- OPEN THIS

          return (
            
            <div key={index} className="dataZone_container">
              <div className="dataZone_label">{item.label}</div>
              <div className="dataZone_line">
                          <div className="dataZone_line_yatay"></div>
                          <div className="dataZone_line_dikey"></div>
              </div>
              <div className="dataZone_value">
                        {
                          amIBooStringNumberArrayOrObject(item.value) === "array"
                          ?
                                  <div>
                                  {item.value.map((item,index)=>{return(
                                    <div key={index}>{ item }</div>
                                  )})}
                                  </div>
                          :
                          item.value
                          }
                </div>
            </div>
            
          )})}
        
        </div>)
      })}
      </div>
      {
        emcPageUrl && (ACCTitle==="AMPP Attributes" || ACCTitle==="AMP Attributes")
        ?
        amIASpecialLabelContent("emcPageUrlContent")
        :
        null
      }
  {/* Extendable Zone Ends Here */}
  </div>
</div>
)};