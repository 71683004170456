import "./Button.scss";

import classNames from "classnames";
import * as React from "react";

interface ButtonProps {
  className?: string;
  color: "blue" | "transparent" | "white";
  label?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  icon?: JSX.Element;
}
export const Button: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props,
) => (
  <button
    {...props}
    disabled={props.disabled}
    className={classNames(props.className, "btn", props.color, { withIcon: !!props.icon })}
    onClick={props.onClick}
  >
    {props.icon}
    {props.children ?? props.label}
  </button>
);

export default Button;
