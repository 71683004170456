import * as React from "react";

import { FeatureFlagsStore } from "../contexts/FeatureFlagsStore/FeatureFlagsStore";
import { FeatureFlags } from "../types";

export const useFeatureFlags = (): FeatureFlags => {
  const contextState = React.useContext(FeatureFlagsStore);

  if (contextState === null) {
    throw new Error("useFeatureFlags must be used within a FeatureFlagsProvider tag");
  }

  return contextState;
};
