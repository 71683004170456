import { authentication } from "../../auth";

export const http = {
  get,
  getBlob,
  post,
  put,
  delete: _delete,
};

async function get<T>(url: string): Promise<T> {
  const token = await authentication.getToken();

  const requestOptions = {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await fetch(url, requestOptions);
  return handleResponse<T>(response);
}

async function getBlob(url: string): Promise<Blob> {
  const token = await authentication.getToken();

  const requestOptions = {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await fetch(url, requestOptions);
  return response.blob();
}

async function post<TBody, TResult>(url: string, body?: TBody): Promise<TResult> {
  const token = await authentication.getToken();

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);

  return handleResponse(response);
}

async function put<T>(url: string, body: T): Promise<T> {
  const token = await authentication.getToken();

  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(url, requestOptions);
  return handleResponse<T>(response);
}

async function _delete<T>(url: string, body?: T): Promise<void> {
  const token = await authentication.getToken();

  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);

  return handleResponse(response);
}

async function handleResponse<T>(response: Response): Promise<T> {
  const text = await response.text();
  const data = text && JSON.parse(text);

  if (!response.ok) {
    const error = (data && data) || response.statusText;
    return Promise.reject(error);
  }

  return data;
}
