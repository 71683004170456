import SearchService from "../core/services/search.service";
import { TabId } from "../pages/NewSearchResultsPage/SearchResultsTypes";
import useAddSearchToFavourites from "./useAddSearchToFavourites";
import useExportSearchResults from "./useExportSearchResults";

export default function useMobileActions(tabId: TabId) {
  const { add } = useAddSearchToFavourites(SearchService.getBackendLabel(tabId));
  const { initExport } = useExportSearchResults(SearchService.getBackendLabel(tabId));

  switch (tabId) {
    case "totalAmpps":
    case "totalSupplyChainPacks":
      return [
        {
          label: "Add All to favourites",
          action: () => add(),
        },
        {
          label: "Export All",
          action: () => initExport(SearchService.getBackendLabel(tabId)),
        },
      ];
    default:
      return [];
  }
}
