import React, { createContext, useState, useLayoutEffect, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const GlobalContext = createContext();

const GlobalStore = (props) => {
  //-------------------------------------------------------------
  // 		DEFINE GS
  //-------------------------------------------------------------
  const { search } = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const [pageName, setPageName] = useState(null);

  const [lastRenderedId, setLastRenderedId] = useState(null);
  const [latestSearchValue, setLatestSearchValue] = useState(null);
  const [latestScopeValue, setLatestScopeValue] = useState(null);
  const [searchBoxStatus, setSearchBoxStatus] = useState("default");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [database, setDatabase] = useState(params.get("scope"));
  const [searchQuery, setSearchQuery] = useState(params.get("term"));

  const [APIData, setAPIData] = useState({
    value: null,
    level: null,
    headline: null,
    tab: null,
    scope: null,

    // Filter Datas

    showDiscValue: false,

    ingValues: [],
    selectedIngValues: [],

    strengthValues: [],
    selectedStrengthValues: [],

    formValues: [],
    selectedFormValues: [],

    routesValues: [],
    selectedRoutesValues: [],

    supplierValues: [],
    selectedSupplierValues: [],

    anyFilterAttSelected: "",

    // Header Datas & URLs
    VTMLength: 0,
    VMPLength: 0,
    VMPPLength: 0,
    AMPLength: 0,
    AMPPLength: 0,
    SUPLength: 0,
    SCPACKLength: 0,
    SCCOMPANYLength: 0,

    VTMURL: "a",
    VMPURL: "a",
    VMPPURL: "a",
    AMPURL: "a",
    AMPPURL: "a",
    SUPURL: "a",
    SCPACKURL: "a",
    SCCOMPANYURL: "a",
  });

  const [layoutData, setLayoutData] = useState({
    isMobileMenuActive: false,
    isMobileFilterZoneActive: false,
    isMobileSearchActive: false,

    isFilterMenuActive: false,
  });

  //-------------------------------------------------------------
  // 		LOCK or UNLOCK BODY SCROLL
  //-------------------------------------------------------------

  useEffect(() => {
    if (
      layoutData.isFilterMenuActive ||
      layoutData.isMobileMenuActive ||
      layoutData.isMobileFilterZoneActive ||
      layoutData.isMobileSearchActive ||
      isLoading ||
      searchBoxStatus === "onChange" ||
      searchBoxStatus === "focus"
    ) {
      // scroll disabled
      // document.getElementsByTagName('body')[0].style = 'overflow-y:hidden;';
      document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:hidden;");
    } else {
      if (
        history.location.pathname.indexOf("scpack") > 0 ||
        history.location.pathname.indexOf("sccompany") > 0
      ) {
        setLatestScopeValue("sc");
      } else {
        setLatestScopeValue("dmd");
      }
      // scroll enabled
      // document.getElementsByTagName('body')[0].style = 'overflow-y:auto;'
      document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:auto;");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layoutData.isFilterMenuActive,
    isLoading,
    searchBoxStatus,
    layoutData.isMobileMenuActive,
    layoutData.isMobileFilterZoneActive,
    layoutData.isMobileSearchActive,
  ]);

  //-------------------------------------------------------------
  // 		API IDENTIFER
  //-------------------------------------------------------------
  const amIBooStringNumberArrayOrObject = (data) => {
    let typeofData = typeof data;
    // console.log (typeof data);

    if (typeofData === "number") {
      return "number";
    } else if (typeofData === "string") {
      return "string";
    } else if (typeofData === "boolean") {
      return "boolean";
    } else if (typeofData === "object") {
      if (Array.isArray(data)) {
        return "array";
      } else {
        return "object";
      }
    } else {
      return "undefined";
    }
  };
  //-------------------------------------------------------------
  // 		PENNY to POUND
  //-------------------------------------------------------------
  const pennyToPound = (value) => {
    return (Number(value) / 100).toFixed(2);
  };
  //-------------------------------------------------------------
  // 		GO TO HOME function
  //-------------------------------------------------------------
  const goToHome = () => {
    if (pageName !== "homePage") {
      history.push("/");
    }
  };
  //-------------------------------------------------------------
  // 		FILTER ZONE ACTIONS -> open / cancel / clear / apply
  //-------------------------------------------------------------
  const openFilterZone = () => {
    if (searchBoxStatus !== "default") {
      setSearchBoxStatus("default");
    }
    if (layoutData.isFilterMenuActive !== true) {
      setLayoutData((prevGS) => ({
        ...prevGS,
        isFilterMenuActive: true,
      }));
    }
  };
  const closeFilterZone = () => {
    if (layoutData.isFilterMenuActive !== false) {
      setLayoutData((prevGS) => ({
        ...prevGS,
        isFilterMenuActive: false,
      }));
    }
  };
  const clearAllFilter = () => {
    setAPIData((prev) => ({
      ...prev,
      showDiscValue: false,
      selectedIngValues: [],
      selectedStrengthValues: [],
      selectedFormValues: [],
      selectedRoutesValues: [],
      selectedSupplierValues: [],
      anyFilterAttSelected: "",
    }));

    if (layoutData.isFilterMenuActive !== false) {
      setLayoutData((prevGS) => ({
        ...prevGS,
        isFilterMenuActive: false,
      }));
    }
  };
  //-------------------------------------------------------------
  // 		MOBILE MENU / FILTER OPEN FUNCTIONS
  //-------------------------------------------------------------
  const openMobileMenu = () => {
    setLayoutData((prev) => ({
      ...prev,
      isMobileMenuActive: true,
      isMobileFilterZoneActive: false,
      isMobileSearchActive: false,
    }));
  };

  const openMobileSearch = () => {
    setLayoutData((prev) => ({
      ...prev,
      isMobileMenuActive: false,
      isMobileFilterZoneActive: false,
      isMobileSearchActive: true,
    }));
  };

  const openMobileFilter = () => {
    setLayoutData((prev) => ({
      ...prev,
      isMobileMenuActive: false,
      isMobileFilterZoneActive: true,
      isMobileSearchActive: false,
    }));
  };

  const closeAllMobileZones = () => {
    setLayoutData((prev) => ({
      ...prev,
      isMobileMenuActive: false,
      isMobileFilterZoneActive: false,
      isMobileSearchActive: false,
    }));
  };
  //-------------------------------------------------------------
  // 		BACK TO RESULTS FUNCTION
  //-------------------------------------------------------------
  const backToResults = () => {
    history.push({
      pathname: "/search",
      search:
        "?" + new URLSearchParams({ term: latestSearchValue, scope: latestScopeValue }).toString(),
    });
  };

  //-------------------------------------------------------------
  // 		COLORED CONSOLE LOG FOR TESTING
  //-------------------------------------------------------------
  const log = (TEXT) => {
    console.log("%c " + TEXT + " ", "background: #f00; color: #fff");
  };

  //-------------------------------------------------------------
  // 		RESET APIData
  //-------------------------------------------------------------
  const resetAPIData = useCallback(() => {
    if (APIData.value === null) {
      return;
    }

    setLastRenderedId(null);
    setAPIData((prev) => ({
      ...prev,
      value: null,
      headline: null,
      level: null,
      tab: null,
      showDiscValue: false,
      ingValues: [],
      selectedIngValues: [],
      strengthValues: [],
      selectedStrengthValues: [],
      formValues: [],
      selectedFormValues: [],
      routesValues: [],
      selectedRoutesValues: [],
      supplierValues: [],
      selectedSupplierValues: [],
      anyFilterAttSelected: "",
      VTMLength: 0,
      VMPLength: 0,
      VMPPLength: 0,
      AMPLength: 0,
      AMPPLength: 0,
      SUPLength: 0,
      VTMURL: "a",
      VMPURL: "a",
      VMPPURL: "a",
      AMPURL: "a",
      AMPPURL: "a",
      SUPURL: "a",
    }));
  }, [APIData.value]);

  //-------------------------------------------------------------
  // 		IF PAGE TITLE CHANGE, (EXCEPT SEARCH PAGE) RESET APIDATA
  //-------------------------------------------------------------
  useLayoutEffect(() => {
    if (pageName !== "searchListingPage" && pageName !== "levelPage") {
      resetAPIData();
    }

    closeAllMobileZones();
  }, [pageName, resetAPIData]);

  //-------------------------------------------------------------
  // 		IF HEADLINE IS CHANGED, CHANGE PAGE BROWSER TITLE
  //-------------------------------------------------------------
  useLayoutEffect(() => {
    if (APIData.headline === null) {
      document.title = "emc med data browser";
    } else {
      document.title = APIData.headline;
    }
  }, [APIData.headline]);
  //-------------------------------------------------------------
  // 		RETURN
  //-------------------------------------------------------------

  return (
    <GlobalContext.Provider
      value={{
        database,
        setDatabase,
        lastRenderedId,
        setLastRenderedId,
        latestSearchValue,
        setLatestSearchValue,
        latestScopeValue,
        setLatestScopeValue,
        searchBoxStatus,
        setSearchBoxStatus,
        isLoading,
        setIsLoading,
        isLoadingMore,
        setIsLoadingMore,
        APIData,
        setAPIData,
        layoutData,
        setLayoutData,
        pageName,
        setPageName,
        isMobileMenuOpen,
        setMobileMenuOpen,
        searchQuery,
        setSearchQuery,

        amIBooStringNumberArrayOrObject,
        pennyToPound,
        goToHome,

        openFilterZone,
        closeFilterZone,
        clearAllFilter,

        resetAPIData,
        log,

        openMobileMenu,
        openMobileFilter,
        openMobileSearch,
        closeAllMobileZones,

        backToResults,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalStore;
