import * as React from "react";

import { PopupStore } from "../../contexts/PopupStore/PopupStore";
import { http } from "./http.service";

export interface NotificationSettings {
  frequency: "Daily" | "Weekly" | "Monthly";
  application: "SupplyChain" | "Dmd";
}

interface BackendResponse {
  preferences: NotificationSettings[];
}

const useFavSettingsService = () => {
  const popupStore = React.useContext(PopupStore);
  const baseUrl = `${process.env.REACT_APP_API_PATH}/preferences`;

  async function loadFavSettings(): Promise<BackendResponse> {
    try {
      return await http.get<BackendResponse>(baseUrl + "/me");
    } catch (e) {
      popupStore.openPopup("error", "Could not load favourite settings.");
      console.error(e);
      return { preferences: [] };
    }
  }

  async function saveFavSettings(payload: NotificationSettings) {
    try {
      await http.post<NotificationSettings, void>(baseUrl, payload);
      popupStore.openPopup("success", "Settings saved!");
    } catch (e) {
      popupStore.openPopup("error", "Could not save settings.");
      console.error(e);
    }
  }

  async function deleteFavSettings(database: NotificationSettings["application"]) {
    try {
      await http.delete(baseUrl + `/${database}`);
      popupStore.openPopup("success", "Settings saved!");
    } catch (e) {
      popupStore.openPopup("error", "Could not save settings.");
      console.error(e);
    }
  }

  return { loadFavSettings, saveFavSettings, deleteFavSettings };
};

export default useFavSettingsService;
