import React from 'react';



export const LoadingSVG = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { svgClass } = props;
// const [currentValue,setCurrentValue]=useState("");



//-------------------------------------------------------------
// 		FUNCTIONS
//-------------------------------------------------------------



//-------------------------------------------------------------
// 		LIFE CYCLE
//-------------------------------------------------------------




//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className = { svgClass }
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 128 35"
    space="preserve">

    <g><circle cx="17.5" cy="17.5" r="17.5"/><animate attributeName="opacity" dur="1800ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.167;0.5;0.668;1" values="0.3;1;1;0.3;0.3"/></g>
    <g><circle cx="110.5" cy="17.5" r="17.5"/><animate attributeName="opacity" dur="1800ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.334;0.5;0.835;1" values="0.3;0.3;1;1;0.3"/></g>
    <g><circle cx="64" cy="17.5" r="17.5"/><animate attributeName="opacity" dur="1800ms" begin="0s" repeatCount="indefinite" keyTimes="0;0.167;0.334;0.668;0.835;1" values="0.3;0.3;1;1;0.3;0.3"/></g>


  </svg>
)};










/* TURNING EMC LOGO

<svg version="1.1"
    className = { svgClass }
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 128 128"
    space="preserve">

    <g>
    <path d="M101.4,50.2V28.6C101.4,14.5,89.9,3,75.8,3C62.3,3,51.3,13.4,50.2,26.6H28.6C14.5,26.6,3,38.1,3,52.2
    c0,13.4,10.4,24.5,23.6,25.6v21.6c0,14.1,11.5,25.7,25.7,25.7c13.4,0,24.5-10.4,25.6-23.6h21.6c14.1,0,25.7-11.5,25.7-25.7
    C125,62.3,114.6,51.3,101.4,50.2z M73.7,80.1v17.2H56.3c-1.1,0-2-0.9-2-2V80.1c0-3.5-2.9-6.4-6.4-6.4H30.7V56.3c0-1.1,0.9-2,2-2
    h15.2c3.5,0,6.4-2.9,6.4-6.4V30.7h17.4c1.1,0,2,0.9,2,2v15.2c0,3.5,2.9,6.4,6.4,6.4h17.2v17.4c0,1.1-0.9,2-2,2H80.1
    C76.6,73.7,73.7,76.6,73.7,80.1z M75.8,7.2c11.8,0,21.5,9.6,21.5,21.5v21.5H80.1c-1.2,0-2.2-1-2.2-2.2V32.7c0-3.4-2.8-6.1-6.1-6.1
    H54.4C55.5,15.7,64.6,7.2,75.8,7.2z M7.2,52.2c0-11.8,9.6-21.5,21.5-21.5h21.5v17.2c0,1.2-1,2.2-2.2,2.2H32.7
    c-3.4,0-6.1,2.8-6.1,6.1v17.3C15.7,72.5,7.2,63.4,7.2,52.2z M52.2,120.8c-11.8,0-21.5-9.6-21.5-21.5V77.9h17.2c1.2,0,2.2,1,2.2,2.2
    v15.2c0,3.4,2.8,6.1,6.1,6.1h17.3C72.5,112.3,63.4,120.8,52.2,120.8z M99.4,97.3H77.9V80.1c0-1.2,1-2.2,2.2-2.2h15.2
    c3.4,0,6.1-2.8,6.1-6.1V54.4c10.9,1.1,19.4,10.2,19.4,21.4C120.8,87.6,111.2,97.3,99.4,97.3z"/>
      
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 64 64"
        to="90 64 64"
        dur="1800ms"
        repeatCount="indefinite">
      </animateTransform>
    
    </g>


  </svg>


*/