import React from 'react';



export const LogoEmblem = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { svgClass } = props;




//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className = { svgClass }
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 800 800"
    space="preserve">


      <path
        d="M639.3,312v-138c0-90.4-73.6-164-164-164c-85.9,0-156.5,66.4-163.3,150.6h-138
        c-90.4,0-164,73.6-164,164c0,85.9,66.4,156.5,150.6,163.3v138c0,90.4,73.6,164,164,164c85.9,0,156.5-66.4,163.3-150.6h138
        c90.4,0,164-73.6,164-164C789.9,389.4,723.5,318.9,639.3,312z M462,503v109.6H350.6c-7,0-12.6-5.7-12.6-12.6v-97
        c0-22.6-18.4-41-41-41H187.4V350.6c0-7,5.7-12.6,12.6-12.6h97c22.6,0,41-18.4,41-41V187.4h111.3c7,0,12.6,5.7,12.6,12.6v97
        c0,22.6,18.4,41,41,41h109.6v111.3c0,7-5.7,12.6-12.6,12.6h-97C480.4,462,462,480.4,462,503z M475.3,36.8
        c75.7,0,137.3,61.6,137.3,137.3v137.3H503c-7.9,0-14.4-6.4-14.4-14.4v-97c0-21.7-17.6-39.3-39.3-39.3H338.7
        C345.4,91.3,404.1,36.8,475.3,36.8z M36.8,324.7c0-75.7,61.6-137.3,137.3-137.3h137.3V297c0,7.9-6.4,14.4-14.4,14.4h-97
        c-21.7,0-39.3,17.6-39.3,39.3v110.7C91.3,454.6,36.8,395.9,36.8,324.7z M324.7,763.2c-75.7,0-137.3-61.6-137.3-137.3V488.6H297
        c7.9,0,14.4,6.4,14.4,14.4v97c0,21.7,17.6,39.3,39.3,39.3h110.7C454.6,708.7,395.9,763.2,324.7,763.2z M625.9,612.6H488.6V503
        c0-7.9,6.4-14.4,14.4-14.4h97c21.7,0,39.3-17.6,39.3-39.3V338.7c69.5,6.7,124,65.4,124,136.7C763.2,551,701.6,612.6,625.9,612.6z"/>


  </svg>
)};