import classNames from "classnames";
import * as React from "react";
import { Modal } from "../../components/Library/Modal/Modal";
import "./ModalStore.scss";

interface ModalStoreInterface {
  openModal: (
    title: string,
    content: JSX.Element,
    cb?: () => void,
    customButtons?: JSX.Element[],
  ) => void;
  closeModal: () => void;
}

export const ModalStore = React.createContext<ModalStoreInterface>({} as ModalStoreInterface);

export const ModalStoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("Modal title");
  const [content, setContent] = React.useState(<p>Default modal message</p>);
  const [callback, setCallback] = React.useState<Function>();
  const [customButtons, setCustomButtons] = React.useState<JSX.Element[] | undefined>();

  function openModal(
    title: string,
    content: JSX.Element,
    cb?: () => void,
    customButtons?: JSX.Element[],
  ) {
    setTitle(title);
    setContent(content);
    setCallback(() => cb);
    setOpen(true);
    customButtons ? setCustomButtons(customButtons) : setCustomButtons(undefined);
    document.body.classList.add("scrollLocked");
  }

  function closeModal() {
    setOpen(false);
    document.body.classList.remove("scrollLocked");
  }

  return (
    <ModalStore.Provider value={{ openModal, closeModal }}>
      {isOpen && (
        <div className={classNames("modalContainer", { open: isOpen })}>
          <Modal
            title={title}
            content={content}
            onClose={() => closeModal()}
            onConfirm={() => {
              closeModal();
              callback && callback();
            }}
            customButtons={customButtons}
          />
        </div>
      )}
      {children}
    </ModalStore.Provider>
  );
};
