import React from 'react';
import "./CheckBox.scss";







export const CheckBox = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { currentValue,label,bigLabel,onChange } = props;










//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
<div
  className={ "checkBox_main" + (bigLabel?" big":"") }
  onClick = {()=>{ onChange(currentValue) }}
  >

  <div className={"checkBox_box "+ currentValue } />
  <div className="checkBox_label">{label}</div>

</div>)};