import "./Radio.scss";

import * as React from "react";

interface RadioProps {
  label?: string;
  valueName?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (newValue: boolean) => void;
}

export const Radio: React.FC<RadioProps> = (props) => {
  const { label, valueName, value, disabled, onChange } = props;

  return (
    <label className="lib-radio b-contain">
      <span>{label}</span>
      <input
        type="radio"
        name={valueName}
        checked={value}
        disabled={disabled}
        onChange={() => onChange && onChange(!value)}
      />
      <div className="b-input"></div>
    </label>
  );
};

export default Radio;
