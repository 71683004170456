import * as React from "react";

import { useFeatureFlags } from "../Hooks/useFeatureFlags";
import { Flags } from "../types";

type Props = React.PropsWithChildren<{
  name: Flags;
}>;

export const Feature = ({ name, children }: Props): JSX.Element | null => {
  const features = useFeatureFlags();

  if (!features.loading && features[name]) {
    return <>{children}</>;
  }

  return null;
};
