import React, {useContext} from 'react';
import "./RenderBoxes.scss";
import {Link} from "react-router-dom";
import { ExternalURLicon } from "../ExternalURLicon.js";
import { GlobalContext } from '../../contexts/GlobalStore/GlobalStore';









export const RenderBoxes = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { content,screenSize,clientTop,totalLength} = props;
const { pennyToPound } = useContext(GlobalContext);



//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <div className={"renderBoxes_main"+(screenSize==="half"?" half":"")}>



  {/* Search filter can be added here */}
  {!content?null:content.map((item, index) => {

		return (
    <React.Fragment key={index}>


    {
      clientTop && index !== 0 && (index)%clientTop === 0
      ?
      <div className="box_seperator">Items {index} - {index+clientTop>totalLength?totalLength:index+clientTop} of { totalLength }</div>
      :
      null
    }

    <div className="boxes_maincontainer">

    { // external URL is active
      (item.type === "amp" || item.type === "ampp") && item.productId && item.productId !== ""
      ?
      <ExternalURLicon className="externalURLicon" productId={item.productId}/>
      :
      null
    }

    <Link 
      to={ "/"+ item.type +"/"+ item.id +"/"+ item.type +"s" + (item.type === "scpack" ? "/" +  item.companyId : "") }
    >
    {
    item.type === "vmp" || item.type === "vmpp"
    ?
    <div className="boxes_container">
      <div className="VMP_head1st">
      <div className="VMP_IdCell"><span>{item.type}</span> {item.id}</div>
      <div className="VMP_nameCell">{item.nm || item.name || item.desc}</div>
      </div>
      {
        (!item.route || item.route === null || item.route === "")
        ||
        (!item.form || item.form === null || item.form === "")
        ?
        null
        :
        <div className="VMP_routeCell">{item.route} / {item.form}</div>
      }
    </div>
    :
    item.type === "amp"
    ?
    <div className="boxes_container">
      <div className="VMP_head1st">
      <div className="VMP_IdCell"><span>{item.type}</span> {item.id}</div>
      <div className="VMP_nameCell">{item.desc || item.nm || item.name }</div>
      </div>
      <div className="AMP_3rdCell">
        <div>
          {!item.bnf && !item.bnfCode?null:
            <div className="AMP_codeLine"><span>BNF</span> {item.bnf || item.bnfCode}</div>
          }
          {!item.atc?null:
            <div className="AMP_codeLine"><span>ATC</span> {item.atc}</div>
          }
          
        </div>
        
      </div>
    </div>
    :
    item.type === "ampp"
    ?
    <div className="boxes_container">
      <div className="VMP_head1st">
      <div className="VMP_IdCell"><span>{item.type}</span> {item.id}</div>
      <div className="VMP_nameCell">{item.nm || item.name || item.desc}</div>
      </div>
      <div className="AMP_3rdCell">
        

        
          
        <div className="AMPP_price_discArea">


          <div style={{width:"50%"}}>
              {
                item.gtinCodes.length === 0?null:
                <div className="AMP_codeLine"><span>GTIN</span> {item.gtinCodes[0]}</div>
              }

              {
                item.priceBasis && (item.priceBasis==="No Price Available" || item.priceBasis==="No Price priced when manufactured")?null:
                <div className="AMPP_priceLine"><span>£</span> {pennyToPound(item.price)}</div>
              }
          </div>
          <div style={{width:"50%",display:"flex",alignSelf:"flex-end",marginBottom:8,justifyContent:"flex-end"}}>
              {
                !item.isDiscontinued?null:
                <div className="AMPP_availability" > Discontinued </div>
              }
          </div>


        </div>
        
      </div>
    </div>
    :
    item.type === "sup"
    ?
    <div className="SUP_container">
      <div className="VMP_head1st">
      <div className="VMP_IdCell"><span>{item.type}</span> {item.id}</div>
      <div className="VMP_nameCell">{item.nm || item.name || item.desc}</div>
      </div>
    </div>
    :
    item.type === "scpack"
    ?
    <div className="boxes_container">
      <div className="VMP_head1st">
      <div className="VMP_IdCell scpack-ampp-style"><span>AMPP</span> {item.amppId}</div>
      <div className="VMP_nameCell">{item.nm || item.name || item.desc}</div>
      </div>
      <div className="AMP_3rdCell">
          
        <div className="AMPP_price_discArea">


          <div style={{width:"50%"}}>
              {
                item.gtins && item.gtins.length > 0
                ? <div className="AMP_codeLine"><span>GTIN</span> {item.gtins[0]}</div>
                : null
              }

             {
                !item.pipCode?null:
                <div className="AMP_codeLine"><span>PIP Code</span> {item.pipCode}</div>
              }

          </div>

        </div>
        
      </div>
    </div>
    :
    item.type === "sccompany"
    ?
    <div className="SUP_container">
      <div className="VMP_head1st">
      <div className="VMP_IdCell"><span>{item.type}</span> {item.id}</div>
      <div className="VMP_nameCell">{item.nm || item.name || item.desc}</div>
      </div>
    </div>
    :
    null
    }
  </Link></div></React.Fragment>)})}
</div>)};