import * as React from "react";
import { GlobalContext } from "../../../contexts/GlobalStore/GlobalStore";
import { CloseIcon } from "../../MobileMenuZone/CloseIcon";
import { FilterZone } from "../FilterZone";
import "./MobileFilterZone.scss";

const MobileFilterZone = () => {
  const { layoutData } = React.useContext(GlobalContext);

  return (
    <div
      className={
        "mobileZones Filter_Background" + (layoutData.isMobileFilterZoneActive ? " active" : "")
      }
    >
      <CloseIcon />
      <div className="mobileZoneInner">
        <FilterZone />
      </div>
    </div>
  );
};

export default MobileFilterZone;
