import * as React from "react";

interface TabProps {
  tabId: string;
  children: React.ReactNode;
}

const Tab: React.FC<TabProps> = ({ children }) => <>{children}</>;

export default Tab;
