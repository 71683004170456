import "./ExportFavoritesButton.scss";

import * as React from "react";

import { Button } from "../../../../components/Library/Button/Button";
import ErrorBoundary from "../../../../core/Guards/ErrorBoundary";
import FavouritesService from "../../../../core/services/favourites.service";
import { FavouriteType } from "../../types";
import { ModalStore } from "../../../../contexts/ModalStore/ModalStore";

interface Props {
  label: string;
  type: FavouriteType;
  className?: string;
  disabled?: boolean;
}

export const ExportFavoritesButton = (props: Props) => {
  const { label, className, type, disabled } = props;
  const [isLoading, setLoading] = React.useState(false);
  const { exportFavourites } = FavouritesService;
  const modalStore = React.useContext(ModalStore);

  const handleClick = async () => {
      setLoading(true);
      await exportFavourites(type);
      setLoading(false);
  }

  return (
    <ErrorBoundary>
      <Button
        disabled={disabled ?? isLoading}
        color={isLoading ? "white" : "blue"}
        className={`${className}`}
        onClick={async () => handleClick()}
        label={isLoading ? "Loading" : label}
      />
    </ErrorBoundary>
  );
};
