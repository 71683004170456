import * as React from "react";
import Button from "../Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import "./Modal.scss";

interface ModalProps {
  title: string;
  content: JSX.Element;
  onClose: Function;
  onConfirm?: Function;
  customButtons?: JSX.Element[];
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  onConfirm,
  content,
  title,
  customButtons,
}) => {
  return (
    <div className="modal">
      <div className="topBar">
        <h3>{title}</h3>
        <CloseIcon onClick={() => onClose()} />
      </div>
      <div className="content">{content}</div>
      {!customButtons ? (
        <div className="bottomBar">
          <Button onClick={() => onClose()} color={"transparent"} label="Cancel" />
          {onConfirm && <Button onClick={() => onConfirm()} color={"blue"} label="Continue" />}
        </div>
      ) : (
        <div className="customBottomBar">
          <Button
            className="cancelButton"
            onClick={() => onClose()}
            color={"transparent"}
            label="Cancel"
          />
          <div>{customButtons}</div>
        </div>
      )}
    </div>
  );
};

export default Modal;
