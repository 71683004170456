import * as msal from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import * as React from "react";

import { B2cPolicies, BaseLoginScopes } from "../configuration";

interface UserClaims {
  acr?: string;
}

async function handleLoginEvent(
  response: msal.AuthenticationResult,
  instance: msal.IPublicClientApplication,
): Promise<void> {
  const arcClaim = response.idTokenClaims as UserClaims;
  const hasForgotPasswordClaim = arcClaim === B2cPolicies.names.forgotPassword;

  if (hasForgotPasswordClaim) {
    await instance.logoutRedirect();
  }
}

async function handleFailure(
  error: msal.AuthError,
  instance: msal.IPublicClientApplication,
): Promise<void> {
  const adForgotPasswordErrorCode = "AADB2C90118";
  if (error.errorMessage.indexOf(adForgotPasswordErrorCode) > -1) {
    try {
      var loginRequest: msal.RedirectRequest = {
        scopes: BaseLoginScopes,
        authority: B2cPolicies.authorities.forgotPassword.authority,
      };

      await instance.loginRedirect(loginRequest);
    } catch (redirectError) {}
  }
}

export const AuthenticationEventHandler = () => {
  const { instance } = useMsal();

  React.useEffect(() => {
    const callbackId = instance.addEventCallback((message: msal.EventMessage) => {
      if (message.eventType === msal.EventType.LOGIN_SUCCESS) {
        const response = message.payload as msal.AuthenticationResult;

        if (!response) {
          return;
        }

        const account = response.account;

        instance.setActiveAccount(account);
        handleLoginEvent(response, instance);
      }

      if (message.eventType === msal.EventType.LOGIN_FAILURE) {
        const response = message.payload as msal.AuthError;

        if (!response) {
          return;
        }

        handleFailure(response, instance);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return null;
};
