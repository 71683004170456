import React, { useContext, useLayoutEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import "./SearchBoxWithoutSuggestion.scss"
import { GlobalContext } from '../../contexts/GlobalStore/GlobalStore';



const SearchBox = (props) => {

  const { history } = props;
  const { APIData,searchBoxStatus,setSearchBoxStatus } = useContext(GlobalContext);
  const inputZone = React.createRef();
  const val = props.location.search;
  const [scopeValue] = useState("");
  const searchBarVisible = false;
//-------------------------------------------------------------
// 		onACTION FUNCTIONS
//-------------------------------------------------------------
const onSubmit = (e) => {

  let submitValue = inputZone.current.value;
  if (inputZone.current.value !== ""){
    history.push({
      pathname: '/search',
      search: "?" + new URLSearchParams({ term:submitValue, scope: scopeValue }).toString()
    })

  }
  e.preventDefault();
}

const onMouseOver = () => {
  if (searchBoxStatus !== "hover" && searchBoxStatus !== "focus" && searchBoxStatus !== "onChange"){
    setSearchBoxStatus("");
  }
}
const onMouseOut = () => {
  if (searchBoxStatus !== "focus" && searchBoxStatus !== "onChange"){
    setSearchBoxStatus("default");
  }
}

const onCancel = () => {
  inputZone.current.value = APIData.headline;
  setSearchBoxStatus("default");
  inputZone.current.blur();
}

const onClear = (e) => {
  inputZone.current.value = "";
  inputZone.current.focus();
  e.preventDefault();
}

//-------------------------------------------------------------
// 		CHANGE HEADLINE if a NEW PAGE LOADED
//-------------------------------------------------------------
useLayoutEffect(() => {
    inputZone.current.value = APIData.headline;
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [APIData.value, APIData.headline]);



//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
<div className={"searchBox " + searchBoxStatus}>
<div className={"searchBoxContainer " + searchBoxStatus}>
  
  <input
    ref={inputZone}
    readOnly
    disabled
    spellCheck="false"
    autoCorrect="off"
    autoCapitalize="off"
    className={"searchBoxInput " + searchBoxStatus}
                type="text"
                defaultValue={ APIData.headline }
                onKeyUp={(event) => {
                    if(event.key === 'Enter'){
                        event.preventDefault();
                        onSubmit(event);
                    }
                    if(event.keyCode === 27) {
                      onCancel();
                    }

                }}
                // onChange={()=>{onChange()}}
                // onMouseOver={()=>{onMouseOver()}}
                // onMouseOut={()=>{onMouseOut()}}
                // onFocus={()=>{onFocus()}}
                // onBlur={()=>{onBlur()}}
                
                autoComplete="off"
    />



  {/* MAGNIFIER ICON or LEVEL TAG -> depending on APIData.level */}
  {searchBarVisible === true ? 
    <svg version="1.1"
        className = {APIData.level === "search"?"searchBoxMagnifier " + searchBoxStatus:"searchBoxMagnifier hide " + searchBoxStatus}
        xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 16 16"
        space="preserve">
        <path d="M10.4,2.1C8.3,1.6,6.2,2.9,5.7,5c-0.2,1-0.1,2.1,0.5,3c0.1,0.1,0.2,0.3,0.3,0.4l-3.7,4.3 c-0.3,0.3-0.2,0.8,0.1,1.1C3,13.9,3.1,14,3.3,14c0.2,0,0.4-0.1,0.6-0.3l3.8-4.4c0.3,0.2,0.6,0.3,1,0.4c0.3,0.1,0.6,0.1,0.9,0.1 c0.7,0,1.4-0.2,2.1-0.6c0.9-0.6,1.5-1.4,1.8-2.4C13.8,4.7,12.5,2.6,10.4,2.1z M11.9,6.5C11.6,7.8,10.3,8.6,9,8.3 C8.3,8.1,7.8,7.8,7.5,7.2C7.1,6.7,7,6,7.2,5.4c0.3-1.3,1.6-2.1,2.9-1.8C11.4,3.9,12.2,5.2,11.9,6.5z"/>
    </svg>
    :
      null
  }
  

  {
    (APIData.level === "search" && searchBarVisible === true) ? null :
    <div
      className={"searchBoxLevelTag " + searchBoxStatus}
    >{(val.indexOf("=sc") > 0 || props.location.pathname.indexOf("scpack")> 0 || props.location.pathname.indexOf("sccompany") > 0)?
      " emc supply chain" :
      " NHS dm+d"
    }
      </div>
  }

  
  {searchBarVisible === true ?
  <svg version="1.1"
      className = {"searchBoxClearButton " +searchBoxStatus}
      xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      space="preserve"
      onMouseDown = {(e)=>{ onClear(e); }}
      onMouseOver={()=>{ onMouseOver() }}
      onMouseOut={()=>{ onMouseOut() }}
      >
        <rect/>
        <path d="M13.1,13H8.1c-0.2,0-0.3-0.1-0.5-0.2L2.4,8.6C2.2,8.4,2.1,8.2,2.1,8c0-0.2,0.1-0.4,0.3-0.6l5.2-4.3 C7.7,3,7.9,3,8.1,3h5.1c0.4,0,0.8,0.3,0.8,0.8v8.6C13.9,12.7,13.6,13,13.1,13z M8.3,11.5h4.1V4.5H8.3L4,8L8.3,11.5z"/>
    </svg>
    :
    null
    }
    {searchBarVisible === true ?
  <svg version="1.1"
      className = {"searchBoxCancelButton " +searchBoxStatus}
      xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      space="preserve"
      onClick = {()=>{ onCancel() }}
      onMouseOver={()=>{ onMouseOver() }}
      onMouseOut={()=>{ onMouseOut() }}
      >
        <rect/>
        <path d="M12.6,11.5c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2L8,9.1l-3.5,3.5 c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1L6.9,8L3.4,4.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0L8,6.9 l3.5-3.5c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L9.1,8L12.6,11.5z"/>
    </svg>
    :
    null
    }

{searchBarVisible === true ?
  <svg version="1.1"
      className = {"searchBoxEnterButton " +searchBoxStatus}
      xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      space="preserve"
      onMouseDown = {(e)=>{ onSubmit(e) }}
      onMouseOver={()=>{onMouseOver()}}
      onMouseOut={()=>{onMouseOut()}}
      >
        <rect/>
        <path d="M14.6,3.2v6.7c0,0.6-0.4,1-1,1H4.8l1.4,1.3c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.5,0.3-0.7,0.3 c-0.2,0-0.5-0.1-0.7-0.3l-3.1-2.9c-0.4-0.4-0.4-1,0-1.4l2.9-3.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L4.8,8.8h7.9V3.2 c0-0.6,0.4-1,1-1S14.6,2.6,14.6,3.2z"/>
    </svg>
    :
    null
    }
</div></div>)};


export default withRouter(SearchBox);