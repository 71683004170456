import "./AddSearchToFavourites.scss";

import * as React from "react";

import { Button } from "../../../../components/Library/Button/Button";
import ErrorBoundary from "../../../../core/Guards/ErrorBoundary";
import { FavouriteType } from "../../types";
import useAddSearchToFavourites from "../../../../hooks/useAddSearchToFavourites";

interface Props {
  type: FavouriteType;
  label: string;
  className?: string;
  color: "blue" | "transparent" | "white";
}

export const AddSearchToFavourites: React.FC<Props> = (props) => {
  const { type, label, className, color } = props;
  const { isLoading, add } = useAddSearchToFavourites(type === "supplychainpack" ? "scpack" : type);

  return (
    <ErrorBoundary>
      <Button
        disabled={isLoading}
        color={isLoading ? "white" : color}
        className={`${className}`}
        onClick={async () => await add()}
        label={label}
      />
    </ErrorBoundary>
  );
};
