import "./MobileHamburger.scss";

import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { authentication } from "../../auth";
import { ClientContext } from "../../contexts/ClientStore/ClientStore";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";

export const MobileHamburger = (props) => {
  //-------------------------------------------------------------
  // 		VARIABLES
  //-------------------------------------------------------------
  const { backToResults, pageName, APIData, latestSearchValue, closeAllMobileZones } =
    useContext(GlobalContext);
  const { CS } = useContext(ClientContext);

  const user = authentication.getAccount();

  //-------------------------------------------------------------
  // 		RETURN
  //-------------------------------------------------------------
  if (CS.WINW >= 600) {
    return null;
  } else {
    return (
      <div className="mobileZoneInner">
        {pageName === "homePage" ? null : <Link to=""> BACK TO HOME </Link>}

        {APIData.level === "search" || latestSearchValue === null ? null : (
          <Link
            to=""
            onMouseDown={() => {
              backToResults();
            }}
          >
            LATEST SEARCH
          </Link>
        )}

        <Link to={{ pathname: "https://www.datapharm.com/products/emc-med-data/" }} target="_blank">
          ABOUT
        </Link>
        {/* OneTrust Cookies Settings button */}
        <a id="ot-sdk-btn-mobile" className="ot-sdk-show-settings" href="/">
          Cookie Settings
        </a>
        <a href="/legal" target="_blank" rel="noopener noreferrer">
          Legal
        </a>
        <Link to="http://meddatasupport.medicines.org.uk/support/home">HELP</Link>
        <div className="subCategory">
          <h1>FAVOURITES</h1>
          <Link to="/favourites/dmd" onClick={() => closeAllMobileZones()}>
            NHS dm+d
          </Link>
          <Link to="/favourites/emcsupplychain" onClick={() => closeAllMobileZones()}>
            emc supply chain
          </Link>
        </div>
        <Link to="https://www.datapharm.com/contact/ ">CONTACT</Link>

        {user ? (
          <>
            <Link to="/editprofile">
              User: <b>{user.name}</b>
            </Link>
            <Link to="/editprofile">Edit Profile</Link>
            <Link to="/signout">Log Out</Link>
          </>
        ) : (
          <Link to="/signin">Sign In</Link>
        )}
      </div>
    );
  }
};
