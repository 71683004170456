import "./ToggleFavouriteButton.scss";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import * as React from "react";

import { Button } from "../../../../components/Library/Button/Button";
import { ModalStore } from "../../../../contexts/ModalStore/ModalStore";
import { PopupStore } from "../../../../contexts/PopupStore/PopupStore";
import ErrorBoundary from "../../../../core/Guards/ErrorBoundary";
import FavouritesService from "../../../../core/services/favourites.service";
import { FavouriteElement, FavouriteType } from "../../types";

interface Props {
  element: FavouriteElement;
  label: string;
  className?: string;
}

export const ToggleFavouriteButton = (props: Props) => {
  const { element, label, className } = props;
  const {
    deleteFavouritePack,
    deleteFavouriteSupplier,
    saveFavouritePack,
    saveFavouriteSupplier,
    checkIfItemIsFavourite,
  } = FavouritesService;
  const [isLoading, setLoading] = React.useState(false);
  const [selectedAsFavourite, setAsFavourite] = React.useState(false);
  const [areFavouritesLoading, setFavouritesLoading] = React.useState(true);

  const modalStore = React.useContext(ModalStore);
  const popupStore = React.useContext(PopupStore);

  React.useEffect(() => {
    async function isFavourite(element: FavouriteElement) {
      setFavouritesLoading(true);
      const isFavourite = await checkIfItemIsFavourite(element);
      setFavouritesLoading(false);
      setAsFavourite(isFavourite);
    }
    isFavourite(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displaySuccess = (adding: boolean) => {
    popupStore.openPopup(
      "success",
      `Item was successfully ${adding ? "added" : "removed"} ${
        adding ? "to" : "from"
      } Favourites list!`,
    );
  };

  const displayError = (adding: boolean) => {
    popupStore.openPopup(
      "error",
      `Item was not successfully ${adding ? "added" : "removed"} ${
        adding ? "to" : "from"
      } Favourites list. Please try again.`,
    );
  };

  function success() {
    setAsFavourite((selectedAsFavourite) => !selectedAsFavourite);
    displaySuccess(!selectedAsFavourite);
    setLoading(false);
  }

  function saveFavourite(e: FavouriteElement) {
    switch (e.type) {
      case "ampp":
      case "supplychainpack":
        return openPackModal(() => {
          setLoading(true);
          saveFavouritePack(e.type, e.companyId, e.packId, e.amppId, e.packName).then(
            () => success(),
            (e) => popupStore.openPopup("error", e.detail),
          );
        }, true);
      case "sccompany":
      case "sup":
        return openPackModal(() => {
          setLoading(true);
          saveFavouriteSupplier(e.type, e.companyId).then(
            () => success(),
            (e) => popupStore.openPopup("error", e.detail),
          );
        }, true);
    }
  }

  function deleteFavourite(e: FavouriteElement) {
    switch (e.type) {
      case "ampp":
      case "supplychainpack":
        return openPackModal(() => {
          setLoading(true);
          deleteFavouritePack(e.type, e.companyId, e.packId, e.amppId).then(() => success());
        }, false);
      case "sccompany":
      case "sup":
        return openSupplierModal(e.type, e.companyId);
    }
  }

  function openPackModal(cb: Function, adding: boolean) {
    modalStore.openModal(
      `${adding ? "Add" : "Remove"} ${label} ${adding ? "to" : "from"} favourites`,
      <p>
        You are about to {adding ? "add" : "remove"} this {label} {adding ? "to" : "from"} your
        favourites list.
        <br />
        Are you sure?
      </p>,
      () => cb(),
    );
  }

  function openSupplierModal(type: Extract<"sup" | "sccompany", FavouriteType>, companyId: string) {
    modalStore.openModal(
      "Remove Supplier From Favourites",
      <p>
        Removing a favourite supplier will stop notifications of new packs. <br /> Do you also want
        to remove all favourite items from this supplier? <br /> Removing a favourite supplier may
        take a while.
      </p>,
      undefined,
      [
        <Button
          label="Remove Supplier Only"
          color="transparent"
          key={0}
          onClick={() => {
            setLoading(true);
            modalStore.closeModal();
            deleteFavouriteSupplier(type, companyId, false).then(() => success());
          }}
        />,
        <Button
          label="Remove Packs & Supplier"
          color="blue"
          key={1}
          onClick={() => {
            setLoading(true);
            modalStore.closeModal();
            deleteFavouriteSupplier(type, companyId, true).then(() => success());
          }}
        />,
      ],
    );
  }

  const toggleFavourite = async (element: FavouriteElement): Promise<void> => {
    try {
      selectedAsFavourite ? deleteFavourite(element) : saveFavourite(element);
    } catch (e) {
      displayError(selectedAsFavourite);
    }
  };

  const getLabel = (): string => {
    if (areFavouritesLoading) {
      return "Loading";
    }

    return selectedAsFavourite ? `Remove ${label} from Favourites` : `Add ${label} to Favourites`;
  };

  const getIcon = () => {
    if (areFavouritesLoading) {
      return;
    }
    return selectedAsFavourite ? <StarRoundedIcon /> : <StarBorderRoundedIcon />;
  };

  return (
    <div className="container">
      <ErrorBoundary>
        <Button
          disabled={areFavouritesLoading || isLoading}
          color={areFavouritesLoading || isLoading ? "white" : "blue"}
          className={`${className}`}
          onClick={async () => await toggleFavourite(element)}
          label={getLabel()}
          icon={getIcon()}
        />
      </ErrorBoundary>
    </div>
  );
};
