import "./SearchBoxHome.scss";

import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { authentication } from "../../auth";
import { ClientContext } from "../../contexts/ClientStore/ClientStore";
import SelectBox from "../SearchDropDown";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";

export const SearchBoxHome = (props) => {
  //-------------------------------------------------------------
  // 		VARIABLES
  //-------------------------------------------------------------
  const { CS } = useContext(ClientContext);
  const { setDatabase, setPageName } = useContext(GlobalContext);
  const inputZone = useRef();
  let history = useHistory();
  const permissions = authentication.getUserPermissions();
  const [scopeValue, setScope] = useState("");
  const [dropdownvisible, setVisibility] = useState(false);
  const [searchPlaceholder, setPlaceholder] = useState(
    "Select a data set and  enter a medicine or supplier name",
  );
  //-------------------------------------------------------------
  // 		onACTION FUNCTIONS
  //-------------------------------------------------------------
  const onSubmit = (e) => {
    let submitValue = inputZone.current.value;
    let actualScope = ensureCorrectDefaultScope();
    if (inputZone.current.value !== "") {
      setDatabase(actualScope);
      setPageName("searchResults"); //TODO: to be discarded after sidebar refactor
      history.push({
        pathname: "/search",
        search: "?" + new URLSearchParams({ term: submitValue, scope: actualScope }).toString(),
      });
    }
    e.preventDefault();
  };
  const setSelectedOption = (key) => {
    setScope(key);
  };
  const changePlaceHolder = () => {
    setVisibility(true);
    setPlaceholder("Search medicine or device name in ");
  };

  const ensureCorrectDefaultScope = () => {
    if (scopeValue === "") {
      // need to determine what the 'default' scope would be assume to be dmd unless the user only has SC
      return !permissions.dmd.granted && permissions.sc.granted ? "sc" : "dmd";
    }
    return scopeValue;
  };
  //-------------------------------------------------------------
  // 		RETURN
  //-------------------------------------------------------------
  return (
    <div className="searchBoxHome_container">
      <input
        ref={inputZone}
        spellCheck="false"
        autoCorrect="off"
        autoCapitalize="off"
        {...(CS.WINW > 600 && {
          placeholder: searchPlaceholder,
        })}
        className="searchBoxHome_input"
        autoComplete="off"
        onClick={() => changePlaceHolder()}
        onKeyUp={(event) => {
          changePlaceHolder();
          if (event.key === "Enter") {
            event.preventDefault();
            onSubmit(event);
          }
          if (event.keyCode === 27) {
            inputZone.current.blur();
          }
        }}
      />

      {permissions.dmd.granted && permissions.sc.granted && dropdownvisible ? (
        <div className="homepage-search-dropdown">
          <SelectBox
            className="drop-down"
            selectItem={setSelectedOption}
            items={[
              { value: "NHS dm+d", id: "dmd", class: "invisible" },
              { value: "emc supply chain", id: "sc", class: "" },
            ]}
          />
        </div>
      ) : null}
      <svg
        version="1.1"
        className="searchBoxHome_magnifier"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 16 16"
        space="preserve"
      >
        <path d="M10.4,2.1C8.3,1.6,6.2,2.9,5.7,5c-0.2,1-0.1,2.1,0.5,3c0.1,0.1,0.2,0.3,0.3,0.4l-3.7,4.3 c-0.3,0.3-0.2,0.8,0.1,1.1C3,13.9,3.1,14,3.3,14c0.2,0,0.4-0.1,0.6-0.3l3.8-4.4c0.3,0.2,0.6,0.3,1,0.4c0.3,0.1,0.6,0.1,0.9,0.1 c0.7,0,1.4-0.2,2.1-0.6c0.9-0.6,1.5-1.4,1.8-2.4C13.8,4.7,12.5,2.6,10.4,2.1z M11.9,6.5C11.6,7.8,10.3,8.6,9,8.3 C8.3,8.1,7.8,7.8,7.5,7.2C7.1,6.7,7,6,7.2,5.4c0.3-1.3,1.6-2.1,2.9-1.8C11.4,3.9,12.2,5.2,11.9,6.5z" />
      </svg>
      <svg
        version="1.1"
        className="searchBoxHome_enterButton"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 16 16"
        space="preserve"
        onMouseDown={(e) => {
          onSubmit(e);
        }}
      >
        <rect />
        <path d="M14.6,3.2v6.7c0,0.6-0.4,1-1,1H4.8l1.4,1.3c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.5,0.3-0.7,0.3 c-0.2,0-0.5-0.1-0.7-0.3l-3.1-2.9c-0.4-0.4-0.4-1,0-1.4l2.9-3.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L4.8,8.8h7.9V3.2 c0-0.6,0.4-1,1-1S14.6,2.6,14.6,3.2z" />
      </svg>
      <div
        onClick={(event) => {
          onSubmit(event);
        }}
        className="search-btn"
      >
        Search
      </div>
    </div>
  );
};
