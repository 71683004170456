import "./Sidebar.scss";

import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { authentication } from "../../auth";
import { ClientContext } from "../../contexts/ClientStore/ClientStore";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { Feature } from "../../features/features";
import { LogoEmblem } from "../LogoEmblem.js";
import { LogoType } from "../LogoType.js";
import { FilterZone } from "./FilterZone";
import { SidebarButton } from "./SidebarButton/SidebarButton";

export const Sidebar = (props) => {
  //-------------------------------------------------------------
  // 		VARIABLES
  //-------------------------------------------------------------
  const { layoutData, APIData, goToHome, openFilterZone, closeFilterZone } =
    useContext(GlobalContext);
  const { CS } = useContext(ClientContext);

  const user = authentication.getAccount();
  const history = useHistory();
  const onFavourites = useRouteMatch("/favourites/:database/:tab?");
  const onSearchResults = useRouteMatch("/search");
  //-------------------------------------------------------------
  // 		TEMPORARY ACTION. IT IS HANDLING UN-READY FUNCTIONS.
  //-------------------------------------------------------------

  //-------------------------------------------------------------
  // 		RETURN
  //-------------------------------------------------------------
  if (CS.WINW <= 600) {
    return null;
  }

  return (
    <div className={"sidebar" + (layoutData.isFilterMenuActive ? " lock" : "")}>
      {/* ADDITINAL BLOCKER BEHIND FILTER ZONE */}
      {layoutData.isFilterMenuActive ? (
        <div
          className="additionalFilterBehind"
          onClick={() => {
            closeFilterZone();
          }}
        ></div>
      ) : null}

      {/* BACKGROUND BIG EMBLEM & LEFT LINE */}
      <div className="leftLine" />

      {/* EMBLEM AND LOGOTYPE */}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          goToHome();
        }}
      >
        <LogoEmblem svgClass="LogoEmblem" />
        <LogoType svgClass="LogoType" />
      </div>

      {/* DEFAULT BUTTONS */}
      <SidebarButton
        labelText="Back To Home"
        icon={<HomeRoundedIcon />}
        onClick={() => {
          goToHome();
        }}
      />

      <Feature name="favouritePacksAndCompanies">
        <SidebarButton labelText="Favourites" icon={<StarRoundedIcon />}>
          <div className="sub-menu">
            {authentication.userHasDmdAccess() && (
              <div className="sub-menu-item" onClick={() => history.push("/favourites/dmd")}>
                NHS dm+d
              </div>
            )}
            {authentication.userHasSupplyChainAccess() && (
              <div
                className="sub-menu-item"
                onClick={() => history.push("/favourites/emcsupplychain")}
              >
                emc supply chain
              </div>
            )}
          </div>
        </SidebarButton>
      </Feature>

      {history.location.state?.from === "search" && (
        <SidebarButton
          labelText="Latest Search"
          icon={<ArrowBackIosNewRoundedIcon fontSize="large" />}
          onClick={() => history.goBack()}
        />
      )}

      {(APIData.level === "search" || onSearchResults) && !onFavourites ? (
        <SidebarButton
          labelText="Filter"
          icon={<FilterAltRoundedIcon />}
          onClick={() => {
            if (!layoutData.isFilterMenuActive) {
              openFilterZone();
            } else {
              closeFilterZone();
            }
          }}
          isLocked={layoutData.isFilterMenuActive ? true : false}
        />
      ) : null}

      {user ? (
        <SidebarButton
          labelText="Log Out"
          icon={<PersonRoundedIcon />}
          onClick={() => {
            authentication.signOut();
          }}
        />
      ) : null}

      {/* FILTER ZONE */}
      <div className={"filterZone" + (layoutData.isFilterMenuActive ? " active" : "")}>
        {layoutData.isFilterMenuActive ? <FilterZone /> : null}
      </div>
    </div>
  );
};
