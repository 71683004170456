import { FeatureFlags } from "../../features/features/types";
import { ErrorService } from "./error.service";
import { http } from "./http.service";

const FeatureFlagsService = {
  getFeatureFlags: async (): Promise<FeatureFlags> => {
    const getUrl = `${process.env.REACT_APP_API_PATH}/features/management`;
    return await http.get<FeatureFlags>(getUrl).catch((error) => {
      ErrorService.create().log(error);
      return {
        loading: false,
        changeNotification: false,
        favouritePacksAndCompanies: false,
        exportPackData: false,
      };
    });
  },
};

export default FeatureFlagsService;
