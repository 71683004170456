import * as React from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalStore/GlobalStore";

import { ModalStore } from "../contexts/ModalStore/ModalStore";
import { fetchAPI } from "../pages/APIConnections";
import { SearchType } from "../pages/NewSearchResultsPage/SearchResultsTypes";
import useSearchQuery from "./useSearchQuery";

export default function useExportSearchResults(type: SearchType) {
  const modalStore = React.useContext(ModalStore);
  const { setIsLoading, APIData } = React.useContext(GlobalContext);
  const [q, db] = useSearchQuery();
  const history = useHistory();

  const getIDsAsArray = (ARRAY: { id: string; name: string }[]) => {
    let convertedIDs = [];
    for (var i in ARRAY) {
      convertedIDs.push(ARRAY[i].id);
    }
    return convertedIDs;
  };

  async function initExport(type: SearchType) {
    const file = await fetchAPI(
      history,
      {
        apiType: type === "scpack" ? "scpackSearchExport" : "amppSearchExport",
        value: q,
        scope: db,
        showDis: APIData.showDiscValue,
        ingList: getIDsAsArray(APIData.selectedIngValues),
        strList: getIDsAsArray(APIData.selectedStrengthValues),
        routeList: getIDsAsArray(APIData.selectedRoutesValues),
        formList: getIDsAsArray(APIData.selectedFormValues),
        suppList: getIDsAsArray(APIData.selectedSupplierValues),
      },
      setIsLoading,
    );

    const link = document.createElement("a");

    link.href = (file as any).url;
    link.download = `Export All ${+new Date()}.xlsx`;
    link.click();
  }
  
  return { initExport };
}
