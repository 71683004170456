import "./Footer.scss";

import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { authentication } from "../../auth";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { Feature } from "../../features/features";
import Logo from "../../img/dataPharmLogo.png";
import { FooterButton } from "./FooterButton/FooterButton";

// import { ClientContext } from '../../contexts/ClientStore';
export const Footer = (props) => {
  //-------------------------------------------------------------
  // 		VARIABLES
  //-------------------------------------------------------------
  const { pageName, isLoading, setPageName } = useContext(GlobalContext);
  // const { CS } = useContext(ClientContext);

  // const footerYear = new Date().getFullYear();

  const user = authentication.getAccount();
  const history = useHistory();

  //-------------------------------------------------------------
  // 		RETURN
  //-------------------------------------------------------------

  if (pageName === "errorPage") {
    return null;
  }

  function navigateToFavourites(url) {
    setPageName("favourites");
    history.push(url);
  }

  return (
    <div
      className={
        "footer_main" +
        (pageName === "homePage" ? " FMhomeView" : "") +
        (isLoading ? " FMloadingView" : "")
      }
    >
      {/*!!! IMPORTANT, MOBILE HAS A DIFFERENT COMPONENT FOR FOOTER LINKS. DONT FORGET TO CHANGE THERE. */}
      <div className="footer_linkarea_left">
        <Feature name="favouritePacksAndCompanies">
          <FooterButton label="Favourites">
            <div className="sub-menu">
              {authentication.userHasDmdAccess() && (
                <div
                  className="sub-menu-item"
                  onClick={() => navigateToFavourites("/favourites/dmd")}
                >
                  NHS dm+d
                </div>
              )}
              {authentication.userHasSupplyChainAccess() && (
                <div
                  className="sub-menu-item"
                  onClick={() => navigateToFavourites("/favourites/emcsupplychain")}
                >
                  emc supply chain
                </div>
              )}
            </div>
          </FooterButton>
        </Feature>
        <a
          href="https://www.datapharm.com/products/emc-med-data/"
          target="_blank"
          rel="noopener noreferrer"
        >
          About
        </a>
        {/* OneTrust Cookies Settings button */}
        <span id="ot-sdk-btn-left" className="ot-sdk-show-settings">
          Cookie Settings
        </span>
        <a href="/legal" target="_blank" rel="noopener noreferrer">
          Legal
        </a>
        <a href="https://support.datapharm.com/knowledge" target="_blank" rel="noopener noreferrer">
          Help
        </a>
        <a href="https://www.datapharm.com/contact/ " target="_blank" rel="noopener noreferrer">
          Contact
        </a>
        {user ? (
          <>
            &nbsp;|&nbsp;
            <i>
              Signed in as <b>{user.name}</b>
            </i>
            &nbsp;|&nbsp;
            <Link to="/editprofile">Edit Profile</Link>
            <Link to="/signout">Log Out</Link>
          </>
        ) : (
          <Link to="/signin">Sign In</Link>
        )}
      </div>

      <div className="footer_linkarea_right">
        <span>Delivered to you by</span>
        <a href="https://www.datapharm.com" target="_blank" rel="noopener noreferrer">
          <img src={Logo} alt="Delivered to you by Datapharm" className="footer_linkarea_logo" />
        </a>
      </div>
    </div>
  );
};
