import classNames from "classnames";
import * as React from "react";
import Popup from "../../components/Library/Popup/Popup";
import "./PopupStore.scss";

interface PopupStoreInterface {
  openPopup: (type: "success" | "error", message: string) => void;
  closePopup: () => void;
}

export const PopupStore = React.createContext<PopupStoreInterface>({} as PopupStoreInterface);

export const PopupStoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false);
  const [type, setType] = React.useState<"success" | "error">("success");
  const [message, setMessage] = React.useState<string>("Default mesasage");

  function openPopup(type: "success" | "error", message: string) {
    setType(type);
    setMessage(message);
    setOpen(true);
    setTimeout(() => closePopup(), 3000);
  }

  function closePopup() {
    setOpen(false);
  }

  return (
    <PopupStore.Provider value={{ openPopup, closePopup }}>
      {isOpen && (
        <div className={classNames("popupContainer", { open: isOpen })}>
          <Popup type={type} message={message} onClose={() => closePopup()} />
        </div>
      )}
      {children}
    </PopupStore.Provider>
  );
};
