import {
  FavoritesDetailsResult,
  FavouriteElement,
  FavouriteType,
  SearchProps,
} from "../../features/favourites/types";
import { SearchType } from "../../pages/NewSearchResultsPage/SearchResultsTypes";
import { http } from "./http.service";

const FavouriteService = {
  addSearchedFavourites: async (
    type: SearchType | "supplychainpack",
    search: SearchProps,
  ): Promise<void> => {
    if (["ampp", "sup", "supplychainpack", "sccompany"].indexOf(type) === -1) {
      return;
    }
    const postUrl = `${process.env.REACT_APP_API_PATH}/${type}/search/favourites`;

    return await http.post(postUrl, search);
  },

  checkIfItemIsFavourite: async (element: FavouriteElement): Promise<boolean> => {
    function checkIfPackIsFavourite(packId: string) {
      const getUrl = `${process.env.REACT_APP_API_PATH}/profile/favourites/packs/${packId}`;

      return http.get<boolean>(getUrl);
    }
    function checkIfSupplierIsFavourite(companyId: string) {
      const getUrl = `${process.env.REACT_APP_API_PATH}/profile/favourites/companies/${companyId}`;

      return http.get<boolean>(getUrl);
    }

    switch (element.type) {
      case "ampp":
      case "supplychainpack":
        return checkIfPackIsFavourite(element.packId);
      case "sccompany":
      case "sup":
        return checkIfSupplierIsFavourite(element.companyId);
    }
  },

  loadFavouritesDetails: async (
    top: number,
    offset: number,
    type?: FavouriteType,
  ): Promise<FavoritesDetailsResult> => {
    const getUrl = `${process.env.REACT_APP_API_PATH}/profile/favourites/details`;

    return http.post<{}, FavoritesDetailsResult>(getUrl, { top, offset, type });
  },

  saveFavouritePack: async (
    type: Extract<"ampp" | "supplychainpack", FavouriteType>,
    companyId: string,
    packId: string,
    amppId: string,
    packName: string,
  ): Promise<void> => {
    const postUrl = `${process.env.REACT_APP_API_PATH}/${type}/favourite`;
    const data = { packId, amppId, companyId, packName };
    return http.post(postUrl, data);
  },

  saveFavouriteSupplier: async (
    type: Extract<"sup" | "sccompany", FavouriteType>,
    companyId: string,
  ): Promise<void> => {
    const postUrl = `${process.env.REACT_APP_API_PATH}/${type}/${companyId}/favourite`;
    return http.post(postUrl);
  },

  deleteFavouritePack: async (
    type: Extract<"ampp" | "supplychainpack", FavouriteType>,
    companyId: string,
    packId: string,
    amppId: string,
  ): Promise<void> => {
    const postUrl = `${process.env.REACT_APP_API_PATH}/${type}/favourite`;
    const data = { packId, amppId, companyId };
    return http.delete(postUrl, data);
  },

  deleteFavouriteSupplier: async (
    type: Extract<"sup" | "sccompany", FavouriteType>,
    companyId: string,
    removeCompanyFavoritePacks: boolean,
  ): Promise<void> => {
    const postUrl = `${process.env.REACT_APP_API_PATH}/${type}/${companyId}/favourite?removeCompanyFavoritePacks=${removeCompanyFavoritePacks}`;
    return http.delete(postUrl);
  },

  exportFavourites: async (type: FavouriteType): Promise<void> => {
    const exportUrl = `${process.env.REACT_APP_API_PATH}/${type}/favourites/export`;

    const data = await http.getBlob(exportUrl);
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = `Export All ${+new Date()}.xlsx`;
    link.click();
  },
};

export default FavouriteService;
