import React from 'react';



export const FilterCollapseIcon = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { type } = props;



//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className ="filterSections_PlusSVG"
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    space="preserve">

    {type==="+"?
    
    <React.Fragment>
      <line id="Line_309_1_" style={{fill:"none",stroke:"#FFFFFF",strokeWidth:1.5,strokeLinecap:"round",strokeLinejoin:"round"}} x1="3.5" y1="15" x2="26.5" y2="15"/>
      <line id="Line_310_1_" style={{fill:"none",stroke:"#FFFFFF",strokeWidth:1.5,strokeLinecap:"round",strokeLinejoin:"round"}} x1="14.5" y1="4" x2="14.5" y2="26"/>
    </React.Fragment>
    
    :

    <line id="Line_309_1_" style={{fill:"none",stroke:"#FFFFFF",strokeWidth:1.5,strokeLinecap:"round",strokeLinejoin:"round"}} x1="3.5" y1="15" x2="26.5" y2="15"/>


    }


      


  </svg>
)};