import * as React from "react";
import { Button, Radio, Checkbox } from "../../../components/Library";
import { useParams } from "react-router-dom";
import useFavService, { NotificationSettings } from "../../../core/services/favSettings.service";
import "./FavouritesSettingsTab.scss";

const FavouritesSettingsTab: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState<NotificationSettings["frequency"]>("Weekly");
  const { database } = useParams<{ database: string; tab: string }>();
  const finalDatabase = database === "dmd" ? "Dmd" : "SupplyChain";
  const service = useFavService();

  React.useEffect(() => {
    setCheckboxValue(false);
    setRadioValue("Weekly");
    loadDefaultSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [database]);

  async function loadDefaultSettings() {
    const data = await service.loadFavSettings();
    setLoading(false);
    const settings = data.preferences.find((e) => e.application === finalDatabase);
    if (settings) {
      setCheckboxValue(true);
      setRadioValue(settings.frequency);
    }
  }

  function onSubmit(e: React.MouseEvent) {
    e.preventDefault();
    const payload: NotificationSettings = {
      frequency: radioValue,
      application: finalDatabase,
    };
    checkboxValue ? service.saveFavSettings(payload) : service.deleteFavSettings(finalDatabase);
  }

  return (
    <form className="settingsTab">
      {loading ? (
        <h1 className="loader">Loading...</h1>
      ) : (
        <>
          <h2>Notifications</h2>
          <Checkbox
            label="I would like to receive updates when new information related to my favourites is published."
            value={checkboxValue}
            onChange={setCheckboxValue}
          />
          <div className="radioContainer">
            <Radio
              label="Daily"
              value={radioValue === "Daily"}
              onChange={() => setRadioValue("Daily")}
            />
            <Radio
              label="Weekly"
              value={radioValue === "Weekly"}
              onChange={() => setRadioValue("Weekly")}
            />
            <Radio
              label="Monthly"
              value={radioValue === "Monthly"}
              onChange={() => setRadioValue("Monthly")}
            />
          </div>
          <div className="flex">
            <Button color="transparent" label="Cancel" onClick={() => loadDefaultSettings()} />
            <Button color="blue" label="Save" onClick={(e) => onSubmit(e)} />
          </div>
        </>
      )}
    </form>
  );
};

export default FavouritesSettingsTab;
