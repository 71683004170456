import * as React from "react";

import { Button } from "../../../components/Library";
import { ModalStore } from "../../../contexts/ModalStore/ModalStore";
import { PopupStore } from "../../../contexts/PopupStore/PopupStore";
import FavouriteService from "../../../core/services/favourites.service";
import { FavoritesDetailsResult, Favourite, FavouriteElement, FavouriteType } from "../types";

function useFavourites() {
  const [favourites, setFavourites] = React.useState<Array<Favourite>>([]);
  const [areFavouritesLoading, setFavouritesLoading] = React.useState(true);
  const { loadFavouritesDetails, deleteFavouriteSupplier, deleteFavouritePack } = FavouriteService;
  const [counters, setCounters] = React.useState<Omit<FavoritesDetailsResult, "results">>({
    totalAmpps: 0,
    totalSupplyChainPacks: 0,
    totalSupplyChainCompanies: 0,
    totalSupps: 0,
  });
  const modalStore = React.useContext(ModalStore);
  const popupStore = React.useContext(PopupStore);
  const itemsInSlice = 24;

  function isMoreOfType(type: FavouriteType) {
    switch (type) {
      case "ampp":
        return counters.totalAmpps > favourites.length;
      case "supplychainpack":
        return counters.totalSupplyChainPacks > favourites.length;
      case "sup":
        return counters.totalSupps > favourites.length;
      case "sccompany":
        return counters.totalSupplyChainCompanies > favourites.length;
    }
  }

  async function loadFavourites(type: FavouriteType, init?: boolean) {
    setFavouritesLoading(true);
    const response = await loadFavouritesDetails(itemsInSlice, init ? 0 : favourites.length, type);
    setFavourites(init ? [...response.results] : [...favourites, ...response.results]);
    setCounters({
      totalAmpps: response.totalAmpps,
      totalSupplyChainPacks: response.totalSupplyChainPacks,
      totalSupplyChainCompanies: response.totalSupplyChainCompanies,
      totalSupps: response.totalSupps,
    });

    setFavouritesLoading(false);
  }

  function createElement(f: Favourite): FavouriteElement {
    switch (f.type) {
      case "ampp":
        return { type: f.type, companyId: f.supId, packId: f.id, amppId: f.id, packName: f.nm };
      case "supplychainpack":
        return {
          type: f.type,
          companyId: f.companyId,
          packId: f.id,
          amppId: f.amppId,
          packName: f.name,
        };
      case "sup":
      case "sccompany":
        return { type: f.type, companyId: f.id };
    }
  }

  function removeFavourite(index: number, favourite: Favourite) {
    const e = createElement(favourite);
    function decrement() {
      const newArray = [...favourites];
      newArray.splice(index, 1);
      setFavourites(newArray);
      decrementCounter(favourite.type);
    }

    switch (e.type) {
      case "ampp":
      case "supplychainpack":
        return openPackModal(e.type, e.companyId, e.packId, e.amppId, decrement);
      case "sccompany":
      case "sup":
        return openSupplierModal(e.type, e.companyId, decrement);
    }
  }

  function openPackModal(
    type: Extract<"ampp" | "supplychainpack", FavouriteType>,
    companyId: string,
    packId: string,
    amppId: string,
    decrement: Function,
  ) {
    modalStore.openModal(
      `Remove from favourites`,
      <p>You are about to remove this item from favourites. Are you sure?</p>,
      () => {
        decrement();
        deleteFavouritePack(type, companyId, packId, amppId).then(
          () => {
            popupStore.openPopup("success", `Item deleted`);
          },
          () => {
            popupStore.openPopup("error", `Item was not deleted - refresh page`);
          },
        );
        modalStore.closeModal();
      },
    );
  }

  function openSupplierModal(
    type: Extract<"sup" | "sccompany", FavouriteType>,
    companyId: string,
    decrement: Function,
  ) {
    modalStore.openModal(
      "Remove Supplier From Favourites",
      <p>
        Removing a favourite supplier will stop new pack notifications and may take a while. <br />
        Do you also want to remove all favourite packs from this supplier?
      </p>,
      undefined,
      [
        <Button
          label="Remove Supplier Only"
          color="transparent"
          key={0}
          onClick={() => {
            decrement();
            modalStore.closeModal();
            deleteFavouriteSupplier(type, companyId, false).then(() =>
              popupStore.openPopup("success", `Item deleted`),
            );
          }}
        />,
        <Button
          label="Remove Packs & Supplier"
          color="blue"
          key={1}
          onClick={() => {
            decrement();
            modalStore.closeModal();
            deleteFavouriteSupplier(type, companyId, true).then(() =>
              popupStore.openPopup("success", `Item deleted`),
            );
          }}
        />,
      ],
    );
  }

  function decrementCounter(type: FavouriteType) {
    switch (type) {
      case "ampp":
        setCounters({ ...counters, totalAmpps: counters.totalAmpps - 1 });
        return;
      case "supplychainpack":
        setCounters({ ...counters, totalSupplyChainPacks: counters.totalSupplyChainPacks - 1 });
        return;
      case "sccompany":
        setCounters({
          ...counters,
          totalSupplyChainCompanies: counters.totalSupplyChainCompanies - 1,
        });
        return;
      case "sup":
        setCounters({ ...counters, totalSupps: counters.totalSupps - 1 });
        return;
    }
  }

  function clearFavourites() {
    setFavourites([]);
  }

  return [
    favourites,
    loadFavourites,
    removeFavourite,
    clearFavourites,
    areFavouritesLoading,
    counters,
    isMoreOfType,
  ] as const;
}

export default useFavourites;
