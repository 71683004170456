import React, {useState,useLayoutEffect,useRef} from 'react';
import "./RenderBoxes.scss";
import {RenderDetailsAcc} from "./RenderDetailsAcc";

export const RenderDetails = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { content, level } = props;//const { content, level,calculateDetailsHeight } = props;
const [isThereJustOneSection,setIsThereJustOneSection]=useState(false);
const [isAllCollapsed,setIsAllCollapsed]=useState("complex"); // allExtended - allCollapsed - complex
const accNoExtCollapsedSituations = useRef([]);
const accNoExtCollapsedHeights = useRef([]);

//-------------------------------------------------------------
// 		CHECK SECTION COUNT and IF THERE s ONLY 1, CLOSE ACCORDION FUNCTION and HIDE +/- ICONS AUTOMATICLY.
//-------------------------------------------------------------
useLayoutEffect(() => {
    if(content.length === 1){
      setIsThereJustOneSection(true);
    }
}, [content]);

//-------------------------------------------------------------
// 		COLLAPSE ALL EXTEND ALL FUNCTIONS
//-------------------------------------------------------------
const changeAllSectionToCollapse = (newStatus) => {

  if (newStatus !== isAllCollapsed){
    setIsAllCollapsed(newStatus);
  }

}

//-------------------------------------------------------------
// 		GET ALL ACCs SITUATION TO DECIDE COLLAPSE ALL or EXTEND ALL
//-------------------------------------------------------------

useLayoutEffect(() => {

  for (var i in content) {

    // create accNoExtCollapsedSituations
    if (i === 0){
      accNoExtCollapsedSituations.current["acc"+0] = true;
    } else {
      accNoExtCollapsedSituations.current["acc"+i] = false;
    }
                
  }


},[content]);


const getACCSituations = (ACCNo,BOO,WH) => {


  accNoExtCollapsedHeights.current["acc"+ACCNo] = WH;
  // console.log(accNoExtCollapsedHeights.current);

  if (accNoExtCollapsedSituations.current["acc"+ACCNo] === BOO){ return }

  // Section positions and height changed. Recalculate sticky positions at the parent
  // Send a notification to the parent for recalculation.
  //if( level !== "ampp"){
  //  calculateDetailsHeight();
  //}
  
  // Handle new positions
  accNoExtCollapsedSituations.current["acc"+ACCNo] = BOO;
  

  let accNoExtCollapsedSituationsKeys = Object.keys(accNoExtCollapsedSituations.current);
  
  if (content.length === accNoExtCollapsedSituationsKeys.length){

      let currentCSituation_true = 0;
      let currentCSituation_false = 0;
      
              for (var i in accNoExtCollapsedSituationsKeys) {
                if(accNoExtCollapsedSituations.current[accNoExtCollapsedSituationsKeys[i]] === true){
                  currentCSituation_true++;
                } else {
                  currentCSituation_false++;
                }
              }

      if (currentCSituation_true === 0){ 
        setIsAllCollapsed("allCollapsed");
      } else if (currentCSituation_false === 0){
        setIsAllCollapsed("allExtended");
      } else {
        setIsAllCollapsed("complex");
      }

  }

}

//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <div className="renderDetails_main">
  {/* Seperate sections then call RenderDetailACC */}
  {!content?null:content.map((item, index) => {

    // hide all section if its empty
    if(
      (item.label === "Combination Pack Content" && item.combPacks.length === 0)
      ||
      (item.items && item.items.length === 0)
      ||
      (item[item.subTag] && item[item.subTag].length === 0)
      ){
        // return null; // EMPTY CONTROLLER -- OPEN THIS
      }   
		return (
      
      <RenderDetailsAcc
        key={index}
        data={item}
        level={level}
        ACCNo = {index}
        isAllCollapsed = {isAllCollapsed }
        isThereJustOneSection = { isThereJustOneSection }
        changeAllSectionToCollapse = { changeAllSectionToCollapse }
        getACCSituations = { getACCSituations }
        accNoExtCollapsedHeights = {accNoExtCollapsedHeights}
      />
    )})}
</div>
)};