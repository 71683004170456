import React, { useLayoutEffect, useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import "./ErrorPage.scss";

import { LogoFull } from "../../components/LogoFull.js";
import { Button } from "../../components/FormElements/Buttons/Button.js";
import { PageContainer } from "../../components";

export const ErrorPage = (props) => {
  const thisPageName = "errorPage";
  const { type } = props;
  const { goToHome, pageName, setPageName } = useContext(GlobalContext);

  useLayoutEffect(() => {
    if (thisPageName !== pageName) {
      setPageName(thisPageName);
    }
  }, [thisPageName, pageName, setPageName]);

  return (
    <PageContainer>
      <div className="errorPages">
        <LogoFull svgClass="errorpage_LogoFull" />
        <div className="errorpage_container">
          <div>Sorry to say, but...</div>
          <div>
            <b>
              {type === 403
                ? "You do not have access to the page you have requested."
                : type === 404
                ? "We couldn't find the page that you're looking for."
                : type === 500
                ? "Something just isn't right on server-side and it is unexpected."
                : "unknown error!!!"}
            </b>
          </div>
          <div>
            <br />
            Please try again or contact your admin. (error code: {type})
          </div>
          <div className="errorpage_buttonArea">
            <Button
              buttonText="HOME"
              type="grey"
              level="default"
              onClick={() => {
                goToHome();
              }}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default ErrorPage;
