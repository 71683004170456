import React from 'react';
import "./Buttons.scss";







export const Button = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { buttonText, type, level, onClick } = props;
// const [currentValue,setCurrentValue]=useState("");



//-------------------------------------------------------------
// 		FUNCTIONS
//-------------------------------------------------------------



//-------------------------------------------------------------
// 		LIFE CYCLE
//-------------------------------------------------------------




//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
<div
  className={ "Button "+ type +" "+ level }
  onClick = {()=>{ onClick() }}
  >
  
  { buttonText }

</div>)};