import "./SearchResultsTab.scss";

import * as React from "react";
import { useInfiniteQuery } from "react-query";

import Tile from "../../../components/Tile/Tile";
import { Filters } from "../../../contexts/FilterStore/FilterStore";
import SearchService from "../../../core/services/search.service";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { Database, SearchType } from "../SearchResultsTypes";

interface SearchResultsTabProps {
  type: SearchType;
  total: number;
  filters: Filters;
  q: string;
  db: Database;
}

const SearchResultsTab: React.FC<SearchResultsTabProps> = ({ type, total, filters, q, db }) => {
  const pageSize = 48;
  const triggerPosition = 26;
  const { getSearchResults, getSearchQuery, generateTileData } = SearchService;

  const getResults = ({ pageParam = 0 }) => {
    return getSearchResults<typeof type>(getSearchQuery(q, pageSize, pageParam, type, filters, db));
  };

  const { data, fetchNextPage, status } = useInfiniteQuery(
    [type, q, ...Object.values(filters)],
    getResults,
    {
      getNextPageParam: (lastPage, pages) => pages.length * pageSize,
    },
  );

  const { shouldUpdateReference } = useInfiniteScroll(fetchNextPage, data);

  return (
    <div className="searchResults">
      {status === "success" &&
        data.pages.map((page, p) => {
          const fromCount = p * pageSize;
          const toCount = (p + 1) * pageSize <= total ? (p + 1) * pageSize : total;

          return (
            <div key={p}>
              {p > 0 && fromCount <= toCount && (
                <i>{`Items ${fromCount} to  ${toCount} of ${total}`}</i>
              )}
              <div className="searchResultsTab gridContainer">
                {total > 0 &&
                  page.map((tile, t) => (
                    <Tile
                      ref={(node) => shouldUpdateReference(node, p, t, triggerPosition)}
                      key={p + t}
                      tileData={generateTileData(tile)}
                      labelColor={type === "scpack" ? "orange" : "blue"}
                      from="search"
                    />
                  ))}
                {total === 0 && <h2>There are no search results of this type</h2>}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SearchResultsTab;
