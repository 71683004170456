import * as React from "react";

interface TabContainerProps {
  children: React.ReactElement[];
  activeTab: string;
}

const TabContainer: React.FC<TabContainerProps> = ({ activeTab, children }) => {
  return (
    <div className="tabContainer">
      {children.map((child, i) => child.props.tabId === activeTab && <div key={i}>{child}</div>)}
    </div>
  );
};

export default TabContainer;
