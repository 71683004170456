import React from 'react';



export const AccCollapseIcon = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const { type } = props;



//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------

return (
  <svg version="1.1"
    className ="renderDetails_AccCollapseIcon"
    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 26"
    space="preserve">

    <path
        style={{ fill:"#2DA7DB" }}
        d="M25.3,26H7.5c-0.4,0-0.7-0.3-0.7-0.7V7.5c0-0.4,0.3-0.7,0.7-0.7h17.7c0.4,0,0.7,0.3,0.7,0.7v17.7C26,25.7,25.7,26,25.3,26z
        M8.3,24.5h16.3V8.3H8.3V24.5z"/>

    {type==="+"?

    <path
        style={{ fill:"#2DA7DB" }}
        d="M21.6,16.4c0,0.4-0.3,0.7-0.7,0.7h-3.7v3.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-3.7H12c-0.4,0-0.7-0.3-0.7-0.7
        c0-0.4,0.3-0.7,0.7-0.7h3.7V12c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7v3.7h3.7C21.3,15.7,21.6,16,21.6,16.4z"
      />
  
    
    :

    <path
        style={{ fill:"#2DA7DB" }}
        d="M20.8,17.1h-8.9c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h8.9c0.4,0,0.7,0.3,0.7,0.7S21.3,17.1,20.8,17.1z"
      />


    }


      


  </svg>
)};