interface IErrorService {
  log(error: Error): void;
  log(error: Error, info?: string): void;
  log(error: Error, info: string): void;
}

export class ErrorService implements IErrorService {
  log(error: Error): void;
  log(error: Error, info: string): void;
  log(error: Error, info?: string): void {
    console.log(error.message, info);
  }

  static create(): ErrorService {
    return new ErrorService();
  }
}
