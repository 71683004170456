import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal
} from "@azure/msal-react";
import * as React from "react";
import { Redirect } from "react-router-dom";

import { GlobalContext } from "../contexts/GlobalStore/GlobalStore";
import { authentication } from "./authentication.service";
import { LoginScopes } from "./configuration";

const SignIn = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { setIsLoading } = React.useContext(GlobalContext);

  React.useEffect(() => {
    setIsLoading(true);
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      authentication.signIn().finally(() => setIsLoading(false));
    }
  }, [setIsLoading, isAuthenticated, inProgress]);

  return null;
};

const SignUp = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { setIsLoading } = React.useContext(GlobalContext);

  React.useEffect(() => {
    setIsLoading(true);

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      authentication.signUp().finally(() => setIsLoading(false));
    }
  }, [inProgress, isAuthenticated, setIsLoading]);

  return null;
};

const SignOut = () => {
  const { setIsLoading } = React.useContext(GlobalContext);
  React.useEffect(() => {
    setIsLoading(true);
    authentication.signOut().finally(() => setIsLoading(false));
  }, [setIsLoading]);

  return null;
};

const EditProfile = () => {
  const { inProgress } = useMsal();
  const { setIsLoading } = React.useContext(GlobalContext);
  React.useEffect(() => {
    setIsLoading(true);
    if (inProgress === InteractionStatus.None) {
      authentication.editProfile().finally(() => setIsLoading(false));
    }
  }, [setIsLoading, inProgress]);

  return null;
};

const NewToken = () => {
  const { inProgress } = useMsal();
  const { setIsLoading } = React.useContext(GlobalContext);
  React.useEffect(() => {
    setIsLoading(true);
    if (inProgress === InteractionStatus.None) {
      authentication.newToken().finally(() => setIsLoading(false));
    }
  }, [setIsLoading, inProgress]);

  return null;
};

const ForgotPassword = () => {
  const { inProgress } = useMsal();
  const { setIsLoading } = React.useContext(GlobalContext);
  React.useEffect(() => {
    setIsLoading(true);
    if (inProgress === InteractionStatus.None) {
      authentication.forgotPassword().finally(() => setIsLoading(false));
    }
  }, [setIsLoading, inProgress]);

  return null;
};

const ExistingUserRegister = () => {
  const { inProgress } = useMsal();
  const { setIsLoading } = React.useContext(GlobalContext);
  React.useEffect(() => {
    setIsLoading(true);
    if (inProgress === InteractionStatus.None) {
      authentication.existingUserRegister().finally(() => setIsLoading(false));
    }
  }, [setIsLoading, inProgress]);

  return null;
};

interface ProtectedProps {
  children: React.ReactElement[];
}

const Protected = ({ children }: ProtectedProps) => {
  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

const Loading = () => {
  const { setIsLoading } = React.useContext(GlobalContext);
  React.useEffect(() => {
    setIsLoading(true);
    return () => setIsLoading(false);
  }, [setIsLoading]);
  return null;
};

const Error = ({ error }: MsalAuthenticationResult) => {
  const userCancelledEnteringSelfAssertion = "AADB2C90091";

  if (error?.errorMessage && error?.errorMessage.indexOf(userCancelledEnteringSelfAssertion) > -1) {
    return <Redirect to="/signin" />;
  }

  return <span>Something went wrong. Please refresh page or try again after some time.</span>;
};

const withAuth =
  (Component: any) =>
  ({ ...props }) => {
    const request = {
      scopes: LoginScopes,
    };

    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={request}
        loadingComponent={Loading}
        errorComponent={Error}
      >
        <Component {...props} />
      </MsalAuthenticationTemplate>
    );
  };

export {
  SignIn,
  SignUp,
  SignOut,
  EditProfile,
  NewToken,
  ForgotPassword,
  ExistingUserRegister,
  Protected,
  withAuth,
};
