import "./Navbar.scss";

import classnames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

export interface NavbarProps {
  tabs: {
    label: string;
    id: string;
    href: string;
    params?: { [k: string]: string };
  }[];
  setActiveTab: (id: unknown) => void;
  activeTabId: string;
  buttons?: JSX.Element[];
}

const Navbar: React.FC<NavbarProps> = ({ tabs, setActiveTab, activeTabId, buttons }) => {
  function generateParams(params?: { [k: string]: string }) {
    if (!params) {
      return "";
    }
    return Object.entries(params)
      .map(([k, v]) => `${k}=${v}`)
      .join("&");
  }

  return (
    <div className="navbar">
      <div className="tabs">
        {tabs.map((tab) => (
          <Link
            className={classnames({ active: tab.id === activeTabId })}
            key={tab.label}
            to={{ pathname: tab.href, search: generateParams(tab.params) }}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </Link>
        ))}
      </div>
      <div className="buttons">
        {buttons?.map((button, i) => (
          <span key={i}>{button}</span>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
