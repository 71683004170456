import * as React from "react";
import "./FooterButton.scss";

export const FooterButton = ({ label, onClick, children }) => {
  return (
    <div className="footerButton" onClick={onClick}>
      {label}
      {children}
    </div>
  );
};
