import * as React from "react";
import { LogoFull } from "../LogoFull";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import "./NewHeader.scss";
import classNames from "classnames";
import Searchbar from "../Searchbar/Searchbar";
import { MobileSearchZone } from "../Sidebar/MobileSearchZone";
import MobileFilterZone from "../Sidebar/MobileFilterZone/MobileFilterZone";

interface NewHeaderProps {
  title?: string;
  query?: string;
  dataset?: string;
  mobileButtons?: { label: string; action: Function }[];
  disableSearch?: boolean;
  disableFilter?: boolean;
}

const NewHeader: React.FC<NewHeaderProps> = ({
  title,
  query,
  dataset,
  mobileButtons,
  disableSearch,
  disableFilter,
}) => {
  const { setMobileMenuOpen, openMobileSearch, openMobileFilter, layoutData, closeAllMobileZones } =
    React.useContext(GlobalContext);
  const [actionsMenuOpen, setActionsMenuOpen] = React.useState(false);

  React.useEffect(() => {
    if (actionsMenuOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [actionsMenuOpen]);

  return (
    <div className="newHeader">
      <div
        className={
          "mobileZones Search_Background" + (layoutData.isMobileSearchActive ? " active" : "")
        }
      >
        <CloseIcon className="closeIcon" onClick={() => closeAllMobileZones()} />
        <MobileSearchZone />
        <MobileFilterZone />
      </div>
      <div className="mobile">
        <div className="flex">
          <LogoFull svgClass="LogoFull" />
          <div className="flex">
            {!disableFilter && (
              <FilterAltIcon className="filterIcon" onClick={() => openMobileFilter()} />
            )}
            {!disableSearch && (
              <SearchIcon className="searchIcon" onClick={() => openMobileSearch()} />
            )}
            <MenuIcon onClick={() => setMobileMenuOpen(true)} />
          </div>
        </div>
        <div className="flex titleContainer">
          {title && <h3>{title}</h3>}
          {mobileButtons && (
            <>
              {actionsMenuOpen && (
                <div className="backdrop" onClick={() => setActionsMenuOpen(false)} />
              )}
              <div className={classNames("flex actions", { open: actionsMenuOpen })}>
                <h3 onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>More Actions</h3>
                <MoreVertIcon onClick={() => setActionsMenuOpen(!actionsMenuOpen)} />
                {actionsMenuOpen && (
                  <div className="mobileButtons">
                    {mobileButtons.map((button, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          button.action();
                          setActionsMenuOpen(false);
                        }}
                      >
                        {button.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {title && <h3 className="title">{title}</h3>}
      {!query && !dataset && <Searchbar />}
      <div className="queryContainer">
        {query && (
          <h5>
            Search results: <strong>{query}</strong>
          </h5>
        )}
        {dataset && (
          <h5>
            Dataset: <strong>{dataset}</strong>
          </h5>
        )}
      </div>
    </div>
  );
};

export default NewHeader;
