import React, { useLayoutEffect, useContext } from "react";
import { PageContainer } from "../../components";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";

const LegalPage = () => {
  const { pageName, setPageName } = useContext(GlobalContext);
  const thisPageName = "legalPage";

  useLayoutEffect(() => {
    if (thisPageName !== pageName) {
      setPageName(thisPageName);
    }
  }, [thisPageName, pageName, setPageName]);

  return (
    <PageContainer>
      <div className="dataZone">
        <div className="font_seperator">
          <b>Important legal information</b>
        </div>
        <div className="defaultTextContainer">
          <p>The emc med data browser website is owned and operated by Datapharm Ltd.</p>
          <p>
            Use of this site is subject to the following terms and disclaimers ("Terms") and by
            continuing to use the site you signify your acceptance of these Terms.
          </p>
          <p>
            Datapharm reserves the right to amend these Terms from time to time and any such
            amendment will be effective from the date when revised Terms appear on the site.
          </p>
          <p>
            <b>
              The material on the site must not be systematically reproduced, linked to and / or
              sold for commercial gain.
            </b>
          </p>
          <p>
            We reserve the right to restrict access to some or all of the website, at our
            discretion.
          </p>
          <h3>Content and site availability disclaimers</h3>
          <p>
            Every effort is taken to ensure that the information published on this website is
            accurate and kept up to date in terms of current medical knowledge. However, whilst we
            only accept information from respected sources we cannot guarantee that all of the
            information displayed is accurate or complete, nor can we guarantee that it will be
            suitable for a user's individual circumstances. This website is therefore provided for
            information only. It is not intended to replace a consultation with an appropriately
            qualified medical practitioner.
          </p>
          <p>
            Datapharm does not accept responsibility for any loss, damage or injury that arises from
            the use of this website except to the extent that liability cannot be excluded by law.
          </p>
          <p>
            We make every effort to ensure that this website is available at all times. To achieve
            this we do the following:
          </p>
          <ul>
            <li>
              Host the site within a fully managed solution monitored 24 hours a day, 365 days a
              year
            </li>
            <li>Use industry standard software for the servers and the website</li>
            <li>Have well developed security arrangements</li>
            <li>Have well developed monitoring, maintenance and repair procedures</li>
          </ul>
          <p>
            In spite of these efforts we cannot guarantee uninterrupted access to this website, or
            the sites to which it links. We also cannot guarantee that information on this website
            will display correctly on all types of browser. We accept no responsibility for any
            damages arising from the loss of access to and use of information or the manner in which
            such information may be displayed by individual browsers.
          </p>
          <h3>Copyright and usage</h3>
          <p>
            The material featured on this site is subject to copyright protection unless otherwise
            indicated.
          </p>
          <h4>Allowed use</h4>
          <p>
            The copyright protected material may be reproduced by anyone free of charge in any
            format or medium for private study or personal use. This is subject to the material
            being reproduced accurately and not being used in a misleading context.
          </p>
          <p>
            In addition and subject to any restrictions on use and the disclaimers published on this
            website from time to time
          </p>
          <ul>
            <li>
              healthcare professionals may use and download any content available on the site for
              professional use either by themselves or other healthcare professionals and may copy
              from the site (a) for distribution with related products or (b) for supply to members
              of the general public or other healthcare professionals for information purposes
              provided that in either case the relevant content is reproduced and supplied in their
              entirety and not in part.
            </li>
            <li>
              Pharmaceutical companies who are subscribers to dm+d solutions may reproduce the
              content for any purpose.
            </li>
          </ul>
          <h4>Non-UK organisations who are not members of emc</h4>
          <ul>
            <li>
              The information contained on this site is UK regulatory approved information. Any
              non-UK based organisation or subsidiary who is not an existing emc or Datapharm
              customer will need to be verified before use will be permitted to ensure the
              information is not used incorrectly. If Datapharm believes any organisation is using
              the information incorrectly or without our permission, we reserve the right to block
              access to the site
            </li>
          </ul>
          <h4>Prohibited use</h4>
          <p>
            <b>
              The material on the site must not be systematically reproduced, linked to and / or
              sold for commercial gain.
            </b>
          </p>
          <p> You agree not to use the Website:</p>
          <ul>
            <li>
              for any commercial purposes (which includes using the Website to promote or encourage
              the sale of your goods/services or for inclusion into healthcare and prescribing
              systems)
            </li>
            <li>
              to create or populate a database or knowledge bank (electronic or otherwise) that
              includes material downloaded or otherwise obtained from the Website
            </li>
            <li>
              to transmit or re-circulate any material obtained from the Website to any third party
              except where expressly permitted on the Website
            </li>
            <li>
              to routinely or systematically check/track changes/updates to 3rd party information
            </li>
            <li>
              to create additional information or summarise content for your own company or on
              behalf of another company.
            </li>
          </ul>
          <p>
            These terms apply directly to all organisations in the UK who gain commercially from
            using the website including but not limited to;
          </p>
          <ul>
            <li>Non emc pharmaceutical customers</li>
            <li>Publishers</li>
            <li>Contract Research Organisations</li>
            <li>Consultancies</li>
            <li>Medical Communication agencies</li>
            <li>Database providers</li>
            <li>Membership bodies</li>
            <li>Service providers</li>
            <li>Market Intelligence providers</li>
            <li>Clinical Trial providers</li>
            <li>Law firms</li>
          </ul>
          <p>
            For further information on Datapharm copyright policy: if you require clarification of
            how you can use the content on this site or you would like to enquire about licensing
            content please contact{" "}
            <a href="mailto:servicedesk@datapharm.com">servicedesk@datapharm.com</a>.
          </p>
          <h3>Privacy policy</h3>
          <p>
            Datapharm Limited (“we”, “us” or “our”) are committed to protecting the privacy of all
            individuals using our websites. We are the data controller and are responsible for your
            personally identifiable information obtained through your use of Datapharm product
            websites. The following statement sets out our privacy practices in relation to your use
            of Datapharm product websites.
          </p>
          <p>
            It is important that you read this privacy policy together with any other privacy policy
            or fair processing policy that we may provide on specific occasions when we are
            collecting or processing personally identifiable information about you so that you are
            fully aware of how and why we are using your information. This privacy policy
            supplements other notices and privacy policies and is not intended to override them.
          </p>
          <p>
            We have appointed a data privacy manager who is responsible for overseeing questions in
            relation to this privacy policy. If you have any questions about this privacy policy,
            including any requests to exercise your legal rights, please contact the data privacy
            manager using the details set out below.
          </p>
          <h4>Contact Details</h4>
          <p>
            If you have any questions about this privacy policy or our privacy practices, please
            contact our data privacy manager in the following ways
          </p>
          <p>Full name of legal entity: Datapharm Limited</p>
          <p>Email address: privacy@datapharm.com</p>
          <p>Postal address:</p>
          <p>
            Datapharm Limited <br />
            Cassini Court <br />
            Randalls Way <br />
            Leatherhead <br />
            Surrey KT22 7TW
          </p>
          <p>
            You have the right to make a complaint at any time to the Information Commissioner's
            Office (ICO), the UK supervisory authority for data protection issues (
            <a href="//www.ico.org.uk" target="_blank" rel="noopener noreferrer">
              www.ico.org.uk
            </a>
            ). We would, however, appreciate the chance to deal with your concerns before you
            approach the ICO so please contact us in the first instance.
          </p>
          <h4>Information collection and use</h4>
          <p>
            Personally identifiable information, means any information about an individual from
            which that person can be identified. It does not include information where the identity
            has been removed (anonymous data).
          </p>
          <p>
            We may collect, use, store and transfer different kinds of personally identifiable
            information about you which we have grouped together as follows:
          </p>
          <ul>
            <li>
              Identity Data - this includes first name, last name, username or similar identifier,
              title.
            </li>
            <li>Contact Data this includes email address and telephone numbers.</li>
            <li>
              Technical Data this includes internet protocol (IP) address, your login data, browser
              type and version, time zone setting and location, browser plug-in types and versions,
              operating system and platform, and other technology on the devices you use to access
              this website.
            </li>
            <li>
              Profile Data this includes your username and password, your preferences, feedback and
              survey responses.
            </li>
            <li>Usage Data includes information about how you use our website.</li>
            <li>
              Marketing and Communications Data includes your preferences in receiving marketing
              from us and our third parties and your communication preferences.
            </li>
          </ul>
          <p>
            We also collect, use and share Aggregated Data such as statistical or demographic data
            for any purpose. Aggregated Data could be derived from your personally identifiable
            information but is not considered personal data in law as this data will not directly or
            indirectly reveal your identity. For example, we may aggregate your Usage Data to
            calculate the percentage of users accessing a specific website feature. However, if we
            combine or connect Aggregated Data with your personal data so that it can directly or
            indirectly identify you, we treat the combined data as personally identifiable
            information which will be used in accordance with this privacy policy.
          </p>
          <h4>How is your data collected?</h4>
          <p>
            We use different methods to collect personally identifiable information from and about
            you including through:
          </p>
          <ul>
            <li>
              Direct interactions. You may give us your Identity or Contact Data by registering with
              the website or by corresponding with us by post, phone, email or otherwise.
            </li>
            <li>
              Automated technologies or interactions. As you interact with the website, we will
              automatically collect Usage Data and Technical Data about your equipment, browsing
              actions and patterns. We collect this personally identifiable information by using
              cookies and other similar technologies. Please see our cookie policy (
              <a
                href="https://www.medicines.org.uk/emc/privacy-policy-and-legal#cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie policy
              </a>
              ) for further details.
            </li>
            <li>
              Third parties. We will receive Usage Data and Technical Data about you from our
              analytics providers such as Google who are based outside the EU and if you are a
              business user from our marketing provider HubSpot.
            </li>
          </ul>
          <p>
            We do not collect or store any personally identifiable information about users of
            Datapharm products unless you voluntarily provide this information. You might
            voluntarily provide information when you:
          </p>
          <ul>
            <li>Contact us and we may keep a copy of the correspondence.</li>
            <li>Register to use our services.</li>
            <li>Log into Datapharm product websites</li>
            <li>Fill in a form to attend any of our events, meetings or training sessions</li>
            <li>Voluntarily complete a survey for research purposes; or</li>
            <li>Accept our user behaviour tracking cookies</li>
          </ul>
          <h4>How we use your data</h4>
          <p>
            We always aim to ensure that any personally identifiable information that we collect
            will be adequate, relevant, and not excessive for the purpose or purposes for which the
            personally identifiable information is stored and/or processed.
          </p>
          <p>
            We will only use your personally identifiable information when the law allows us to.
            Most commonly, we will use your personally identifiable information in the following
            circumstances (being the lawful basis for our processing):
          </p>
          <ul>
            <li>
              Where you consent i.e. by accepting our data practices as set out in this privacy
              policy
            </li>
            <li>
              Where we need to perform the contract we are about to enter into or have entered into
              with you;
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a third party) and
              your interests and fundamental rights do not override those interests; and
            </li>
            <li>Where we need to comply with a legal obligation.</li>
          </ul>
          <p>We do not sell any personally identifiable information to any third parties.</p>
          <p>We will only use the information you provide us to:</p>
          <ul>
            <li>Respond to your enquiries or requests;</li>
            <li>Notify you about changes to our services</li>
            <li>Carry out our contractual obligations;</li>
            <li>Provide you with relevant information about our products, services or training;</li>
            <li>Assess the user experience of the website; and</li>
            <li>Identify any unauthorised use of the website</li>
          </ul>
          <p>
            We may disclose your personally identifiable information to third parties, but only
            under the following limited circumstances:
          </p>
          <ul>
            <li>
              where we are under a legal obligation to do so. For example:
              <ul>
                <li>
                  if you provide safety data about a medicine we are obliged to pass this
                  information to the company that markets that medicine so that they can fulfil
                  their safety reporting obligations;
                </li>
                <li>
                  in response to any demand by law enforcement authorities in connection with a
                  criminal investigation, or civil or administrative authorities in connection with
                  a pending civil case or administrative investigation,
                </li>
              </ul>
            </li>
            <li>
              where we choose to sell, transfer or merge parts of our business or our assets. If a
              change happens to our business, then the new owners may use your personal data in the
              same way as set out in this privacy policy; or
            </li>
            <li>
              where we choose to sell, transfer or merge parts of our business or our assets. If a
              change happens to our business, then the new owners may use your personal data in the
              same way as set out in this privacy policy; or
            </li>
          </ul>
          <p>
            We require all third parties to respect the security of your personal data and to treat
            it in accordance with the law. We do not allow our third-party service providers
            personal data for specified purposes and in accordance with our instructions.
          </p>
          <h4>Change of Purpose</h4>
          <p>
            We will only use your personally identifiable information for the purposes for which we
            collected it, unless we reasonably consider that we need to use it for another reason
            and that reason is compatible with the original purpose. If you wish to get an
            explanation as to how the processing for the new purpose is compatible with the original
            purpose, please contact us.
          </p>
          <p>
            If we need to use your personal data for an unrelated purpose, we will notify you and we
            will explain the legal basis which allows us to do so.
          </p>
          <p>
            Please note that we may process your personal data without your knowledge or consent, in
            compliance with the above rules, where this is required or permitted by law.
          </p>
          <p>
            <b>Processing of Special Category Data</b> Whilst the intention of our products is not
            to collect special categories of personally identifiable information (i.e. information
            concerning your health), we are mindful of the fact that linking your Usage Data and/or
            Profile Data to your personally identifiable information may inadvertently reveal
            information concerning your health.
          </p>
          <p>
            As detailed further in our cookie policy (
            <a
              href="https://www.medicines.org.uk/emc/privacy-policy-and-legal#cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie policy
            </a>
            ), when you use our product websites, we collect details of visitor behaviour patterns.
            For example, we collect the total number of visits to our websites, the number of
            visitors to each page and the domain names of our visitors' internet service provider.
            We use this information to analyse trends, administer the site and track users'
            movements so that we can improve our product websites and identify unauthorised use of
            our websites.
          </p>
          <p>
            Our lawful basis for such processing is to rely on your explicit consent for us to do
            so. If you do not wish for your Usage Data to be processed in such a manner, please
            ensure that the relevant cookies are turned off (see our cookie policy for further
            details) and do not save or bookmark any preferences to your profile.
          </p>
          <p>
            Please also note that we may use the Usage Data described above to provide analytical
            data to our customers, in order to assist them with trend analysis. Where we do share
            such analytical data, all personally indefinable information will be anonymised and
            therefore our customers will not be able to identify you personally from the analytical
            data we share with them.
          </p>
          <p>
            We will take your acceptance of our privacy policy during registration and your
            continued use of the website, as your explicit consent to process your Usage and/or
            Profile Data for the purposes described above. We therefore rely on your explicit
            consent as the lawful basis for the processing of any special categories of personally
            indefinable information.
          </p>
          <h4>How we will safeguard this data</h4>
          <p>
            We have procedures in place to ensure that we only process these special categories of
            personal information in accordance with the law. We ensure that:
          </p>
          <p>
            (i) this personal information is processed lawfully, fairly and in a transparent manner.
            Our privacy policy sets out the legal bases on which we rely to process personal
            information for various purposes;
          </p>
          <p>
            (ii) we only collect this personal information for specified, explicit and legitimate
            purposes and we only process the information for these purposes. A further explanation
            as to the purposes for which we collect personal information can be found in our privacy
            policy;
          </p>
          <p>
            (iii) any personal information we collect is adequate, relevant and limited to what is
            necessary in order to provide you with our services;
          </p>
          <p>
            (iv) this personal information is accurate and kept up to date as we require you to
            input your personal information on our website each time you use our services;
          </p>
          <p>(v) we keep this personal information in a form which allows identification; and</p>
          <p>
            (vi) we keep this personal information secure as we have put in place appropriate
            security measures to protect personal information from being accidentally lost, used, or
            accessed in an unauthorised way.
          </p>
          <h4>Your Choices</h4>
          <p>
            We strive to provide you with choices regarding certain personally identifiable
            information uses, particularly around marketing and advertising. If you receive
            communications, such as updates and newsletters from us, you can opt out at any time, by
            following the instructions in the communication. We will obtain your express opt-in
            consent before we share your personal data with any third party for marketing purposes.
          </p>
          <p>
            You can prevent our use of cookies by following the instructions in our Cookie Policy (
            <a
              href="https://www.medicines.org.uk/emc/privacy-policy-and-legal#cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie policy
            </a>
            ).
          </p>
          <p>
            It is important to us that all personally identifiable information we hold about you is
            accurate and current. Please keep us informed if your personal data changes during your
            relationship with us.
          </p>
          <h4>Where we store your personally identifiable information</h4>
          <p>
            Any personally identifiable information that you provide will be stored on secure
            servers. The data will primarily be stored and processed in the UK. As some of our
            external third-party providers are based outside the European Economic Area (EEA), your
            personally identifiable information may from time to time be stored and processed on
            servers located outside the EEA. Where your personally identifiable information is
            transferred outside of the EEA, we ensure that a similar degree of protection is
            afforded to it by ensuring at least one of the following safeguards are implemented:
          </p>
          <ul>
            <li>
              We will only transfer your personally identifiable information to countries that have
              been deemed to provide an adequate level of protection for personal data by the
              European Commission.
            </li>
            <li>
              Where we use certain service providers, we may use specific contracts approved by the
              European Commission which give personal data the same protection it has in Europe.
            </li>
          </ul>
          <p>
            Please contact us if you want further information on the specific mechanism used by us
            when transferring your personally identifiable information out of the EEA.
          </p>
          <h4>Data Security</h4>
          <p>
            We have put in place appropriate security measures to prevent your personally
            identifiable information from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access to your personally
            identifiable information to those employees, agents, contractors and other third parties
            who have a business need to know. They will only process your personally identifiable
            information on our instructions and they are subject to a duty of confidentiality.
          </p>
          <p>
            We have put in place procedures to deal with any suspected personally identifiable
            information breach and will notify you and any applicable regulator of a breach where we
            are legally required to do so.
          </p>
          <h4>How Long Will You Use My Personally Identifiable Information For?</h4>
          <p>
            We will only retain your personally identifiable information for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for the purposes of
            satisfying any legal, regulatory, tax, accounting or reporting requirements. We may
            retain your personally identifiable information for a longer riod in the event of a
            complaint or if we reasonably believe there is a prospect of litigation in respect to
            our relationship with you.
          </p>
          <p>
            To determine the appropriate retention period for personally identifiable information,
            we consider the amount, nature and sensitivity of the personally identifiable
            information, the potential risk of harm from unauthorised use or disclosure of your
            personally identifiable information, the purposes for which we process your personally
            identifiable information and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other requirements.
          </p>
          <p>
            In some circumstances we will anonymise your personally identifiable information (so
            that it can no longer be associated with you) for research or statistical purposes, in
            which case we may use this information indefinitely without further notice to you.
          </p>
          <p>
            Details of retention periods for different aspects of your personally identifiable
            information are available upon request.
          </p>
          <h4>Your Legal Rights</h4>
          <p>
            Under certain circumstances, you have rights under data protection laws in relation to
            your personally identifiable information. These are as follows (for further information
            as to your legal rights please visit the ICO’s website):
          </p>
          <p>
            <b>Request access</b> to your personally identifiable information (commonly known as a
            "data subject access request"). This enables you to receive a copy of the personally
            identifiable information we hold about you and to check that we are lawfully processing
            it.
          </p>
          <p>
            <b>Request correction</b> of the personally identifiable information that we hold about
            you. This enables you to have any incomplete or inaccurate data we hold about you
            corrected, though we may need to verify the accuracy of the new data you provide to us.
          </p>
          <p>
            <b>Request erasure</b> of your personally identifiable information. This enables you to
            ask us to delete or remove personally identifiable information where there is no good
            reason for us continuing to process it. You also have the right to ask us to delete or
            remove your personally identifiable information where you have successfully exercised
            your right to object to processing (see below), where we may have processed your
            information unlawfully or where we are required to erase your personally identifiable
            information to comply with local law. Note, however, that we may not always be able to
            comply with your request of erasure for specific legal reasons which will be notified to
            you, if applicable, at the time of your request.
          </p>
          <p>
            <b>Object to processing</b> of your personally identifiable information where we are
            relying on a legitimate interest (or those of a third party) and there is something
            about your particular situation which makes you want to object to processing on this
            ground as you feel it impacts on your fundamental rights and freedoms. You also have the
            right to object where we are processing your personally identifiable information for
            direct marketing purposes. In some cases, we may demonstrate that we have compelling
            legitimate grounds to process your information which override your rights and freedoms.
          </p>
          <p>
            <b>Request restriction of processing</b> of your personally identifiable information.
            This enables you to ask us to suspend the processing of your personally identifiable
            information in the following scenarios:
          </p>
          <ul>
            <li>If you want us to establish the data's accuracy.</li>
            <li>Where our use of the data is unlawful but you do not want us to erase it.</li>
            <li>
              Where you need us to hold the data even if we no longer require it as you need it to
              establish, exercise or defend legal claims.
            </li>
            <li>
              You have objected to our use of your data but we need to verify whether we have
              overriding legitimate grounds to use it.
            </li>
          </ul>
          <p>
            <b>Request the transfer</b> of your personally identifiable information to you or to a
            third party. We will provide to you, or a third party you have chosen, your personally
            identifiable information in a structured, commonly used, machine-readable format. Note
            that this right only applies to automated information which you initially provided
            consent for us to use or where we used the information to perform a contract with you.
          </p>
          <p>
            <b>Withdraw consent at any time</b> where we are relying on consent to process your
            personally identifiable information. However, this will not affect the lawfulness of any
            processing carried out before you withdraw your consent. If you withdraw your consent,
            we may not be able to provide certain products or services to you. We will advise you if
            this is the case at the time you withdraw your consent.
          </p>
          <p>If you wish to exercise any of the rights set out above, please contact us.</p>
          <h4>No Fee Usually Required</h4>
          <p>
            You will not have to pay a fee to access your personally identifiable information (or to
            exercise any of the other rights). However, we may charge a reasonable fee if your
            request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to
            comply with your request in these circumstances.
          </p>
          <h4>Time Limit to Respond</h4>
          <p>
            We try to respond to all legitimate requests within one month. Occasionally it could
            take us longer than a month if your request is particularly complex or you have made a
            number of requests. In this case, we will notify you and keep you updated.
          </p>
          <h4>Updates to This Privacy Policy</h4>
          <p>
            We may from time to time revise this privacy policy, but we will notify you where we do
            so. Continued use of Datapharm product websites after receiving notice of a change to
            this privacy policy indicates your consent to the use of newly submitted information in
            accordance with the amendments. This privacy policy was last updated in November 2020.
          </p>
          <h4>Users of our website</h4>
          <p>
            When you use our website, we collect details of visitor behaviour patterns. For example,
            we collect the total number of visits to our websites, the number of visitors to each
            page and the domain names of our visitors' internet service provider. We use this
            information to analyse trends, administer the site and track users' movements so that we
            can improve our sites.
          </p>
          <h3>Cookie Policy</h3>
          <p>
            At Datapharm, we care about our users and we would like to reassure you that you are in
            control of the data that you are sharing with us.
          </p>
          <p>
            We use cookies to deliver the best user experience to you and to understand how we can
            further improve our services.
          </p>
          <h4>How to manage cookies</h4>
          <p>
            A cookie is a small file of letters and numbers that is transferred from a website, via
            your web browser and is automatically stored on your device's hard drive. Cookies are
            widely used to make websites function properly or make them work better.
          </p>
          <h4>How do we use cookies?</h4>
          <p>We use our cookies for:</p>
          <ul>
            <li>
              Understanding and measuring our user traffic so that we can ensure that we have future
              capabilities to ensure that the speed of the website stays consistent over time.
            </li>
            <li>
              Keeping you logged in every time you visit our website if you are a registered user.
            </li>
            <li>
              Ensuring permitted use of the website. The website offers accessible medicines
              information to healthcare professionals. The website should not be used for commercial
              purposes. For more information, please see our Legal and Privacy Policy.
            </li>
          </ul>
          <p>
            Before cookies are placed on your device, a cookie banner will display automatically
            requesting that you either accept or decline the placement of cookies on your device. By
            accepting the placement of cookies, you are enabling us to provide the best possible
            user experience and service to you. You may, if you wish, decline the placement of
            cookies on your device, unless those cookies are stated as strictly necessary for the
            proper functioning of the website.
          </p>
          <h4>What cookies do we use?</h4>
          <p>
            <b>Performance Cookies</b>
          </p>
          <p>
            Performance cookies enable you to move around the website and use essential features.
            These cookies are useful for recognising our registered users, so you do not have to
            login every time you visit the website.
          </p>
          <p>
            <b>How to manage:</b> Most web browsers are set to accept cookies, but if you do not
            want to receive cookies, you can change your browser so that it notifies you when
            cookies are sent to your device or you can refuse cookies altogether (including
            essential cookies). If you restrict or block web browser cookies, you may find that some
            aspects of the website will not function properly.
          </p>
          <p>
            <b>Analytics cookies</b>
          </p>
          <p>
            The website uses Google Analytics to collect information about how visitors interact
            with the website. For example, Google Analytics records the number of visitors, the time
            of visit, which pages you visited, whether you have visited the site before and what
            site referred you to the website. We use the data from Google Analytics to compile
            reports and improve the website. We will only be using the analytical data for internal
            purposes as set out in our Privacy Policy i.e. to ensure that users utilise the website
            in accordance with our content policy. For more information and to opt out of being
            tracked by Google Analytics, please visit the Google website (
            <a
              href="https://policies.google.com/technologies/cookies?hl=en-US#managing-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google - Managing Cookies
            </a>
            )
          </p>
          <p>
            <b>How to manage:</b> you can customise the analytics cookie preferences by accessing
            the cookie banner, which displays automatically when you visit the website.
          </p>
          <p>
            <b>Third party cookies</b>
          </p>
          <p>
            These cookies are set by a site/service outside of our control. For example, the website
            might use embedded YouTube or Flickr content, or use third party advertising, and these
            sites may set their own cookies.
          </p>
          <p>
            <b>How to manage:</b> you can customise the third-party cookie preferences by accessing
            the cookie banner, which displays automatically when you visit the website.
          </p>
          <h4>Changes</h4>
          <p>
            We reserve the right to update this Cookie Policy at any time. Unless we advise
            otherwise, any amendments to this policy will be effective from the date on which an
            updated Cookie Policy is posted on the website. We encourage you to periodically review
            this Cookie Policy for the latest information about our use of cookies on the website.
          </p>
          <h4>IP address</h4>
          <p>
            An Internet Protocol (IP) address is a set of numbers that is automatically assigned to
            your device when you browse the internet. Our web servers may log the IP address and we
            may use this data to conduct system administration and to analyse website usage and
            performance. If you access our sites through mobile or other devices, we may also
            collect your unique device identifier.
          </p>
          <h4>Links policy</h4>
          <p>
            Links are provided for information and convenience only. We cannot accept responsibility
            for the content or policies of any sites linked to, or the information found there. A
            link does not imply an endorsement of a site; likewise, not linking to a particular site
            does not imply lack of endorsement.
          </p>
          <p>
            We reserve the right to withdraw a link or refuse to link to any website without
            providing an explanation or justification.
          </p>
        </div>
      </div>
    </PageContainer>
  );
};

export default LegalPage;
