import "./LevelPage.scss";

import React, { useCallback, useContext, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { PageContainer } from "../../components";
import { Button } from "../../components/FormElements/Buttons/Button.js";
import { Header } from "../../components/Header/Header";
import { RenderBoxes } from "../../components/RenderContent/RenderBoxes";
import { RenderDetails } from "../../components/RenderContent/RenderDetails";
import { ClientContext } from "../../contexts/ClientStore/ClientStore";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { ToggleFavouriteButton } from "../../features/favourites";
import { Feature } from "../../features/features";
import { fetchAPI } from "../APIConnections.js";

const LevelPage = (props) => {

//-------------------------------------------------------------
// 		VARIABLES
//-------------------------------------------------------------
const thisPageName = "levelPage";
const { isLoading,setIsLoading,isLoadingMore,setIsLoadingMore,
        APIData,setAPIData,
        searchBoxStatus,setSearchBoxStatus,
        lastRenderedId,setLastRenderedId,
        pageName,setPageName } = useContext(GlobalContext);

const { CS } = useContext(ClientContext);
const value = props.match.params.value;
const tab = props.match.params.tab?(props.match.params.tab).toLowerCase():(props.level).toLowerCase()+"s";
const level = (props.level).toLowerCase();
const companyId = props.match.params.companyId;
const [detailZoneRect, setDetailZoneRect] = useState(null);
const detailZone = useRef();
const [boxesZoneRect, setBoxesZoneRect] = useState(null);
const boxesZone = useRef();

const [mainData, setMainData] = useState(null);
const [mainDataRelatedItems, setMainDataRelatedItems] = useState(null);

const [VTMsData, setVTMsData] = useState(null);
const [VMPsData, setVMPsData] = useState(null);
const [VMPPsData, setVMPPsData] = useState(null);
const [AMPsData, setAMPsData] = useState(null);
const [AMPPsData, setAMPPsData] = useState(null);
const [SUPsData, setSUPsData] = useState(null);
const [SCPACKsData, setSCPACKsData] = useState(null);
const [SCCOMPANiesData, setSCCOMPANiesData] = useState(null);

const history = useHistory();

//-------------------------------------------------------------
// 		CHANGE PAGE NAME
//-------------------------------------------------------------
useLayoutEffect(() => { if (thisPageName!==pageName){ setPageName(thisPageName) }; }, [thisPageName,pageName,setPageName]);




//-------------------------------------------------------------
// 		DEFINE OFFSET and TOP VALUES
//-------------------------------------------------------------
const boxLineNumber = 6;

const calculateClientTop= useCallback(() => {
  let returnValue = CS.WINW > 1300
  ?
  4*boxLineNumber
  :
  CS.WINW>1000
  ?
  3*boxLineNumber
  :
  CS.WINW>700
  ?
  2*boxLineNumber
  :
  1*boxLineNumber;

  return returnValue;
    
},[CS]);

const calculateRelativeTop = useCallback(() => {
  let returnValue = CS.WINW > 1300
  ?
  24
  :
  CS.WINW>1000
  ?
  16
  :
  CS.WINW>700
  ?
  6
  :
  2;

  return returnValue;
    
},[CS]);

const [clientTop, setClientTop] = useState(calculateClientTop());
const [relativeTop, setRelativeTop] = useState(calculateRelativeTop());

useLayoutEffect(() => {
  setClientTop(calculateClientTop());
  setRelativeTop(calculateRelativeTop());
}, [CS,calculateClientTop,calculateRelativeTop]);


//-------------------------------------------------------------
// 		MAIN API CONNECTION & HANDLE API
//-------------------------------------------------------------

useLayoutEffect(() => {
  const getmainData = async () => {
    setMainData( await fetchAPI(props.history, {apiType:level +"Details",value:value,relativeTop:relativeTop,companyId:companyId},setIsLoading) );
    setLastRenderedId(value);
  };
  getmainData();

  // we don't need relativeTop in dependencies.
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [level,value,props.history,setIsLoading,setLastRenderedId]);


useLayoutEffect(() => {if (mainData !== null && lastRenderedId === value){


  // DEFINE HEADER ELEMENTS DEPENDING ON LEVEL (VTM, VMP etc.) WE ARE IN.
  // THIS PART IS LITTLE BIT COMPLICATED BECAUSE HEADER BEHAVIORS ARE DIFFERENT for EACH LEVEL.

  let header_VTM_Length,headerURL_VTM,header_VMP_Length,headerURL_VMP,header_VMPP_Length,
  headerURL_VMPP,header_AMP_Length,headerURL_AMP,header_AMPP_Length,
    headerURL_AMPP, header_SUP_Length, headerURL_SUP, selectMainDataRelatedItems, pageHeadline,
    header_SCPACK_Length,header_SCCOMPANY_Length, headerURL_SCPACK, headerURL_SCCOMPANY;

  if (level === "vtm"){

    selectMainDataRelatedItems = mainData.related.vmps.items;
    pageHeadline = mainData.related.vtms.items[0].nm;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = mainData.related.vmps.count;
    headerURL_VMP = "vtm/"+ value +"/vmps";

    header_VMPP_Length = mainData.related.vmpps.count;
    headerURL_VMPP = "vtm/"+ value +"/vmpps";

    header_AMP_Length = mainData.related.amps.count;
    headerURL_AMP = "vtm/"+ value +"/amps";

    header_AMPP_Length = mainData.related.ampps.count;
    headerURL_AMPP = "vtm/"+ value +"/ampps";

    header_SUP_Length = 0;
    headerURL_SUP = null;

    header_SCPACK_Length = 0;
    headerURL_SCPACK = null;

    header_SCCOMPANY_Length = 0;
    headerURL_SCCOMPANY = null;

  } else if (level === "vmp"){

    selectMainDataRelatedItems = mainData.related.amps.items;
    pageHeadline = mainData.related.vmps.items[0].nm;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = mainData.related.vmps.count;
    headerURL_VMP = "vmp/"+ value +"/vmps";

    header_VMPP_Length = mainData.related.vmpps.count;
    headerURL_VMPP = "vmp/"+ value +"/vmpps";

    header_AMP_Length = mainData.related.amps.count;
    headerURL_AMP = "vmp/"+ value +"/amps";

    header_AMPP_Length = mainData.related.ampps.count;
    headerURL_AMPP = "vmp/"+ value +"/ampps";

    header_SUP_Length = 0;
    headerURL_SUP = null;
    
    header_SCPACK_Length = 0;
    headerURL_SCPACK = null;

    header_SCCOMPANY_Length = 0;
    headerURL_SCCOMPANY = null;

  } else if (level === "vmpp"){

    selectMainDataRelatedItems = mainData.related.ampps.items;
    pageHeadline = mainData.related.vmpps.items[0].nm;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = mainData.related.vmps.count;
    headerURL_VMP = "vmp/"+ mainData.related.vmps.items[0].id +"/vmps";

    header_VMPP_Length = mainData.related.vmpps.count;
    headerURL_VMPP = "vmpp/"+ value +"/vmpps";

    header_AMP_Length = mainData.related.amps.count;
    headerURL_AMP = "vmpp/"+ value +"/amps";

    header_AMPP_Length = mainData.related.ampps.count;
    headerURL_AMPP = "vmpp/"+ value +"/ampps";

    header_SUP_Length = 0;
    headerURL_SUP = null;
    
    header_SCPACK_Length = 0;
    headerURL_SCPACK = null;

    header_SCCOMPANY_Length = 0;
    headerURL_SCCOMPANY = null;

  } else if (level === "amp"){

    selectMainDataRelatedItems = mainData.related.ampps.items;
    pageHeadline = mainData.related.amps.items[0].desc;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = mainData.related.vmps.count;
    headerURL_VMP = "vmp/"+ mainData.related.vmps.items[0].id +"/vmps";

    header_VMPP_Length = 0;
    headerURL_VMPP = null;

    header_AMP_Length = mainData.related.amps.count;
    headerURL_AMP = "amp/"+ value +"/amps";

    header_AMPP_Length = mainData.related.ampps.count;
    headerURL_AMPP = "amp/"+ value +"/ampps";

    header_SUP_Length = mainData.related.sups.count;
    headerURL_SUP = "sup/"+ mainData.related.sups.items[0].id +"/sups";

    header_SCPACK_Length = 0;
    headerURL_SCPACK = null;

    header_SCCOMPANY_Length = 0;
    headerURL_SCCOMPANY = null;

  } else if (level === "ampp"){

    selectMainDataRelatedItems = null;
    pageHeadline = mainData.related.ampps.items[0].nm;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = mainData.related.vmps.count;
    headerURL_VMP = "vmp/"+ mainData.related.vmps.items[0].id +"/vmps";

    header_VMPP_Length = mainData.related.vmpps.count;
    headerURL_VMPP = "vmpp/"+ mainData.related.vmpps.items[0].id +"/vmpps";

    header_AMP_Length = mainData.related.amps.count;
    headerURL_AMP = "amp/"+ mainData.related.amps.items[0].id +"/amps";

    header_AMPP_Length = mainData.related.ampps.count;
    headerURL_AMPP = "ampp/"+ value +"/ampps";

    header_SUP_Length = mainData.related.sups.count;
    headerURL_SUP = "sup/"+ mainData.related.sups.items[0].id +"/sups";

    header_SCPACK_Length = 0;
    headerURL_SCPACK = null;

    header_SCCOMPANY_Length = 0;
    headerURL_SCCOMPANY = null;

  } else if (level === "sup"){

    selectMainDataRelatedItems = mainData.related.ampps.items;
    pageHeadline = mainData.related.sups.items[0].nm;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = 0;
    headerURL_VMP = null;

    header_VMPP_Length = 0;
    headerURL_VMPP = null;

    header_AMP_Length = mainData.related.amps.count;
    headerURL_AMP = "sup/"+ value +"/amps";

    header_AMPP_Length = mainData.related.ampps.count;
    headerURL_AMPP = "sup/"+ value +"/ampps";

    header_SUP_Length = mainData.related.sups.count;
    headerURL_SUP = "sup/"+ mainData.related.sups.items[0].id +"/sups";

    header_SCPACK_Length = 0;
    headerURL_SCPACK = null;

    header_SCCOMPANY_Length = 0;
    headerURL_SCCOMPANY = null;

  } else if (level === "scpack"){

    selectMainDataRelatedItems = null;
    pageHeadline =  mainData.sections[0].items[3].value;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = 0;
    headerURL_VMP = null;

    header_VMPP_Length = 0;
    headerURL_VMPP = null;

    header_AMP_Length = 0;
    headerURL_AMP = null;

    header_AMPP_Length = 0;
    headerURL_AMPP = null;

    header_SUP_Length = 0;
    headerURL_SUP = null;
    
    header_SCPACK_Length = 1;
    headerURL_SCPACK = "scpack/"+ value +"/scpacks";

    header_SCCOMPANY_Length = 1;
    headerURL_SCCOMPANY = "sccompany/" + mainData.sections[0].items[2].value + "/sccompanys";

  } else if (level === "sccompany"){

    selectMainDataRelatedItems = null;
    pageHeadline = mainData.sections[0].items[1].value;

    header_VTM_Length = 0;
    headerURL_VTM = null;

    header_VMP_Length = 0;
    headerURL_VMP = null;

    header_VMPP_Length = 0;
    headerURL_VMPP = null;

    header_AMP_Length = 0;
    headerURL_AMP = null;

    header_AMPP_Length = 0;
    headerURL_AMPP = null;

    header_SCPACK_Length = mainData.packsCount;
    headerURL_SCPACK = "sccompany/"+ value +"/scpacks";

    header_SCCOMPANY_Length = 1;
    headerURL_SCCOMPANY = "sccompany/" + mainData.sections[0].items[0].value + "/sccompanys";

  }

  setMainDataRelatedItems(selectMainDataRelatedItems);

  setAPIData(prevGS => ({
    ...prevGS,
    value:value,
    level:level,
    tab:tab,
    headline:pageHeadline,

    VTMLength:header_VTM_Length,
    VMPLength:header_VMP_Length,
    VMPPLength:header_VMPP_Length,
    AMPLength:header_AMP_Length,
    AMPPLength:header_AMPP_Length,
    SUPLength:header_SUP_Length,
    SCPACKLength:header_SCPACK_Length,
    SCCOMPANYLength:header_SCCOMPANY_Length,

    VTMURL:headerURL_VTM,
    VMPURL:headerURL_VMP,
    VMPPURL:headerURL_VMPP,
    AMPURL:headerURL_AMP,
    AMPPURL:headerURL_AMPP,
    SUPURL:headerURL_SUP,
    SCPACKURL:headerURL_SCPACK,
    SCCOMPANYURL:headerURL_SCCOMPANY

  }));


}},[mainData, setAPIData, tab, value,level, lastRenderedId]);

//-------------------------------------------------------------
// 		OTHER TABS' API CONNECTION & HANDLE API
//-------------------------------------------------------------
useLayoutEffect(() => {
  if (tab !== level + "s"){
    
    const getVTMtab = async ()=>{setVTMsData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop,offset:VTMsData?VTMsData.length:0},setIsLoading))};
    const getVMPtab = async ()=>{setVMPsData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop,offset:VMPsData?VMPsData.length:0},setIsLoading))};
    const getAMPtab = async ()=>{setAMPsData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop,offset:AMPsData?AMPsData.length:0},setIsLoading))};
    const getSUPtab = async ()=>{setSUPsData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop,offset:SUPsData?SUPsData.length:0},setIsLoading))};
    const getVMPPtab = async ()=>{setVMPPsData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop,offset:VMPPsData?VMPPsData.length:0},setIsLoading))};
    const getAMPPtab = async ()=>{setAMPPsData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop, offset:AMPPsData?AMPPsData.length:0 },setIsLoading))};

    const getSCCOMPANYtab = async ()=>{setSCCOMPANiesData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop,offset:SCCOMPANiesData?SCCOMPANiesData.length:0},setIsLoading))};
    const getSCPACKtab = async ()=>{setSCPACKsData( await fetchAPI(props.history, {apiType:tab,value:value,level:level,top:clientTop, offset:SCPACKsData?SCPACKsData.length:0 },setIsLoading))};

    if (tab === "vtms" && VTMsData === null) { getVTMtab() }
  else if (tab === "vmps" && VMPsData === null){ getVMPtab() }
  else if (tab === "amps" && AMPsData === null){ getAMPtab() }
  else if (tab === "sups" && SUPsData === null){ getSUPtab() }
  else if (tab === "vmpps" && VMPPsData === null){ getVMPPtab() }
  else if (tab === "ampps" && AMPPsData === null){ getAMPPtab() }
  else if (tab === "scpacks" && SCPACKsData === null){ getSCPACKtab() }
  else if (tab === "sccompanys" && SCCOMPANiesData === null){ getSCCOMPANYtab() }

  // we don't need clientTop in dependencies.
  // eslint-disable-next-line react-hooks/exhaustive-deps
}}, [tab,props,value,level,AMPPsData,AMPsData,SUPsData,VMPPsData,VMPsData,VTMsData,SCPACKsData,SCCOMPANiesData,setIsLoading]);

//-------------------------------------------------------------
// 		REMOVE ISLOADING
//-------------------------------------------------------------
useLayoutEffect(() => {

  const setSetIsLoadingFalse = () => { setIsLoading(false); }

  if (tab === level + "s"){

    if (mainData!==null && lastRenderedId === value && isLoading){ setSetIsLoadingFalse(); }
  
  } else {

    if (mainData!==null && lastRenderedId === value && isLoading){

      if (tab === "vtms" && VTMsData!==null){ setSetIsLoadingFalse(); }
      else if (tab === "vmps" && VMPsData!==null){ setSetIsLoadingFalse(); }
      else if (tab === "amps" && AMPsData!==null){ setSetIsLoadingFalse(); }
      else if (tab === "sups" && SUPsData!==null){ setSetIsLoadingFalse(); }
      else if (tab === "vmpps" && VMPPsData!==null){ setSetIsLoadingFalse(); }
      else if (tab === "ampps" && AMPPsData!==null){ setSetIsLoadingFalse(); }
      else if (tab === "scpacks" && SCPACKsData!==null){ setSetIsLoadingFalse(); }
      else if (tab === "sccompanys" && SCCOMPANiesData!==null){ setSetIsLoadingFalse(); }

    }

  }

}, [AMPPsData,AMPsData,SUPsData,VMPPsData,VMPsData,VTMsData,SCPACKsData,SCCOMPANiesData,isLoading,mainData,setIsLoading,tab,level, lastRenderedId,value]);

//-------------------------------------------------------------
// 		DIV ZONES HEIGHT EQUALITY FOR WIDE SCREENS. STICKY + JS > 1000 // IMPORTANT ---> ALL LEVELS EXCEPT AMPP and IE11
//-------------------------------------------------------------


useLayoutEffect(() => {if (level !== "ampp" && tab === level + "s" && CS.WINW > 1000 && mainData !== null && lastRenderedId === value && CS.BROWSER!=="IE"){
  setTimeout(function(){

      setDetailZoneRect(detailZone && detailZone.current ? detailZone.current.clientHeight : null);
      setBoxesZoneRect(boxesZone && boxesZone.current ? boxesZone.current.clientHeight : null);
  
    }, 500);
  
}}, [tab,level,mainData, CS.WINW,lastRenderedId,value,CS.BROWSER]);

useLayoutEffect(() => {if (level !== "ampp" && tab === level + "s" && CS.WINW > 1000 && mainData !== null && lastRenderedId === value && CS.BROWSER!=="IE"){

  if (detailZoneRect && boxesZoneRect){
    detailZone.current.style.top = ( detailZoneRect<CS.WINH?150:-1*(detailZoneRect - CS.WINH ) )+"px";
    if(level !== "scpack"){
      boxesZone.current.style.top = ( boxesZoneRect<CS.WINH?150:-1*(boxesZoneRect - CS.WINH ) )+"px";
    }
  }
}}, [tab,level,detailZoneRect,boxesZoneRect, CS.WINH, CS.WINW, lastRenderedId, mainData,value,CS.BROWSER]);

//-------------------------------------------------------------
// 		CHANGE reCalculate STATE to CATCH EXPANDING - COLLAPSING ZONES
//-------------------------------------------------------------
const calculateDetailsHeight = async () => {
  setTimeout(function(){

    setDetailZoneRect(detailZone && detailZone.current ? detailZone.current.clientHeight : null);

}, 250);
}

//-------------------------------------------------------------
// 		CLOSE SEARCHBOX IF ITS ACTIVE
//-------------------------------------------------------------

useLayoutEffect(() => {
  if (searchBoxStatus !=="default" && lastRenderedId !== value){
    setSearchBoxStatus("default");
  }
}, [tab,level,value,searchBoxStatus,setSearchBoxStatus,lastRenderedId]);

//-------------------------------------------------------------
// 		HANDLE LOAD MORE BUTTON
//-------------------------------------------------------------

const handleLoadMore = async (ATTS) => {

  let newIncomingPackage = [];
  let scrolling = true; if (ATTS !== undefined && ATTS.scroll===false){ scrolling = false }
  
  let newOfset = null;

  if(tab === "vmps"){ newOfset = VMPsData.length; }
  if(tab === "amps"){ newOfset = AMPsData.length; }
  if(tab === "vmpps"){ newOfset = VMPPsData.length; }
  if(tab === "ampps"){ newOfset = AMPPsData.length; }
  if(tab === "sups"){ newOfset = SUPsData.length; }
  if(tab === "scpacks"){ newOfset = SCPACKsData.length; }
  if(tab === "sccompanys"){ newOfset = SCCOMPANiesData.length; }

    newIncomingPackage = await fetchAPI(props.history, {
      apiType:tab,
      value:value,
      level:level,
      top:clientTop,
      offset:newOfset
      },setIsLoading,setIsLoadingMore);


  if(tab === "vmps"){ setVMPsData(VMPsData.concat(newIncomingPackage)) }
  if(tab === "amps"){ setAMPsData(AMPsData.concat(newIncomingPackage)) }
  if(tab === "vmpps"){ setVMPPsData(VMPPsData.concat(newIncomingPackage)) }
  if(tab === "ampps"){ setAMPPsData(AMPPsData.concat(newIncomingPackage)) }
  if(tab === "sups"){ setSUPsData(SUPsData.concat(newIncomingPackage)) }
  if(tab === "scpacks"){ setSCPACKsData(SCPACKsData.concat(newIncomingPackage)) }
  if(tab === "sccompanys"){ setSCCOMPANiesData(SCCOMPANiesData.concat(newIncomingPackage)) }
  setIsLoadingMore(false);

  if(scrolling){
    window.scrollTo({
      top: (window.pageYOffset+(CS.WINH/2)),
      left:0,
      behavior: 'smooth'
    });
  }

}

//-------------------------------------------------------------
// 		HANDLE GO TO TOP
//-------------------------------------------------------------
const handleGoToTop =()=>{
  window.scrollTo({ top:0, left:0, behavior: 'smooth' });
}

//-------------------------------------------------------------
// 		RETURN
//-------------------------------------------------------------
if (mainData === null || lastRenderedId !== value ){ return null }
else if ( tab === level + "s" ){

//sccompany, sup
  if( level === "ampp" || level === "scpack" ) {
    return (
      <PageContainer>
        <Header location={props.location} level={level}/>
        <div className="dataZone">
          { 
            level === "scpack" && 
            <Feature name={"favouritePacksAndCompanies"}>
              <div className="setAsFavourite">
                <ToggleFavouriteButton element={{type: "supplychainpack", companyId: companyId, packId: value, amppId: mainData.sections[6].items[4].value, packName: mainData.sections[0].items[3].value}} label="Pack" /> 
              </div>
            </Feature>
          }
          { 
            level === "ampp" && 
            <Feature name={"favouritePacksAndCompanies"}>
              <div className="setAsFavourite">
                <ToggleFavouriteButton element={{type: "ampp", companyId: mainData.related.sups.items[0].id, packId: value, amppId: value, packName: mainData.sections[0].items[1].value}} label="Pack" /> 
              </div>
            </Feature>
          }
          <div className="page_parent">           
            <div ref={detailZone} className="page_details" style={{ width:"100%" }}>
                <RenderDetails content = { mainData.sections } level = { level }/>
            </div>
          </div>
        </div>
      </PageContainer>
      )
  } else {
    return (
      <PageContainer>
        <Header location={props.location}/>
        <div className="dataZone">
          {
            level === "sccompany" && 
            <Feature name={"favouritePacksAndCompanies"}>
              <div className="setAsFavourite">
                <ToggleFavouriteButton element={{type: "sccompany", companyId: props.match.params.value}} label="Supplier" /> 
              </div>
            </Feature>
          }
          { 
            level === "sup" && 
            <Feature name={"favouritePacksAndCompanies"}>
              <div className="setAsFavourite">
                <ToggleFavouriteButton element={{type: "sup", companyId: mainData.related.sups.items[0].id}} label="Supplier"/> 
              </div>
            </Feature>
          }
          <div className="page_parent">
            <div ref={detailZone} className="page_details">
                <RenderDetails content = { mainData.sections } level = { level } calculateDetailsHeight = {calculateDetailsHeight}/>
            </div>
            <div ref={boxesZone} className="page_details_right">
                <RenderBoxes screenSize="half" content = { mainDataRelatedItems } />
                {
                  mainDataRelatedItems && mainDataRelatedItems.length >= relativeTop
                  ?
                  <div style={{display:"flex",justifyContent:"center",marginTop:20}}>
                    <Button buttonText="SHOW MORE" type="grey" level="default"
                    onClick = {()=>{
                      let newURL;
                      if (level === "vmp"){newURL = "/vmp/"+ value +"/amps"}
                      if (level === "vmpp"){newURL = "/vmpp/"+ value +"/ampps"}
                      if (level === "amp"){newURL = "/amp/"+ value +"/ampps"}
                      if (level === "sup"){newURL = "/sup/"+ value +"/ampps"}
                      if (level === "sccompany"){newURL = "/sccompany/"+ value +"/scpacks"}
                      history.push(newURL);
                    }}/>
                  </div>
                  :
                  null 
                }
            </div>
          </div>
        </div>
      </PageContainer>
      )
  }


} else {
  return (
    <PageContainer>
      <Header location={props.location}/>
      <div className="dataZone">
          <RenderBoxes
            clientTop = { clientTop }
            totalLength = {
              tab==="vmps"?APIData.VMPLength:
              tab==="amps"?APIData.AMPLength:
              tab==="sups"?APIData.SUPLength:
              tab==="vmpps"?APIData.VMPPLength:
              tab==="ampps"?APIData.AMPPLength:
              tab==="scpacks"?APIData.SCPACKLength:
              tab==="sccompanys"?APIData.SCCOMPANYLength:
              ""
            }
            content = {
                tab==="vtms"?VTMsData:
                tab==="vmps"?VMPsData:
                tab==="amps"?AMPsData:
                tab==="sups"?SUPsData:
                tab==="vmpps"?VMPPsData:
                tab==="ampps"?AMPPsData:
                tab==="scpacks"?SCPACKsData:
                tab==="sccompanys"?SCCOMPANiesData:
                ""
            }
          />

          {
            /* is load more necessery ? */
            (tab==="vmps" && VMPsData && APIData.VMPLength > VMPsData.length)
            ||
            (tab==="amps" && AMPsData && APIData.AMPLength > AMPsData.length)
            ||
            (tab==="vmpps" && VMPPsData && APIData.VMPPLength > VMPPsData.length)
            ||
            (tab==="ampps" && AMPPsData && APIData.AMPPLength > AMPPsData.length )
            ||
            (tab==="sups" && SUPsData && APIData.SUPLength > SUPsData.length)
            ||
            (tab==="scpacks" && SCPACKsData && APIData.SCPACKLength > SCPACKsData.length )
            ||
            (tab==="sccompanys" && SCCOMPANiesData && APIData.SCCOMPANYLength > SCCOMPANiesData.length)
            ?
    
            !isLoadingMore
            ?
            <div className="searchListingPage_buttomButtonsArea">
    
                {CS.WINW>600?null:
                  <div className="loadMoreButtonArea">
                  <Button buttonText="GO TO TOP"
                  type="grey" level="default"
                  onClick = {()=>{ handleGoToTop() }}
                  />
                </div>}

                <div className="loadMoreButtonArea">
                  <Button buttonText="LOAD MORE"
                  type="grey" level="default"
                  onClick = {()=>{ handleLoadMore() }}
    
                  />
                </div>
    
            </div>
            :
            <div className="loadMoreButtonArea">Loading...</div>
    
            :
            null
          }
      </div>
    </PageContainer>
    )



}};

export default LevelPage;