import * as React from "react";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalContext } from "../../contexts/GlobalStore/GlobalStore";
import { authentication } from "../../auth";

import "./NewMobileMenu.scss";

const NewMobileMenu: React.FC = () => {
  const { setMobileMenuOpen } = React.useContext(GlobalContext);
  const user = authentication.getAccount();

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  function renderMenuLink(path: string, label: React.ReactNode, newTab?: boolean) {
    return (
      <Link
        to={newTab ? { pathname: path } : path}
        onClick={() => setMobileMenuOpen(false)}
        target={newTab ? "_blank" : "_self"}
      >
        {label}
      </Link>
    );
  }

  return (
    <div className="newMobileMenu">
      <CloseIcon onClick={() => setMobileMenuOpen(false)} />
      {renderMenuLink("", "BACK TO HOME")}
      {renderMenuLink("https://www.datapharm.com/products/emc-med-data/", "ABOUT", true)}
      {renderMenuLink("", "COOKIE SETTINGS")}
      {renderMenuLink("/legal", "LEGAL")}
      {renderMenuLink("http://meddatasupport.medicines.org.uk/support/home", "HELP", true)}
      <div className="subCategory">
        <h1>FAVOURITES:</h1>
        {renderMenuLink("/favourites/dmd", "NHS dm+d")}
        {renderMenuLink("/favourites/emcsupplychain", "emc supply chain")}
      </div>
      {renderMenuLink("https://www.datapharm.com/contact/", "CONTACT", true)}

      {user ? (
        <>
          {renderMenuLink(
            "/editprofile",
            <>
              USER: <b>{user.name}</b>
            </>,
          )}
          {renderMenuLink("/editprofile", "EDIT PROFILE")}
          {renderMenuLink("/signout", "LOG OUT")}
        </>
      ) : (
        renderMenuLink("/signin", "SIGN IN")
      )}
    </div>
  );
};

export default NewMobileMenu;
